import clsx from "clsx"
import { graphql } from "gatsby"
import React, { useEffect, useState } from "react"
import { Container } from "react-bootstrap"
import AppraisalCard from "../components/AppraisalCard/AppraisalCard"
import BannerLanding from "../components/BannerLanding/BannerLanding"
import Layout from "../components/Layout/Layout"
import SEO from "../components/SEO/seo"
import StaticPageBlock from "../components/StaticPageBlock/StaticPageBlock"

const LandingPage = ({ data }) => {
  const pageData = data?.strapiPage
  const modules = pageData?.add_page_modules
  const banner = pageData?.banner
  const [renderComponent, setRenderComponent] = useState(false)

  useEffect(() => {
    window.addEventListener("mousemove", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
    window.addEventListener("touchmove", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
    window.addEventListener("keypress", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
    window.addEventListener("scroll", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
  }, [])
  return (
    <div className={clsx("areaguide-template", pageData.page_class)}>
      <Layout
        popular_search={pageData?.select_popular_search}
        pageData={pageData}
      >
        <SEO
          title={
            pageData?.seo?.meta_title
              ? pageData?.seo?.meta_title
              : pageData?.title
          }
          description={
            pageData?.seo?.meta_description
              ? pageData?.seo?.meta_description
              : pageData?.title
          }
        />
        <BannerLanding isStatic data={banner} pageData={pageData} />
        <div className="areaguide-page-block static-page">
          <Container className="content-section">
            <div className="left-section">
              {modules?.map((module, index) => {
                return (
                  <div>
                    {module.__typename ===
                      "STRAPI__COMPONENT_COMPONENTS_RICH_TEXT_BLOCK" && (
                      <StaticPageBlock
                        isStatic={banner?.image?.url ? true: false}
                        module={module}
                        pageData={pageData}
                      />
                    )}
                  </div>
                )
              })}
            </div>
            <div className="right-section">
              <div className="sticky-section">
                <AppraisalCard />
              </div>
            </div>
          </Container>
        </div>
      </Layout>
    </div>
  )
}

export default LandingPage

export const query = graphql`
  query ($page_id: String) {
    strapiPage(id: { eq: $page_id }) {
      ...PageFragment
      add_page_modules {
        ... on STRAPI__COMPONENT_COMPONENTS_RICH_TEXT_BLOCK {
          id
          __typename
          content {
            data {
              content
            }
          }
        }
      }
    }
  }
`
