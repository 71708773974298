// sort dropdown styles
export const customStylesMoreFilter = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isSelected ? "#null" : "null",
      color: isSelected ? "#141413" : "#141413",
      "&:hover": {
        color: "#141413",
        cursor: "pointer",
        fontWeight: "600",
      },
    }
  },
  control: styles => ({
    ...styles,
    backgroundColor: null,
    border: 0,
    paddingLeft: 0,
    outline: 0,
    boxShadow: "none",
    color: "#141413",
    fontSize: "1rem",
  }),
  valueContainer: styles => ({
    ...styles,
    fontSize: "1rem",
    paddingLeft: 0,
    lineHeight: "21px",
    cursor: "pointer",
  }),
  dropdownIndicator: styles => ({
    ...styles,
    color: "#141413",
  }),
  indicatorsContainer: styles => ({
    ...styles,
    color: "#141413",
    cursor: "pointer",
  }),
  indicatorSeparator: () => null,
  placeholder: defaultStyles => {
    return {
      ...defaultStyles,
      color: "#141413",
      marginLeft: 0,
    }
  },
}

// Dropdown react select styles
export const customSearchSelectStyles = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isSelected ? "null" : "null",
      color: isSelected ? "#141413" : "#141413",
      "&:hover": {
        color: "#141413",
        cursor: "pointer",
        fontWeight: "600",
      },
    }
  },
  control: styles => ({
    ...styles,
    backgroundColor: null,
    border: 0,
    paddingLeft: 0,
    outline: 0,
    boxShadow: "none",
    color: "#141413",
    fontSize: "1rem",
  }),
  valueContainer: styles => ({
    ...styles,
    fontSize: "1rem",
    paddingLeft: 0,
    lineHeight: "21px",
    cursor: "pointer",
  }),
  dropdownIndicator: styles => ({
    ...styles,
    color: "#141413",
  }),
  indicatorsContainer: styles => ({
    ...styles,
    color: "#141413",
    cursor: "pointer",
  }),
  indicatorSeparator: () => null,
  placeholder: defaultStyles => {
    return {
      ...defaultStyles,
      color: "#141413",
      marginLeft: 0,
    }
  },
}
// Dropdown react select styles

export const mapStyles = [
  {
    featureType: "transit",
    elementType: "labels.icon",
    stylers: [{ visibility: "off" }],
  },
  {
    featureType: "poi",
    stylers: [{ visibility: "off" }],
  },
  // {
  //     featureType: 'road',
  //     stylers: [{ visibility: 'off' }]
  // },
  {
    featureType: "all",
    elementType: "labels.text",
    stylers: [
      {
        color: "#878787",
      },
    ],
  },
  {
    featureType: "all",
    elementType: "labels.text.stroke",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "all",
    stylers: [
      {
        color: "#f9f5ed",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "all",
    stylers: [
      {
        color: "#f5f5f5",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#c9c9c9",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "all",
    stylers: [
      {
        color: "#aee0f4",
      },
    ],
  },
]
