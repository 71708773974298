import React, { useState } from "react"
import { Row, Col, Container } from "react-bootstrap"
import MortgageCalculator from "./MortgageCalculator"
import StampDutyCalculator from "./StampDutyCalculator"
import "./PropertyCalculator.scss"
import { Tab, Tabs } from "react-bootstrap"
import RentalyieldCalc from "./RentalYieldCalculator"
import { Link } from "gatsby"

const PropertyCalculator = props => {
  const [key, setKey] = useState(1)
  return (
    <>
      <h4>Finance Calculators</h4>
      <div className="accordion-wrapper property">
        <Tabs
          activeKey={key}
          onSelect={k => setKey(k)}
          className="properties-tab-section"
        >
          <Tab eventKey={1} title="Mortgage Calculator">
            <MortgageCalculator
              propertyPrice={props?.prop_price ? props?.prop_price : "500000"}
            />
          </Tab>
          <Tab eventKey={2} title="Stamp Duty Calculator">
            <StampDutyCalculator
              propertyValue={props?.prop_price ? props?.prop_price : "500000"}
            />
          </Tab>
          <Tab eventKey={3} title="Rental Yield Calculator">
            <RentalyieldCalc
              price={props?.prop_price ? props?.prop_price : "500000"}
            />
          </Tab>
        </Tabs>
        {!props.hideNote &&
         <>
         <div className="question">Are you ready to take the next step?</div>
          <div className="answer">
            Speak with <Link to="/about/our-team/">one of our team</Link> and we
            can help you find out your affordability:
          </div>
         </>
        } 
      </div>
    </>
  )
}

export default PropertyCalculator
