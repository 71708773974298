import clsx from "clsx"
import React from "react"
import { Container } from "react-bootstrap"
import ReactPlayer from "react-player"
import { getButtonClass, parseHTML } from "../../common/utils/utils"

import useDeviceMedia from "../../hooks/useDeviceMedia"
import watermark from "../../images/banner-watermark.svg"
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs"
import Animation from "../elements/Animation"
import CTALink from "../elements/CTALink"
import GGFXImage from "../elements/GGFXImage"
import ReviewsTotal from "../ReviewsTotal/ReviewsTotal"
import "./Office.scss" 


const OfficeBanner = ({
  data,
  homePage,
  pageData,
  breadcrumbURLs,
  transparentHeader,
  rating_avg,
  testimonial_count
}) => {
  const { isTablet, isMobile } = useDeviceMedia()

  if (!data || !pageData) return null

  const { banner_title, image, ctas, video, mobile_video, banner_content, show_reviews } = data

  const { strapi_id, imagetransforms } = pageData

  let processedImages = imagetransforms?.banner_image_Transforms
  processedImages ||= JSON.stringify({})
  const pageLayout = pageData?.layout
  const menuData = pageData?.menu
  const isTransparent =
    pageLayout === "home_page" ||
    pageLayout === "landing_page" ||
    transparentHeader
  return (
    <Animation
      animateInType={"no"}
      className={clsx("banner-landing-wrap", homePage && "home-banner")}
    >
      {video?.url ? (
        <div className="html-vid">
          <ReactPlayer
            url={isTablet || isMobile ? mobile_video?.url : video?.url}
            muted={true}
            loop={true}
            controls={false}
            autoplay={true}
            playsinline={true}
            playing={true}
          // onPlay={trackerVideo(title)}
          />
        </div>
      ) : (
        <GGFXImage
          ImageSrc={image}
          title={banner_title?.data?.banner_title}
          altText={"banner-img"}
          imagetransforms={processedImages}
          renderer="srcSet"
          imagename={"office.banner_image.listing_banner_image"}
          strapi_id={strapi_id}
          className="bg-image"
        />
      )}
      <div className="bg-img-overlay"></div>
      {!isTablet && homePage && (
        <img src={watermark} alt="banner-watermark" className="bg-watermark" />
      )}
      <Container className="banner-landing-container">

        <Breadcrumbs
          menuData={menuData}
          isTransparent={isTransparent}
          staticURLs={breadcrumbURLs}
        />

        <h1 className="banner-landing-title">
          {parseHTML(banner_title?.data?.banner_title)}
        </h1>
        {banner_content?.data?.banner_content &&
          <div className="banner-content">
            {parseHTML(banner_content?.data?.banner_content)}
          </div>
        }

        {ctas && (
          <div className="cta-section">
            {ctas?.map((cta, index) => (
              <CTALink
                cta={cta}
                className={`${getButtonClass(cta.cta_theme)} ${cta.cta_label === "Book a Valuation" ? "book_val_but" : ""
                  }`}
              />
            ))}
          </div>
        )}
        {show_reviews && (
          // <div className="reviews-section">
          //   {/* {loader && <div className="loader-icon-loader" />} */}
          //   <img
          //     src={reviewsImg}
          //     alt="reviews-img"
          //     className="reviews-img"
          //   />
          //   <p className="review-text">
          //     <span>{parseFloat(rating_avg).toFixed(1)}</span> from{" "}
          //     {testimonial_count} reviews
          //   </p>
          // </div>
          <ReviewsTotal
          rating_avg={rating_avg}
          testimonial_count={testimonial_count}
          isWhite={true}
        />
        )}
      </Container>
    </Animation>
  )
}

export default OfficeBanner
