import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { Container } from "react-bootstrap"
import { parseHTML, getButtonClass } from "../../common/utils/utils"
import Animation from "../elements/Animation"
import CTALink from "../elements/CTALink"
import "./HelpBlock.scss"

const Helpblock = ({ content, ctas, title }) => {


  return (
    <Animation className="helpBlock">
      <Container>
        <div className="section-p-80">
          <Animation animateInType={"up"}>
            {title &&
               <h3>{title}</h3>
            }         
            {content.data.content &&
              <div className="content">    {parseHTML(content.data.content)}</div>
            }

          </Animation>
          {ctas && (
            <Animation animateInType={"up"} className="cta-section">
              {ctas?.map((cta, index) => (
                <CTALink
                  cta={cta}
                  className={`${getButtonClass(cta.cta_theme)} book_val_but2`}
                />
              ))}
            </Animation>
          )}
        </div>
      </Container>
    </Animation>
  )
}

export default Helpblock
