import React from "react"
import { Container } from "react-bootstrap"
import { currencyFormat } from "../../common/utils/utils"
import bathIcon from "../../images/icons/Baths.svg"
import bedIcon from "../../images/icons/Bed.svg"
import receptionIcon from "../../images/icons/Reception.svg"
import areaIcon from "../../images/icons/arrows-pointing-out.svg"
import PropertyCta from "../PropertyCta/PropertyCta"
import "./PropertyHead.scss"
const PropertyHead = ({ data }) => {

  let floorarea=""
  
  if(data?.floorarea_type==="sqft"&&data?.floorarea_max!==0&&data?.floorarea_max){
    floorarea=Math.floor(data?.floorarea_max/ 10.764)
  }
  return (
    <div className="propertyhead">
      <Container className="content-section">
        <div className="left-section">
          <h1>{data?.display_address}</h1>
          <div className="price">
          {data?.search_type === "sales"?  <span>
              {data?.price_qualifier==="PCM"?"pcm":data?.price_qualifier?data?.price_qualifier:""}
            </span> : null}
            {currencyFormat(data?.price, "£", false)} {" "}
            {data?.search_type === "lettings"? <span>
              {data?.price_qualifier==="PCM"?"pcm":data?.price_qualifier?data?.price_qualifier:""}
            </span>: null}
          </div>
          <div className="icon-list d-flex">
            {data?.bedroom ? (
              <div className="item d-flex">
                <img src={bedIcon} alt="bed" className="bed-icon" />
                <span>
                  {data?.bedroom}
                  <span className="txt">
                    {" "}
                    Bed{data?.bedroom > 1 ? "s" : ""}
                  </span>
                </span>
              </div>
            ) : null}
            {data?.bathroom ? (
              <div className="item d-flex">
                <img src={bathIcon} alt="bath" className="bath-icon" />
                <span>
                  {data?.bathroom}
                  <span className="txt">
                    {" "}
                    Bath{data?.bathroom > 1 ? "s" : ""}
                  </span>
                </span>
              </div>
            ) : null}
            {data?.reception ? (
              <div className="item d-flex">
                <img
                  src={receptionIcon}
                  alt="reception"
                  className="reception-icon"
                />
                <span>
                  {data?.reception}
                  <span className="txt">
                    {" "}
                    Reception{data?.reception > 1 ? "s" : ""}
                  </span>
                </span>
              </div>
            ) : null}
            {data?.floorarea_max ? (
              <div className="item d-flex">
                <img src={areaIcon} alt="area" className="area-icon" />
                <span>
                  {currencyFormat(data?.floorarea_max, "", false)}{" "}
                  {data?.floorarea_type} ({floorarea} sqm)
                </span>
              </div>
            ) : null}
          </div>
        </div>
        <div className="right-section right d-none d-xl-flex">
          <PropertyCta
            data={data}
            id={data?.id}
            soldrules={
              data?.status === "Sold" ||
              data?.status === "Let" ||
              data?.status === "Sold Subject To Contract" ||
              data?.status === "Let Agreed"
                ? true
                : false
            }
          />
        </div>
      </Container>
    </div>
  )
}

export default PropertyHead
