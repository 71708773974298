import { useEffect } from "react"
import {
  commercialPropertyTypeOptions,
  maxPriceOptionsResiRent,
  maxPriceOptionsResiSale,
  minPriceOptionsResiRent,
  minPriceOptionsResiSale,
  resiPropertyTypeOptions,
} from "../SearchFilter/filterOptions"
import { usePropertyStore } from "../store/propertyStore"

const useInitPropertyData = ({ type, department, status, locationName }) => {
  const {
    setPType,
    setPDepartment,
    setPStatus,
    setLocationName,
    setMinPriceOptions,
    setMaxPriceOptions,
    setPropertyTypeOptions,
  } = usePropertyStore(state => ({
    setPType: state.setPType,
    setPDepartment: state.setPDepartment,
    setPStatus: state.setPStatus,
    setLocationName: state.setLocationName,
    setMinPriceOptions: state.setMinPriceOptions,
    setMaxPriceOptions: state.setMaxPriceOptions,
    setPropertyTypeOptions: state.setPropertyTypeOptions,
  }))

  const isResidential = department === "residential"
  const isCommercial = department === "commercial"
  const isNewHomes = department === "new_developments"

  useEffect(() => {
    setPType(type)
    setPDepartment(department)
    setPStatus(status)
    setLocationName(locationName)

    if (type === "sales") {
      setMinPriceOptions(minPriceOptionsResiSale)
      setMaxPriceOptions(maxPriceOptionsResiSale)
    }
    if (type === "lettings") {
      setMinPriceOptions(minPriceOptionsResiRent)
      setMaxPriceOptions(maxPriceOptionsResiRent)
    }
    if (isResidential || isNewHomes) {
      setPropertyTypeOptions(resiPropertyTypeOptions)
    } else if (isCommercial) {
      setPropertyTypeOptions(commercialPropertyTypeOptions)
    }
  }, [])
}

export default useInitPropertyData
