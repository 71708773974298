export const removeSpecialChar = (val, symbol = "-") => {
  if (typeof val === "undefined") {
    return val
      .replace(/[^\w\s]/gi, " ")
      .replace(/\s\s+/g, " ")
      .replace(/ /gi, symbol)
      .toLowerCase()
  }
  return val
}

export const currencyFormat = (num, currency = "£", withSpace = false) => {
  var filterNum = filterNumber(num)
  if (!filterNum) {
    return `${currency} 0`
  }
  if (withSpace) {
    return `${currency} ${new Intl.NumberFormat("en-US", {}).format(filterNum)}`
  } else {
    return `${currency}${new Intl.NumberFormat("en-US", {}).format(filterNum)}`
  }
}

/** Get number only */
export const filterNumber = str => {
  if (!str) return 0
  str = str.toString()
  return parseInt(str.replace(/[^\d.]/g, ""), 10)
}
