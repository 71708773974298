import React from "react"
import { Button } from "react-bootstrap"
import { Form } from "react-bootstrap"
import { Col } from "react-bootstrap"

const ButtonField = ({ type, name, fieldClass, id, step, grpmd }) => (
  <Form.Group
    as={Col}
    md={grpmd}
    className={`${type} ${step} theme-form-group`}
    controlId={"validation" + name}
  >
    <Button variant="" type={type} className={fieldClass} id={id}>
      {name} {fieldClass == "arrow" && <i className="icon form-arrow"></i>}
    </Button>
  </Form.Group>
)

export default ButtonField
