import { graphql, useStaticQuery } from "gatsby"
import { useEffect } from "react"
import { usePropertyStore } from "../store/propertyStore"
const useAreaSelect = searchState => {
  const searchText = usePropertyStore(state => state.searchText)
  const setSearchText = usePropertyStore(state => state.setSearchText)
  const searchSlug = usePropertyStore(state => state.searchSlug)
  const setSearchSlug = usePropertyStore(state => state.setSearchSlug)
  const areaJSON = usePropertyStore(state => state.areaJSON)
  const setAreaJSON = usePropertyStore(state => state.setAreaJSON)
  const areaData = useStaticQuery(graphql`
    {
      areajson {
        areas {
          slug
          name
        }
      }
    }
  `)
  useEffect(() => {
    const getAreas = async areasJson => {
      setAreaJSON(areasJson)
    }

    getAreas(areaData.areajson.areas)
  }, [areaData])

  const handleSelectArea = area => {
    setSearchText(area.name)
    setSearchSlug(area.slug)
  }

  return {
    searchText,
    setSearchText,
    areasSuggestionList: areaJSON,
    handleSelectArea,
    searchSlug,
    setSearchSlug,
  }
}

export default useAreaSelect
