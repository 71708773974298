import React from "react"
import "../styles/main.scss"

const Index = () => {
  return (
    <>
      <div style={{ padding: "24px" }}>
        <h1 style={{ backgroundColor: "black", paddingInline: "24px" }}>
          Heading 1
        </h1>
        <br />
        <h2>Heading 2</h2>
        <br />
        <h3>Heading 3</h3>
        <br />
        <button className="button button-green">book your appraisal</button>
        <br />
        <button className="button button-green-outline">
          book your appraisal
        </button>
        <br />
      </div>
      <div style={{ backgroundColor: "black", padding: "24px" }}>
        <button className="button button-white">book your appraisal</button>
        <br />
        <button className="button button-white-outline">
          book your appraisal
        </button>
      </div>
    </>
  )
}

export default Index
