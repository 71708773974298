import { Link } from "gatsby"
import React, { useState } from "react"
import { Modal } from "react-bootstrap"
import FormFields from "../../../static/forms/team_form.json"
import { teamsURL } from "../../common/utils/urls"
import { breakString, parseHTML } from "../../common/utils/utils"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import Animation from "../elements/Animation"
import GGFXImage from "../elements/GGFXImage"
import DefaultForm from "../forms/default-form-layout"
import "./TeamCard.scss"

const TeamCard = ({ team, index }) => {
  const {
    name,
    position,
    image,
    phone,
    slug,
    imagetransforms,
    strapi_id,
    email,
  } = team

  const [show, setShow] = useState(false)
  const [teammember, setTeammember] = useState("")

  const { isMobile } = useDeviceMedia()

  const formattedName = isMobile ? breakString(name, 1) : name
  const link = `${teamsURL}${slug}/`

  const handleModal = data => {
    setShow(true)
    setTeammember(data)
  }

  const handleClose = () => {
    setShow(false)
    setTeammember("")
  }

  let processedImages = imagetransforms?.image_Transforms
  processedImages ||= JSON.stringify({})

  return (
    <Animation
      animateInType={"up"}
      className="team-card"
      key={"teamcard" + index}
    >
      <Link to={link} className="team__img img-zoom">
        {/* remove this div when u want to enable link */}
        <div className="team__img img-zoom">
          <GGFXImage
            ImageSrc={image}
            title={name}
            altText={name}
            imagetransforms={processedImages}
            renderer="srcSet"
            imagename={"team.image.img"}
            strapi_id={strapi_id}
          />
        </div>
      </Link>
      <div className="team__content">
        <Link to={link}>
          <p className="name">{parseHTML(formattedName)}</p>
        </Link>
        <p className="designation">{position}</p>
        <div className="team__contact">
          <a href={`tel:${phone}`} className="phone">
            {phone}
          </a>
          <span>/</span>
          <a
            onClick={() => handleModal(team)}
            href="javascript:void(0)"
            className="email"
          >
            Email
          </a>
        </div>
      </div>
      <Modal
        show={show}
        // onHide={handleClose}
        backdrop="static"
        centered
        dialogClassName="modal-popup-form"
      >
        <Modal.Header>
          <div className="modal-header-container">
            <div className="logo">
              <i className="icon black-logo-icon"></i>
            </div>
            <div className="close-modal" onClick={() => handleClose()}>
              <i className="icon white-close-icon"></i>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="popup-form-wrapper">
            <div>
              <div className="propety-info">
                <p className="heading">Team Enquiry</p>
              </div>
              <DefaultForm
                fields={FormFields}
                teammembername={teammember.name}
                formtagclassname="team-enquiry"
                to_email_id={teammember.email}
                email_subject_user={`Contact ${teammember.name}`}
                email_subject_admin={`Contact ${teammember.name}`}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Animation>
  )
}

export default TeamCard
