/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import { useLocation } from "@reach/router"
import PropTypes from "prop-types"
import React from "react"
import { Helmet } from "react-helmet"
import useCompanyInfo from "../../hooks/useCompanyInfo"

function SEO({ description, lang, meta, title, image }) {
  const { name } = useCompanyInfo()

  const metaTitle = title || "Estate Agents in the UK"
  const metaDescription =
    description ||
    "We're a leading independent estate agency in the UK committed to serving our community with personalised services in Sales, Lettings and Property Management."
  var { pathname } = useLocation()

  if (pathname == "/home/" || pathname == "/home") pathname = "/"

  var curentUrl = ""
  curentUrl = process.env.GATSBY_SITE_URL + pathname

  // check trailing slash
  if (!curentUrl.endsWith("/")) curentUrl = curentUrl + "/"

  // remove page- from url
  if (curentUrl.includes("page-")) {
    curentUrl = curentUrl
      .split("/")
      .filter(path => !path.includes("page-"))
      .join("/")
  }

  if (curentUrl.includes("sortby-")) {
    curentUrl = curentUrl
      .split("/")
      .filter(path => !path.includes("sortby-"))
      .join("/")

  }
  if(typeof window !== "undefined"){
  if(window.location.pathname === "/new-homes/for-sale/" || window.location.pathname === "/properties/for-sale/" || window.location.pathname === "/properties/to-rent/"  || window.location.pathname === "/commercial-properties/to-rent/" || window.location.pathname === "/commercial-properties/for-sale/"){
    curentUrl = curentUrl+"in-kent/"
  }
}

  //remove multiple slashes
  curentUrl = curentUrl.replace(/([^:]\/)\/+/g, "$1")

  //set lowercase url
  curentUrl = curentUrl.toLowerCase()

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      link={[
        {
          href: curentUrl,
          rel: "canonical",
        },
      ]}
      title={metaTitle}
      titleTemplate={`%s | ${name}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: image ? image : "",
        },
        {
          property: `og:url`,
          content: curentUrl,
        },
        {
          property: `og:image:secure_url`,
          content: image ? image : "",
        },
        {
          property: `og:site_name`,
          content: `Christopher Hodgson`,
        },
        {
          property: `og:image:type`,
          content: `image/jpeg`,
        },
        {
          property: `og:image:width`,
          content: `400`,
        },
        {
          property: `og:image:height`,
          content: `300`,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: name || ``,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        // {
        //   name: `twitter:url`,
        //   content: curentUrl,
        // },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `facebook-domain-verification`,
          content: ``,
        },
        // {
        //   property: "twitter:image",
        //   content: image,
        // },{
        //   name: `twitter:card`,
        //   content: `summary_large_image`,
        // },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  image: null,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
}

export default SEO
