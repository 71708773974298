import React from "react"
import { parseHTML } from "../../common/utils/utils"
import CTALink from "../elements/CTALink"
import { Container } from "react-bootstrap"
import "./ImageCtaComponent.scss"
import Animation from "../elements/Animation"

const ImageCtaComponent = ({ module }) => {
  const { cta, heading, image, title } = module
  return (
    <Animation className="image-cta-wrapper">
      <div className="image-cta-component">
        <div
          className="bg-image"
          style={{ backgroundImage: `url(${image.url})` }}
        >
          <div className="bg-overlay"></div>
          <Container className="cta-content-container">
            <Animation
              animateInType={"up"}
              delay={50}
              className="image-content"
            >
              <p className="heading">{heading}</p>
              <h3 className="title">{parseHTML(title?.data?.title)}</h3>
              <div className="banner-cta">
                <CTALink cta={cta} className={"button button-white-outline book_val_but"} />
              </div>
            </Animation>
          </Container>
        </div>
      </div>
    </Animation>
  )
}

export default ImageCtaComponent
