import React from "react"
import "./AreaguideCard.scss"
import { Link } from "gatsby"
import PlayVideoButton from "../PlayVideo/PlayVideoButton"
import { areaGuidesURL } from "../../common/utils/urls"
import GGFXImage from "../elements/GGFXImage"
import Animation from "../elements/Animation"

const AreaguideCard = ({ areaguide, index }) => {
  if (!areaguide) return null

  const { video_url, tile_img, strapi_id, slug, name, id, imagetransforms } =
    areaguide

  const areaURL = `${areaGuidesURL}${slug}/`

  let processedImages = imagetransforms?.tile_img_Transforms
  processedImages ||= JSON.stringify({})

  return (
    <Animation
      animateInType={"up"}
      delay={(index + 0.5) * 200}
      className="areaguide-card"
      key={"areacard" + index}
    >
      <div className="image-section img-zoom">
        <Link to={areaURL}>
          <GGFXImage
            ImageSrc={tile_img}
            title={name}
            altText={name}
            imagetransforms={processedImages}
            renderer="srcSet"
            imagename={"area-guide.tile_img.card_image"}
            strapi_id={strapi_id}
            className="area-tile-image"
          />
        </Link>
        <PlayVideoButton videoUrl={video_url} />
      </div>
      <Link className="name" to={areaURL}>
        {name}
      </Link>
    </Animation>
  )
}

export default AreaguideCard
