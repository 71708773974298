import clsx from "clsx"
import React from "react"
import { Container } from "react-bootstrap"
import { parseHTML } from "../../common/utils/utils"
import "./BannerListing2.scss"
import Animation from "../elements/Animation"

const BannerListing2 = ({ data }) => {
  if (!data) return null

  const { banner_title, banner_content } = data
  return (
    <>
      <Animation className={clsx("banner-listing-2-wrap")}>
        <Container className="banner-listing-container">
          <h1 className="title">
            {parseHTML(banner_title?.data?.banner_title)}
          </h1>
          <div className="description">
            {parseHTML(banner_content?.data?.banner_content)}
          </div>
        </Container>
      </Animation>
    </>
  )
}

export default BannerListing2
