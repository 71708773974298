import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { parseHTML } from "../../common/utils/utils"
import CTALink from "../elements/CTALink"
import "./AppraisalCard.scss"
import Animation from "../elements/Animation"

const AppraisalCard = () => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiGlobalModule {
        edges {
          node {
            ...AppraisalFragment
          }
        }
      }
    }
  `)

  const appraisalData =
    data?.allStrapiGlobalModule.edges[0]?.node.book_an_appraisal

  const { cta, title } = appraisalData
  return (
    <Animation className="appraisal-card">
      <div className="title">{parseHTML(title?.data?.title)}</div>
      <CTALink
        cta={cta}
        className={"button button-white-outline appraisal-cta"}
      />
    </Animation>
  )
}

export default AppraisalCard
