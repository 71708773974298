import React from "react"
import { Container } from "react-bootstrap"
import FormFields from "../../../static/forms/home_valuation.json"
import { parseHTML } from "../../common/utils/utils"
import HomeValuationForm from "../forms/home-valuation"
import "./HomeValuation.scss"

const HomeValuation = ({ data }) => {
  const title = data.title.data.title
  const content = data.content.data.content
  return (
    <div className="home-valuation-wrapper">
      <Container>
        <div className="home-valuation-module">
          <div className="content-section">
            <h1 className="title">{parseHTML(title)}</h1>
            <p className="content">{parseHTML(content)}</p>
          </div>
          <HomeValuationForm fields={FormFields} formtagclassname="home-valuation" />
        </div>
      </Container>
    </div>
  )
}

export default HomeValuation
