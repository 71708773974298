import {
  propertiesRentURL,
  propertiesSaleURL,
} from "../../../common/utils/urls"

// Ptypes
export const propertySaleRentOptions = [
  { value: "for-sale", label: "Buy" },
  { value: "to-rent", label: "Rent" },
]
export const propertySaleRentUrlOptions = [
  { value: propertiesSaleURL, label: "Buy" },
  { value: propertiesRentURL, label: "Rent" },
]

export const locationOptions = [
  { label: "Location", value: "" },
  {label:"Ashford",value:"ashford"},
  {label:"Canterbury",value:"canterbury"},
  {label:"Deal",value:"deal"},
  {label:"Faversham",value:"faversham"},
  {label:"Herne Bay",value:"herne-bay"},
  {label:"Kent",value:"kent"},
  {label:"Margate",value:"margate"},
  {label:"Ramsgate",value:"ramsgate"},
  {label:"Shepherdswell",value:"shepherdswell"},
  {label:"Whitstable",value:"whitstable"}
]

// Property type
export const resiPropertyTypeOptions = [
  { label: "All Types", value: "" },
  { label: "House", value: "house" },
  { label: "Bungalow", value: "bungalow" },
  { label: "Land", value: "land" },
  { label: "Flat", value: "flat" },
]

// Property type
export const commercialPropertyTypeOptions = [
  { label: "All Types", value: "" },
  { label: "Shop", value: "shop" },
  { label: "Commercial", value: "commercial" },
  { label: "Retail", value: "retail" },
]

// Property type
export const Furnishing = [
  { label: "Select Furnishing", value: "" },
  { label: "Yes", value: "yes" },
  { label: "No", value: "no" },
  { label: "Partly", value: "partly" },
]

export const VirtualViewing = [
  { label: "Select Viewing", value: "" },
  { label: "360 Tours", value: "360-tour" },
  // {label: "3D Tour", value: "3d-tour"},
  { label: "Video Tours", value: "video-tour" },
]
// Price Min Residential Sales
export const minPriceOptionsResiSale = [
  {
    value: "",
    label: "Min Price",
  },
  {
    value: "300000",
    label: "£300,000",
  },
  {
    value: "400000",
    label: "£400,000",
  },
  {
    value: "500000",
    label: "£500,000",
  },
  {
    value: "600000",
    label: "£600,000",
  },
  {
    value: "700000",
    label: "£700,000",
  },
  {
    value: "800000",
    label: "£800,000",
  },
  {
    value: "900000",
    label: "£900,000",
  },
  {
    value: "1000000",
    label: "£1,000,000",
  },
  {
    value: "1100000",
    label: "£1,100,000",
  },
  {
    value: "1200000",
    label: "£1,200,000",
  },
  {
    value: "1300000",
    label: "£1,300,000",
  },
  {
    value: "1400000",
    label: "£1,400,000",
  },
  {
    value: "1500000",
    label: "£1,500,000",
  },
  {
    value: "1600000",
    label: "£1,600,000",
  },
  {
    value: "1700000",
    label: "£1,700,000",
  },
  {
    value: "1800000",
    label: "£1,800,000",
  },
  {
    value: "1900000",
    label: "£1,900,000",
  },
  {
    value: "2000000",
    label: "£2,000,000",
  },
  {
    value: "2100000",
    label: "£2,100,000",
  },
  {
    value: "2200000",
    label: "£2,200,000",
  },
  {
    value: "2300000",
    label: "£2,300,000",
  },
  {
    value: "2400000",
    label: "£2,400,000",
  },
  {
    value: "2500000",
    label: "£2,500,000",
  },
  {
    value: "2600000",
    label: "£2,600,000",
  },
  {
    value: "2700000",
    label: "£2,700,000",
  },
  {
    value: "2800000",
    label: "£2,800,000",
  },
  {
    value: "2900000",
    label: "£2,900,000",
  },
  {
    value: "3000000",
    label: "£3,000,000",
  },
  {
    value: "3250000",
    label: "£3,250,000",
  },
  {
    value: "3500000",
    label: "£3,500,000",
  },
  {
    value: "3750000",
    label: "£3,750,000",
  },
  {
    value: "4000000",
    label: "£4,000,000",
  },
  {
    value: "4250000",
    label: "£4,250,000",
  },
  {
    value: "4500000",
    label: "£4,500,000",
  },
  {
    value: "5000000",
    label: "£5,000,000",
  },
  {
    value: "6000000",
    label: "£6,000,000",
  },
  {
    value: "7000000",
    label: "£7,000,000",
  },
  {
    value: "8000000",
    label: "£8,000,000",
  },
  {
    value: "9000000",
    label: "£9,000,000",
  },
  {
    value: "10000000",
    label: "£10,000,000",
  },
  {
    value: "25000000",
    label: "£25,000,000",
  },
  {
    value: "50000000",
    label: "£50,000,000",
  },
]
// Price Min Residential Sales

// Price Max Residential Sales
export const maxPriceOptionsResiSale = [
  {
    value: "",
    label: "Max Price",
  },
  {
    value: "300000",
    label: "£300,000",
  },
  {
    value: "400000",
    label: "£400,000",
  },
  {
    value: "500000",
    label: "£500,000",
  },
  {
    value: "600000",
    label: "£600,000",
  },
  {
    value: "700000",
    label: "£700,000",
  },
  {
    value: "800000",
    label: "£800,000",
  },
  {
    value: "900000",
    label: "£900,000",
  },
  {
    value: "1000000",
    label: "£1,000,000",
  },
  {
    value: "1100000",
    label: "£1,100,000",
  },
  {
    value: "1200000",
    label: "£1,200,000",
  },
  {
    value: "1300000",
    label: "£1,300,000",
  },
  {
    value: "1400000",
    label: "£1,400,000",
  },
  {
    value: "1500000",
    label: "£1,500,000",
  },
  {
    value: "1600000",
    label: "£1,600,000",
  },
  {
    value: "1700000",
    label: "£1,700,000",
  },
  {
    value: "1800000",
    label: "£1,800,000",
  },
  {
    value: "1900000",
    label: "£1,900,000",
  },
  {
    value: "2000000",
    label: "£2,000,000",
  },
  {
    value: "2100000",
    label: "£2,100,000",
  },
  {
    value: "2200000",
    label: "£2,200,000",
  },
  {
    value: "2300000",
    label: "£2,300,000",
  },
  {
    value: "2400000",
    label: "£2,400,000",
  },
  {
    value: "2500000",
    label: "£2,500,000",
  },
  {
    value: "2600000",
    label: "£2,600,000",
  },
  {
    value: "2700000",
    label: "£2,700,000",
  },
  {
    value: "2800000",
    label: "£2,800,000",
  },
  {
    value: "2900000",
    label: "£2,900,000",
  },
  {
    value: "3000000",
    label: "£3,000,000",
  },
  {
    value: "3250000",
    label: "£3,250,000",
  },
  {
    value: "3500000",
    label: "£3,500,000",
  },
  {
    value: "3750000",
    label: "£3,750,000",
  },
  {
    value: "4000000",
    label: "£4,000,000",
  },
  {
    value: "4250000",
    label: "£4,250,000",
  },
  {
    value: "4500000",
    label: "£4,500,000",
  },
  {
    value: "5000000",
    label: "£5,000,000",
  },
  {
    value: "6000000",
    label: "£6,000,000",
  },
  {
    value: "7000000",
    label: "£7,000,000",
  },
  {
    value: "8000000",
    label: "£8,000,000",
  },
  {
    value: "9000000",
    label: "£9,000,000",
  },
  {
    value: "10000000",
    label: "£10,000,000",
  },
  {
    value: "25000000",
    label: "£25,000,000",
  },
  {
    value: "50000000",
    label: "£50,000,000",
  },
]
// Price Max Residential Sales

// Price Min Residential Lettings
export const minPriceOptionsResiRent = [
  {
    value: "",
    label: "No Min",
  },
  {
    value: "500",
    label: "£500 pcm"
  },
  {
    value: "600",
    label: "£600 pcm"
  },
  {
    value: "750",
    label: "£750 pcm"
  },
  {
    value: "1000",
    label: "£1,000 pcm"
  },
  {
    value: "1250",
    label: "£1,250 pcm"
  },
  {
    value: "1500",
    label: "£1,500 pcm"
  },
  {
    value: "2000",
    label: "£2,000 pcm"
  },
  {
    value: "2500",
    label: "£2,500 pcm"
  },
  {
    value: "3000",
    label: "£3,000 pcm"
  },
  {
    value: "3500",
    label: "£3,500 pcm"
  },
  {
    value: "4000",
    label: "£4,000 pcm"
  },
  {
    value: "4500",
    label: "£4,500 pcm"
  },
  {
    value: "5000",
    label: "£5,000 pcm"
  },
  {
    value: "7500",
    label: "£7,500 pcm"
  },
  {
    value: "10000",
    label: "£10,000 pcm"
  }
]
// Price Min Residential Lettings

// Price Max Residential Lettings
export const maxPriceOptionsResiRent = [
  {
    value: "",
    label: "No Max",
  },
  {
    value: "500",
    label: "£500 pcm"
  },
  {
    value: "600",
    label: "£600 pcm"
  },
  {
    value: "750",
    label: "£750 pcm"
  },
  {
    value: "1000",
    label: "£1,000 pcm"
  },
  {
    value: "1250",
    label: "£1,250 pcm"
  },
  {
    value: "1500",
    label: "£1,500 pcm"
  },
  {
    value: "2000",
    label: "£2,000 pcm"
  },
  {
    value: "2500",
    label: "£2,500 pcm"
  },
  {
    value: "3000",
    label: "£3,000 pcm"
  },
  {
    value: "3500",
    label: "£3,500 pcm"
  },
  {
    value: "4000",
    label: "£4,000 pcm"
  },
  {
    value: "4500",
    label: "£4,500 pcm"
  },
  {
    value: "5000",
    label: "£5,000 pcm"
  },
  {
    value: "7500",
    label: "£7,500 pcm"
  },
  {
    value: "10000",
    label: "£10,000 pcm"
  }
]
// Price Max Residential Lettings

// Bedrooms
export const bedroomOptions = [
  { label: "All", value: "" },
  { label: "Studio", value: "0" },
  { label: "1+", value: "1" },
  { label: "2+", value: "2" },
  { label: "3+", value: "3" },
  { label: "4+", value: "4" },
  { label: "5+", value: "5" },
  { label: "6+", value: "6" },
  { label: "7+", value: "7" },
  { label: "8+", value: "8" },
  { label: "9+", value: "9" },
]
// Bedrooms

// Bathrooms
export const bathroomsRange = [
  { label: "Bathrooms", value: "" },
  { label: "1+", value: "1" },
  { label: "2+", value: "2" },
  { label: "3+", value: "3" },
  { label: "4+", value: "4" },
  { label: "5+", value: "5" },
  { label: "6+", value: "6" },
  { label: "7+", value: "7" },
  { label: "8+", value: "8" },
  { label: "9+", value: "9" },
]
// Bathrooms
// Bedrooms
export const ParkingRange = [
  { label: "Select Parking", value: "" },
  { label: "1+", value: "1" },
  { label: "2+", value: "2" },
  { label: "3+", value: "3" },
  { label: "4+", value: "4" },
  { label: "5+", value: "5" },
]
// Bedrooms

// SQFT
export const areaSizeOptions = [
  { label: "All", value: "" },
  { label: "500+ Sqft", value: "500" },
  { label: "1,000+ Sqft", value: "1000" },
  { label: "2,000+ Sqft", value: "2000" },
  { label: "3,000+ Sqft", value: "3000" },
  { label: "4,000+ Sqft", value: "4000" },
  { label: "5,000+ Sqft", value: "5000" },
  { label: "6,000+ Sqft", value: "6000" },
  { label: "7,000+ Sqft", value: "7000" },
  { label: "8,000+ Sqft", value: "8000" },
  { label: "9,000+ Sqft", value: "9000" },
  { label: "10,000+ Sqft", value: "10000" },
  { label: "11,000+ Sqft", value: "11000" },
  { label: "12,000+ Sqft", value: "12000" },
  { label: "13,000+ Sqft", value: "13000" },
  { label: "14,000+ Sqft", value: "14000" },
  { label: "15,000+ Sqft", value: "15000" },
]
// SQFT

// Property amenities checkboxes
export const amenitiesCheckboxArray = [
  {
    label: "Central A/C & Heating",
    value: "central-a-c-heating",
    checked: false,
  },
  { label: "Covered Parking", value: "covered-parking", checked: false },
  { label: "Built in Wardrobes", value: "built-in-wardrobes", checked: false },
  { label: "Security", value: "security", checked: false },
  { label: "Shared Gym", value: "shared-gym", checked: false },
  { label: "Balcony", value: "balcony", checked: false },
  { label: "Shared Pool", value: "shared-pool", checked: false },
  {
    label: "Built in Kitchen Appliances",
    value: "built-in-kitchen-appliances",
    checked: false,
  },
  { label: "Concierge Service", value: "concierge-service", checked: false },
  { label: "Shared Spa", value: "shared-spa", checked: false },
  { label: "Pets Allowed", value: "pets-allowed", checked: false },
  { label: "View of Water", value: "view-of-water", checked: false },
  { label: "View of Landmark", value: "view-of-landmark", checked: false },
  { label: "Maids Room", value: "maids-room", checked: false },
  { label: "Walk in Closet", value: "walk-in-closet", checked: false },
  { label: "Private Garden", value: "private-garden", checked: false },
  { label: "Study", value: "study", checked: false },
  { label: "Maid Service", value: "maid-service", checked: false },
  { label: "Private Pool", value: "private-pool", checked: false },
  { label: "Private Jacuzzi", value: "private-jacuzzi", checked: false },
  { label: "Private Gym", value: "private-gym", checked: false },
]

export const developerOptions = [
  { label: "Developer", value: "" },
  { label: "Emaar Properties", value: "Emaar" },
  { label: "Nakheel", value: "Nakheel" },
  { label: "Dubai Properties", value: "Dubai" },
  { label: "Meraas", value: "Meraas" },
  { label: "Meydan", value: "Meydan" },
  { label: "Sobha", value: "Sobha" },
  { label: "Deyaar", value: "Deyaar" },
  { label: "Omniyat", value: "Omniyat" },
  { label: "Damac Properties", value: "Damac" },
  { label: "Mag Property Development", value: "Mag Property Development" },
  { label: "Majid Al Futtaim", value: "Majid Al Futtaim" },
  { label: "Ellington Properties", value: "Ellington" },
  { label: "Binghatti", value: "Binghatti" },
  { label: "Bloom Properties", value: "Bloom" },
  { label: "Tiger Properties", value: "Tiger" },
  { label: "Danube Properties", value: "Danube" },
  { label: "Falcon City of Wonders LLC", value: "Falcon City of Wonders LLC" },
  { label: "Five Holdings", value: "Five Holdings" },
  { label: "Green Group", value: "Green Group" },
  { label: "ICD Brookfield", value: "ICD Brookfield" },
  { label: "Iman Developers", value: "Iman Developers" },
  { label: "Indigo Properties", value: "Indigo" },
  { label: "Ishraqah", value: "Ishraqah" },
  { label: "Jumeirah Golf Estates", value: "Jumeirah Golf Estates" },
  { label: "Jumeirah Group", value: "Jumeirah Group" },
  { label: "Liv Developers", value: "Liv Developers" },
  {
    label: "Lootah Real Estate Development",
    value: "Lootah Real Estate Development",
  },
  { label: "Meraki Developers", value: "Meraki Developers" },
  { label: "Meydan Sobha", value: "Meydan Sobha" },
  { label: "Nshama", value: "Nshama" },
  { label: "Palma Development", value: "Palma Development" },
  { label: "Pantheon Development", value: "Pantheon Development" },
  { label: "Select Group", value: "Select Group" },
  { label: "Sobha Developers", value: "Sobha Developers" },
  { label: "Sweid & Sweid", value: "Sweid & Sweid" },
  { label: "Tebyan", value: "Tebyan" },
  { label: "The First Group", value: "The First Group" },
  { label: "Union Properties", value: "Union" },
  { label: "Victory Heights", value: "Victory Heights" },
  { label: "Wasl Properties", value: "Wasl Properties" },
  { label: "Westar Properties", value: "Westar" },
]

export const completionRange = [
  { label: "Completion", value: "" },
  { label: "Ready", value: "ready" },
  { label: "2022", value: "2022" },
  { label: "2023", value: "2023" },
  { label: "2024", value: "2024" },
  { label: "2025", value: "2025" },
  { label: "2026", value: "2026" },
  { label: "2027", value: "2027" },
  { label: "2028", value: "2028" },
  { label: "2029", value: "2029" },
  { label: "2030", value: "2030" },
]
export const rentalPeriodOptions = [
  { label: "Rental Period", value: "" },
  { label: "Yearly", value: "yearly" },
  { label: "Monthly", value: "monthly" },
  { label: "Weekly", value: "weekly" },
  { label: "Daily", value: "daily" },
]

// Search overlay dropdown
export const searchOverlayOptions = [
  { label: "Buy", value: "buy" },
  { label: "Rent", value: "rent" },
  { label: "Off Plan", value: "offplan" },
  { label: "Holiday Lets", value: "holidaylets" },
]
// Search overlay dropdown
