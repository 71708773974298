import React from "react"
import NoImage from "../../images/noImage.png"
import ImageTransform from "../../common/ggfx-client/module/components/image-transform"
import useCompanyInfo from "../../hooks/useCompanyInfo"

const GGFXImage = ({
  ImageSrc,
  altText,
  className,
  imagename,
  imagetransforms,
  renderer,
  lazyLoading,
  strapi_id,
  ...props
}) => {
  const { name } = useCompanyInfo()

  if (ImageSrc && (ImageSrc.url || ImageSrc.srcUrl)) {
    const image_url = ImageSrc.url ? ImageSrc.url : ImageSrc.srcUrl
   
    let alt_text = ImageSrc.alternativeText ? ImageSrc.alternativeText : altText
    let lazyText =
      typeof lazyLoading === "undefined" || lazyLoading == true ? "lazy" : ""

    return (
      <>
        {imagename && imagetransforms ? (
          <ImageTransform
            imagesources={image_url}
            renderer={renderer}
            imagename={imagename}
            attr={{
              className,
              alt: alt_text + " - " + name,
              loading: lazyText,
            }}
            imagetransformresult={imagetransforms}
            id={strapi_id}
          />
        ) : (
          <img
            src={image_url}
            alt={alt_text + " - " + name}
            {...props}
            className={className}
          />
        )}
      </>
    )
  } else {
    return (
      <img src={NoImage} alt={altText + " - " + name} className={className} />
    )
  }
}
export default GGFXImage
