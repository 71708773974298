import { Link } from "gatsby"
import React from "react"
import { Table } from "react-bootstrap"
import { currencyFormat } from "../../common/utils/utils"
import "./AvailableProperty.scss"

const AvailableProperty = ({ data }) => {
  return (
    <div className="availability-wrapper">
      <div className="availability-section">
        <h2>Availability</h2>
        <Table hover responsive className="new-homes-table">
          <thead>
            <tr>
              <th>Type</th>
              <th>Beds</th>
              <th>Baths</th>
              <th>Price</th>
              <th>Status</th>
              <th>Details</th>
              <th>Brochure</th>
            </tr>
          </thead>
          {data.length > 0 && (
            <tbody>
              {data?.map(({ node }, index) => {
                const brochureUrl =
                  node?.brochure?.strapi_json_value?.length > 0
                    ? node.brochure?.strapi_json_value[0]?.srcUrl
                    : ""
                return (
                  <tr className={index + (1 % 2) === 0 ? "even" : "odd"}>
                    <td>{node.extra?.type}</td>
                    <td>{node?.bedroom}</td>
                    <td>{node?.bathroom}</td>
                    <td>{currencyFormat(node?.price, "£", false)}</td>
                    <td>{node?.status}</td>

                    <td>
                      <Link
                        to={`/new-homes-for-sale/${node?.slug}/${node?.strapi_id}/`}
                      >
                        View Details
                      </Link>
                    </td>
                    <td>
                      <a href={brochureUrl} target="_blank">
                        Download
                      </a>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          )}
        </Table>
      </div>
    </div>
  )
}

export default AvailableProperty
