import React from 'react'
import { Container } from "react-bootstrap"
import LoadExternalScript from "../../components/utils/load-external-script"



const SocialWall = () => {
    const socialWallScript = "https://static.elfsight.com/platform/platform.js"
  return (
    <div>
        <Container>
            <LoadExternalScript
                src={socialWallScript}
                // async="true"
                defer="true"
            // loadScript={renderMap}
            // appendScriptTo="head"
            />
            <div class="elfsight-app-6b92b4af-8c92-490f-8e23-173b91c77bf6"></div>
        </Container>
    </div>
  )
}

export default SocialWall