import React from "react"
import "./AreaguideSlider.scss"
import { Link, graphql, useStaticQuery } from "gatsby"
import { Container } from "react-bootstrap"
import { parseHTML } from "../../common/utils/utils"
import clsx from "clsx"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import AreaguideCard from "../AreaguideCard/AreaguideCard"
import Animation from "../elements/Animation"

const AreaguideSlider = ({ module }) => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiAreaGuide(filter: { publish: { eq: true } }) {
        nodes {
          ...AreaguideFragment
        }
      }
    }
  `)

  const areaguides = data?.allStrapiAreaGuide?.nodes

  if (!module || !areaguides) return null

  const { heading, title, bg_color } = module

  const settings = {
    dots: true,
    infinite: true,
    speed: 800,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
  }

  return (
    <Animation
      className={clsx("areaguide-slider-wrap section-p-120", `bg-${bg_color}`)}
    >
      <Container className="areaguide-slider-container">
        <h4 className="heading">{heading}</h4>
        <h2 className="title">{parseHTML(title.data.title)}</h2>
        <div className="slider-section">
          <Slider {...settings}>
            {areaguides.map((areaguide, index) => (
              <AreaguideCard areaguide={areaguide} index={index} />
            ))}
          </Slider>
        </div>
      </Container>
    </Animation>
  )
}

export default AreaguideSlider
