import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { Container } from "react-bootstrap"
import { parseHTML } from "../../common/utils/utils"
import Animation from "../elements/Animation"
import CTALink from "../elements/CTALink"
import "./AppraisalComponent.scss"

const AppraisalComponent = () => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiGlobalModule {
        edges {
          node {
            ...AppraisalFragment
          }
        }
      }
    }
  `)

  const appraisalData =
    data?.allStrapiGlobalModule.edges[0]?.node.book_an_appraisal

  const { cta, stat, title } = appraisalData
  return (
    <Animation className="appraisal-component">
      <Container>
        <div className="appraisal-stat section-p-80">
          <Animation animateInType={"up"} className="stat-data">
            <h2>{stat.stat_value}</h2>
            <div className="stat-content">{stat.stat_description}</div>
          </Animation>
          <Animation className="horizontal-line"></Animation>
          <Animation animateInType={"up"} className="cta-section">
            <div className="cta-title">{parseHTML(title?.data?.title)}</div>
            <CTALink
              cta={cta}
              className={"button button-white-outline appraisal-cta book_val_but2"}
            />
          </Animation>
        </div>
      </Container>
    </Animation>
  )
}

export default AppraisalComponent
