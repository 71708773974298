import React, { useEffect, useRef, useState } from "react"
import "./PropertyReadMore.scss"

const PropertyReadMore = ({ content, height, className,newHomes }) => {
  const [showReadMore, setShowReadMore] = useState(false)
  const [readMore, setReadMore] = useState(true)
  const maxHeight = height || 260

  const elementRef = useRef()

  useEffect(() => {
    if (elementRef?.current?.clientHeight > maxHeight) {
      setShowReadMore(true)
    }

    if (readMore) {
      elementRef.current.style.maxHeight = maxHeight + "px"
    } else {
      elementRef.current.style.maxHeight = ""
    }
  }, [readMore])

  if (!content) return null

  return (
    <div className={`read-more-wrap-property ${className}`}>
      <div
        ref={elementRef}
        className={`${showReadMore ? `read-more-section-property` : ""} ${
          readMore ? `read-more` : "no-read-more"
        }`}
      >
        <div dangerouslySetInnerHTML={{ __html: content }}></div>
      </div>
      {showReadMore && !newHomes&&(
        <button
          onClick={() => {setReadMore(val => !val);elementRef?.current?.scrollIntoView({ behavior: "smooth" })}
        }
          className={`read-more-button property-read-more ${
            readMore ? "" : "read-less"
          }`}
        >
          {readMore ? "Continue Reading" : "Read less"}
        </button>
      )}
    </div>
  )
}

export default PropertyReadMore
