import { graphql, useStaticQuery } from "gatsby"
import { atom, useAtom } from "jotai"
import React, { useEffect } from "react"
import { parseHTML } from "../../common/utils/utils"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import rightArrow from "../../images/icons/arrow-long-right.svg"
import closeIcon from "../../images/icons/x-mark-light.svg"
import CTALink from "../elements/CTALink"
import "./ValuationPopup.scss"
import { popOverHeight, popOverHeightMobile } from "../SearchResults/utils"

export const showPopoverAtom = atom(true)

const ValuationPopup = () => {
  const { isMobile } = useDeviceMedia()
  const popoverRef = React.useRef(null)

  const [showPopover, setShowPopover] = useAtom(showPopoverAtom)

  useEffect(() => {
    const popHeight = isMobile ? popOverHeightMobile : popOverHeight

    const body = document.querySelector("body")
    const header = document.querySelector(".header-wrap")
    const popover = popoverRef.current

    if (!body || !header || !popover) return

    if (showPopover) {
      window.scrollTo(0, 0)
      body.style.top = popHeight + "px"
      header.style.top = popHeight + "px"
      popover.style.top = "0"
    } else {
      body.style.top = "0"
      header.style.top = "0"
      popover.style.top = `-${popHeight + 1 + "px"}`
    }
  }, [showPopover, isMobile])

  const data = useStaticQuery(graphql`
    query {
      strapiGlobalModule {
        book_valuation_popup {
          title {
            data {
              title
            }
          }
          cta {
            ...CTAFragment
          }
        }
      }
    }
  `)

  const { title, cta } = data.strapiGlobalModule.book_valuation_popup

  return (
    <div className="valuation-popup-wrap" ref={popoverRef}>
      <div className="valuation-popup-container">
        <div className="content">
          {!isMobile && (
            <div className="title">{parseHTML(title.data.title)}</div>
          )}
          <CTALink className="val-cta" cta={cta}>
            <span>{cta.cta_label}</span>
            <img src={rightArrow} alt="" onClick={() => setShowPopover(true)} />
          </CTALink>
        </div>
      </div>
      <img
        src={closeIcon}
        alt=""
        className="close-button-icon"
        onClick={() => setShowPopover(false)}
      />
    </div>
  )
}

export default ValuationPopup
