import React from "react"
import { Container } from "react-bootstrap"
import { currencyFormat } from "../../common/utils/utils"
import NewPropertyCta from "../PropertyCta/NewPropertyCta"
import envIcon from "../../images/icons/envelope.svg"
import whatsapp from "../../images/icons/whatsapp_grey.svg"
import callIcon from "../../images/icons/phone.svg"
import { Link } from "gatsby"
import "./PropertyHead.scss"
const NewPropertyHead = ({ data, executeRegisterFormScroll }) => {

  let floorarea = ""

  if (data?.floorarea_type === "sqft" && data?.floorarea_max !== 0 && data?.floorarea_max) {
    floorarea = Math.floor(data?.floorarea_max / 10.764)
  }
  return (
    <div className="propertyhead">
      <Container className="content-section">
        <div className="left-section">
          <h1>{data?.display_address}</h1>
          <div className="price">
            {data?.price_prefix ? <span>
              {data?.price_prefix}
            </span> : null}
            {currencyFormat(data?.price, "£", false)} {" "}
          </div>
        </div>
        <div className="right-section right d-none d-xl-flex">
            <NewPropertyCta data={data} executeRegisterFormScroll={executeRegisterFormScroll}/>

        </div>
      </Container>
    </div>
  )
}

export default NewPropertyHead
