import React from "react"
import {
  RefinementList,
  connectMenu,
  connectRange,
  connectSearchBox,
} from "react-instantsearch-dom"

const ConnectMenu = connectMenu(() => <></>)
const ConnectRange = connectRange(() => <></>)
const ConnectSearchBox = connectSearchBox(() => <></>)

const ConnectFields = () => {
  return (
    <div className="d-none">
      <ConnectMenu attribute="building" />
      <ConnectRange attribute="price" />
      <ConnectRange attribute="bedroom" />
      <ConnectRange attribute="floorarea_max" />
      <RefinementList attribute="developer_slug" />
      <RefinementList attribute="search_areas" defaultRefinement={[]} />
      <RefinementList attribute="display_address" defaultRefinement={""} />
      <ConnectSearchBox />
    </div>
  )
}

export default ConnectFields
