import React from "react"
import { commercialPropertiesSaleURL } from "../../common/utils/urls"
import useInitPropertyData from "../../components/SearchResults/hooks/useInitPropertyData"
import SearchResultsTemplate from "../../templates/search-results-template"

const PropertySale = props => {
  const location = props.location

  useInitPropertyData({
    type: "sales",
    department: "commercial",
    status: ["For Sale"],
    locationName: commercialPropertiesSaleURL,
  })

  return <SearchResultsTemplate location={location} pSubPath="for-sale" />
}

export default PropertySale
