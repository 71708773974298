import _ from "lodash"
import { create } from "zustand"
import { devtools } from "zustand/middleware"
import { immer } from "zustand/middleware/immer"

import {
  areaSizeOptions,
  bedroomOptions,
  maxPriceOptionsResiRent,
  maxPriceOptionsResiSale,
  minPriceOptionsResiSale,
  minPriceOptionsResiRent,
} from "../SearchFilter/filterOptions"
import { DEFAULT_AREA } from "../utils"

const initialState = {
  pType: "",
  pDepartment: "",
  pStatus: [],
  locationName: "",
  searchState: {},
  minPrice: undefined,
  minPriceOptions: [],
  maxPrice: undefined,
  maxPriceOptions: [],
  bedroom: undefined,
  bedroomOptions: bedroomOptions,
  areaSize: undefined,
  areaSizeOptions: areaSizeOptions,
  propertyType: undefined,
  propertyTypeOptions: [],
  searchText: "",
  searchSlug: "",
  areaJSON: [],
  includeSold: false,
  showMap: false,
}

export const usePropertyStore = create(
  devtools(
    immer((set, get) => ({
      ...initialState,
      setPType: pType =>
        set(state => {
          state.pType = pType
        }),
      setPDepartment: pDepartment =>
        set(state => {
          state.pDepartment = pDepartment
        }),
      setPStatus: pStatus =>
        set(state => {
          state.pStatus = pStatus
        }),
      setLocationName: locationName =>
        set(state => {
          state.locationName = locationName
        }),
      addStatus: status =>
        set(state => {
          // state.pStatus.push(status)
          state.pStatus = [...state.pStatus, ...status]
        }),
      removeStatus: status =>
        set(state => {
          state.pStatus = state.pStatus.filter(item => !status.includes(item))
        }),
      setSearchState: searchState =>
        set(state => {
          state.searchState = searchState
        }),
      setMinPrice: minPrice =>
        set(state => {
          const maxPriceOptions =
            get()?.pType === "sales"
              ? maxPriceOptionsResiSale
              : maxPriceOptionsResiRent

          state.minPrice = minPrice
          state.maxPriceOptions = maxPriceOptions.filter(
            priceOption =>
              !priceOption.value ||
              !minPrice.value ||
              parseInt(priceOption.value) > parseInt(minPrice.value)
          )
        }),
      setMaxPrice: maxPrice =>
        set(state => {
          const minPriceOptions =
            get()?.pType === "sales"
              ? minPriceOptionsResiSale
              : minPriceOptionsResiRent

          state.maxPrice = maxPrice
          state.minPriceOptions = minPriceOptions.filter(
            priceOption =>
              !priceOption.value ||
              !maxPrice.value ||
              parseInt(priceOption.value) < parseInt(maxPrice.value)
          )
        }),
      setMinPriceOptions: minPriceOptions =>
        set(state => {
          state.minPriceOptions = minPriceOptions
        }),
      setMaxPriceOptions: maxPriceOptions =>
        set(state => {
          state.maxPriceOptions = maxPriceOptions
        }),
      setBedroom: bedroom =>
        set(state => {
          state.bedroom = bedroom
        }),
      setBedroomOptions: bedroomOptions =>
        set(state => {
          state.bedroomOptions = bedroomOptions
        }),
      setAreaSize: areaSize =>
        set(state => {
          state.areaSize = areaSize
        }),
      setAreaSizeOptions: areaSizeOptions =>
        set(state => {
          state.areaSizeOptions = areaSizeOptions
        }),
      setPropertyType: propertyType =>
        set(state => {
          state.propertyType = propertyType
        }),
      setPropertyTypeOptions: propertyTypeOptions =>
        set(state => {
          state.propertyTypeOptions = propertyTypeOptions
        }),
      setSearchText: searchText =>
        set(state => {
          state.searchText = searchText || ""
        }),
      setSearchSlug: (searchSlug, editSearchText) =>
        set(state => {
          if (editSearchText) {
            const areaJSON = get()?.areaJSON
            const area = areaJSON
              ?.filter(item => item.slug === searchSlug)
              ?.pop()
            state.searchSlug = searchSlug
            state.searchText = area?.name || ""
          } else {
            state.searchSlug = searchSlug
          }
        }),
      setAreaJSON: areaJSON =>
        set(state => {
          state.areaJSON = areaJSON
        }),
      setIncludeSold: includeSold =>
        set(state => {
          state.includeSold = !!includeSold
        }),
      setShowMap: showMap =>
        set(state => {
          state.showMap = !!showMap
        }),
      updateFiltersData: async () => {
        const searchState = get()?.searchState

        if (!_.isEmpty(searchState)) {
          set(state => {
            const areaJSON = get()?.areaJSON
            const isLettings = get()?.pType === "lettings"
            const minPriceOptions = isLettings
              ? minPriceOptionsResiRent
              : minPriceOptionsResiSale
            const maxPriceOptions = isLettings
              ? maxPriceOptionsResiRent
              : maxPriceOptionsResiSale

            if (searchState?.range?.price) {
              if (searchState.range?.price?.min) {
                state.minPrice = state.minPriceOptions
                  .filter(item => item.value === searchState.range?.price?.min)
                  ?.pop()

                state.maxPriceOptions = maxPriceOptions.filter(
                  priceOption =>
                    !priceOption.value ||
                    parseInt(priceOption.value) >
                      parseInt(searchState.range?.price?.min)
                )
              }
              if (searchState.range?.price?.max) {
                state.maxPrice = state.maxPriceOptions
                  .filter(item => item.value === searchState.range?.price?.max)
                  ?.pop()

                state.minPriceOptions = minPriceOptions.filter(
                  priceOption =>
                    !priceOption.value ||
                    parseInt(priceOption.value) <
                      parseInt(searchState.range?.price?.max)
                )
              }
            }
            if (searchState.range?.bedroom?.min)
              state.bedroom = state.bedroomOptions
                .filter(item => item.value === searchState.range?.bedroom?.min)
                ?.pop()
            if (searchState.range?.floorarea_max?.min)
              state.areaSize = state.areaSizeOptions
                .filter(
                  item => item.value === searchState.range?.floorarea_max?.min
                )
                ?.pop()
            if (searchState?.menu?.building) {
              state.propertyType = state.propertyTypeOptions
                .filter(item => item.value === searchState.menu?.building)
                ?.pop()
            }
            if (searchState?.query) {
              const query = searchState?.query

              const area = areaJSON
                ?.filter(item => item.slug === query || item.name === query)
                ?.pop()

              if (!_.isEmpty(area)) {
                state.searchText = area?.name
                state.searchSlug = area?.slug
              } else {
                state.searchText = query
                state.searchSlug = query
              }
            } else if (searchState?.refinementList?.search_areas) {
              if (searchState?.refinementList?.search_areas === DEFAULT_AREA)
                return

              // find if searchText or searchSlug is in areaJSON
              const area = areaJSON
                ?.filter(
                  item =>
                    item.slug === searchState?.refinementList?.search_areas ||
                    item.name === searchState?.refinementList?.search_areas
                )
                ?.pop()

              if (!_.isEmpty(area)) {
                state.searchText = area?.name
                state.searchSlug = area?.slug
              } else {
                state.searchText = searchState?.refinementList?.search_areas
                state.searchSlug = searchState?.refinementList?.search_areas
              }
            }
          })
        }
      },
      reset: () =>
        set(state => {
          state.pType = ""
          state.pDepartment = ""
          state.pStatus = []
          state.locationName = ""
          state.searchState = {}
          state.minPrice = undefined
          state.minPriceOptions = []
          state.maxPrice = undefined
          state.maxPriceOptions = []
          state.bedroom = undefined
          state.bedroomOptions = bedroomOptions
          state.areaSize = undefined
          state.areaSizeOptions = areaSizeOptions
          state.propertyType = undefined
          state.propertyTypeOptions = []
          state.searchText = ""
          state.searchSlug = ""
          state.areaJSON = []
          // state.includeSold = false
          state.showMap = false
        }),
    }))
  )
)
