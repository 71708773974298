import { Link } from "gatsby"
import React from "react"
import "./NewsCard.scss"
import { newsBlogsURL } from "../../common/utils/urls"
import Animation from "../elements/Animation"
import GGFXImage from "../elements/GGFXImage"

const NewsCard = ({ news, index }) => {
  const { tile_image, title, blog_category, slug, imagetransforms, strapi_id } =
    news

  let processedImages = imagetransforms?.tile_image_Transforms
  processedImages ||= JSON.stringify({})

  return (
    <Animation
      animateInType={"up"}
      className="news-card"
      key={"newscard" + index}
    >
      <Link to={`${newsBlogsURL}${slug}/`} className="news-img img-zoom">
        <GGFXImage
          ImageSrc={tile_image}
          title={title}
          altText={title}
          imagetransforms={processedImages}
          renderer="srcSet"
          imagename={"blog.tile_image.image"}
          strapi_id={strapi_id}
        />
      </Link>
      <div className="news-content">
        <h4 className="category">{blog_category.name}</h4>
        <Link to={`${newsBlogsURL}${slug}/`} className="title">
          {title}
        </Link>
      </div>
    </Animation>
  )
}

export default NewsCard
