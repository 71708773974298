import React from "react"
import { Container } from "react-bootstrap"
import { parseHTML } from "../../common/utils/utils"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import usePagination from "../../hooks/usePagination"
import GetReview from "../GetReview/GetReview"
import Pagination from "../Pagination/Pagination"
import Animation from "../elements/Animation"
import "./Reviews.scss"

const Reviews = ({ testimonials, loader }) => {
  // for pagination
  const { isMobile, isTablet } = useDeviceMedia()

  const itemsPerPage = isMobile ? 8 : isTablet ? 10 : 12
  const paginationProps = usePagination({
    items: testimonials,
    itemsPerPage,
  })
  // for pagination
  return (
    <div className="reviews-page">
      {/* {loader && <div className="loader-icon" />} */}
      <Container>
        <div className="section-m-120">
          <div className="reviews-block">
            {paginationProps?.currentItems?.map((item, i) => {
              return (
                <Animation animateInType={"up"} className="review-item">
                  <GetReview starRating={item?.starRating} />
                  <div className="comment">{parseHTML(item?.comment)}</div>
                  <div className="name">{item?.reviewer?.displayName}</div>
                </Animation>
              )
            })}
          </div>
          <Pagination {...paginationProps} />
        </div>
      </Container>
    </div>
  )
}

export default Reviews
