import React from "react"
import Layout from "../components/Layout/Layout"
import AppraisalComponent from "../components/AppraisalComponent/AppraisalComponent"
import { graphql } from "gatsby"
import clsx from "clsx"
import AreaGuideListing from "../components/AreaGuideListing/AreaGuideListing"
import LocRatingMap from "../components/map/loc-rating-map-office"
import Animation from "../components/elements/Animation"
import SEO from "../components/SEO/seo"
const AreaguideLandingPage = ({ data }) => {
  const pageData = data?.strapiPage
  const modules = pageData?.add_page_modules
  const areaGuide = data?.allStrapiAreaGuide?.edges


  let mapItems = []
  {
    areaGuide.map((node, key) => {

      if (node.node?.latitude && node.node?.longitude) {
        let mapItem = {}
        mapItem["lat"] = parseFloat(node.node?.latitude)
        mapItem["lng"] = parseFloat(node.node?.longitude)
        mapItem["id"] = node.node?.id
        const mapMarkerImageShowFlag = 0

        mapItems.push(mapItem)
      }
    })
  }


  return (
    <div className={clsx("home-template", pageData.page_class)}>
      <Layout
        popular_search={pageData?.select_popular_search}
        pageData={pageData}
      >
        <SEO title={pageData?.seo?.meta_title ? pageData?.seo?.meta_title : pageData?.title} description={pageData?.seo?.meta_description ? pageData?.seo?.meta_description : pageData?.title} />
        {modules?.map((module, index) => {
          return (
            <div>
              {module.__typename ===
                "STRAPI__COMPONENT_PAGE_MODULES_LISTING_MODULE" &&
                module.select_module === "area_guides" && (
                  <>
                    {" "}
                    <AreaGuideListing
                      pageData={pageData}
                      areaGuideData={areaGuide}
                    />
                    {/* <Animation
                      className="office-landing-map"
                      id="office-landing-map"
                    >
                      <LocRatingMap data={mapItems} />
                    </Animation> */}
                  </>
                )}
              {module.__typename ===
                "STRAPI__COMPONENT_PAGE_MODULES_GLOBAL_MODULE" &&
                module.select_module === "book_an_appraisal" && (
                  <AppraisalComponent />
                )}
            </div>
          )
        })}
      </Layout>
    </div>
  )
}

export default AreaguideLandingPage

export const query = graphql`
  query ($page_id: String) {
    strapiPage(id: { eq: $page_id }) {
      ...PageFragment
      add_page_modules {
        ... on STRAPI__COMPONENT_PAGE_MODULES_LISTING_MODULE {
          __typename
          id
          select_module
          strapi_id
        }
        ... on STRAPI__COMPONENT_PAGE_MODULES_GLOBAL_MODULE {
          __typename
          id
          select_module
        }
      }
    }
    allStrapiAreaGuide(filter: { publish: { eq: true } }) {
      edges {
        node {
          ...AreaGuideFragment
        }
      }
    }
  }
`
