import clsx from "clsx"
import { Link } from "gatsby"
import React from "react"
import { Container } from "react-bootstrap"
import { propertiesRentURL, propertiesSaleURL } from "../../common/utils/urls"
import { parseHTML } from "../../common/utils/utils"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import watermark from "../../images/banner-watermark.svg"
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs"
import Animation from "../elements/Animation"
import GGFXImage from "../elements/GGFXImage"
import "./AreaGuideBanner.scss"

const AreaGuideBanner = ({
  data,
  homePage,
  pageData,
  breadcrumbURLs,
  transparentHeader,
}) => {
  const { isTablet } = useDeviceMedia()

  if (!pageData) return null

  const { strapi_id, imagetransforms } = pageData

  let processedImages = imagetransforms?.banner_img_Transforms
  processedImages ||= JSON.stringify({})
  const pageLayout = pageData?.layout
  const menuData = pageData?.menu
  const isTransparent =
    pageLayout === "home_page" ||
    pageLayout === "landing_page" ||
    transparentHeader

  // console.log("areaguide", pageData)
  return (
    <Animation
      animateInType={"no"}
      className={clsx(
        "area-guide-banner-landing-wrap",
        homePage && "home-banner"
      )}
    >
      {/* {JSON.stringify(pageData?.banner_img)} */}
      <GGFXImage
        ImageSrc={pageData?.banner_img}
        title={pageData?.name}
        altText={"area-guide-banner-img"}
        imagetransforms={processedImages}
        renderer="srcSet"
        imagename={"area-guide.banner_img.banner_image"}
        strapi_id={strapi_id}
        className="bg-image"
      />
      <div className="bg-img-overlay"></div>
      {!isTablet && homePage && (
        <img
          src={watermark}
          alt="area-guide-banner-watermark"
          className="bg-watermark"
        />
      )}
      <Container className="area-guide-banner-landing-container">
        <Breadcrumbs
          menuData={menuData}
          isTransparent={true}
          staticURLs={breadcrumbURLs}
        />
        <h1 className="area-guide-banner-landing-title">
          {/* {parseHTML(pageData?.name)} */}
          {pageData?.banner_title?pageData?.banner_title:pageData?.name}
        </h1>
        <div className="cta-section">
          <Link class="button button-white" to="/property-valuation/">
            <span>Book a Valuation</span>
          </Link>
          <Link
            class="button button-white-outline"
            to={`${propertiesSaleURL}in-${pageData?.slug}/`}
          >
            <span>property for sale</span>
          </Link>
          <Link
            class="button button-white-outline"
            to={`${propertiesRentURL}in-${pageData?.slug}/`}
          >
            <span>property to rent</span>
          </Link>
        </div>
      </Container>
    </Animation>
  )
}

export default AreaGuideBanner
