import clsx from "clsx"
import { graphql } from "gatsby"
import React, { useEffect, useRef, useState } from "react"
import { Container } from "react-bootstrap"
import { Helmet } from "react-helmet"
import NewAppraisalCard from "../components/AppraisalCard/NewAppraisalCard"
import RegisterYourIntrest from "../components/RegisterYourIntrest/RegisterYourIntrest"
import NewAvailableProperty from "../components/AvailableProperty/NewAvailableProperty"
import Layout from "../components/Layout/Layout"
import NewPropertyBanner from "../components/PropertyBanner/NewPropertyBanner"
import PropertyCalculator from "../components/PropertyCalculator/PropertyCalculator"
import NewPropertyCta from "../components/PropertyCta/NewPropertyCta"
import NewPropertyHead from "../components/PropertyHead/NewPropertyHead"
import PropertyNegotiator from "../components/PropertyNegotiator/PropertyNegotiator"
import PropertyReadMore from "../components/PropertyReadMore/PropertyReadMore"
import SEO from "../components/SEO/seo"
import LocRatingMap from "../components/map/loc-rating-map"
import { MapMarkerInfoBox } from "../components/map/map-marker"
import useDeviceMedia from "../hooks/useDeviceMedia"
import CookieConsent from '../components/CookieConsent/CookieConsent'
import "../styles/other/PropertyDetailTemplate.scss"

const NewPropertyDetailsTemplate = ({ data, children }) => {
  const { isTablet, isMobile } = useDeviceMedia()
  const myRef = useRef(null)
  const registerform = useRef(null)
  const executeScroll = () => {
    myRef?.current?.scrollIntoView({ behavior: "smooth" })
  }
  const executeRegisterFormScroll = () => {
    registerform?.current?.scrollIntoView({ behavior: "smooth" })
  }
  const PageData = data?.strapiNewProperty
  const teams = data?.strapiNewProperty?.teams;

  var imagename = "new-property.images.details"
  let processedImages = JSON.stringify({})
  if (data?.strapiNewProperty?.imagetransforms?.images_Transforms) {
    processedImages = data?.strapiNewProperty?.imagetransforms?.images_Transforms
  }
  const url = typeof window !== "undefined" ? window.location.href : ""
  let mapItems = []
  let mapItem = {}
  mapItem["lat"] = PageData?.latitude
  mapItem["lng"] = PageData?.longitude
  const mapMarkerImageShowFlag = 0
  let mapItemHtml = MapMarkerInfoBox("test", mapMarkerImageShowFlag)
  mapItem["html"] = mapItemHtml
  mapItems.push(mapItem)

  const propertyCta = (
    <div className="mob-tab-view d-block d-xl-none">
      <NewPropertyCta data={PageData} executeRegisterFormScroll={executeRegisterFormScroll} />
    </div>
  )
// console.log('PageData', PageData)

  return (
    <div className={clsx("property-template new-property-template campaign_landing")}>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Layout
        propertydetails={true}
        afterFooter={propertyCta}
        className="property-details-page-layout"
        pageData={
          { page_class: 'campaign_landing' }
        }
        noFooter={true}
      >
        {PageData.title &&
          <div className="heading">
            <p>{PageData.title}</p>
          </div>
        }

        {!isTablet && !isMobile ?
          <div className="d-none d-xl-block">
            <NewPropertyHead data={PageData} executeRegisterFormScroll={executeRegisterFormScroll} />
          </div> : null}
        <NewPropertyBanner
          executeScroll={executeScroll}
          latitude={PageData?.latitude}
          longitude={PageData?.longitude}
          {...data.strapiNewProperty}
          // propImg={propertyDetailImg}
          processedImages={processedImages}
          imagename={imagename}
          crm_id={data?.strapiNewProperty?.strapi_id}
          prop_id={data?.strapiNewProperty?.strapi_id}
          broucher={PageData.broucher}
        />

        {isTablet ?
          <div className="d-block d-xl-none">
            <NewPropertyHead data={PageData} />
          </div> : null}
        <Container className="content-section section-p-80">
          <div className="left-section">
            {PageData?.description?.data?.description && (
              <div className="description-block">
                <h4>Property Description</h4>
                <PropertyReadMore
                  content={PageData?.description?.data?.description}
                  height={270}
                  className={"property-desc-text"}
                />
              </div>
            )}

            {PageData?.features?.length > 0 && (
              <div className="description-block">
                <h4>Key Features</h4>
                <ul className="feature-ul">
                  {PageData?.features?.map(
                    (item, i) => {
                      return <li>{item.text}</li>
                    }
                  )}
                </ul>
              </div>
            )}

            {PageData.availability && PageData.availability?.length > 0 &&
              <>
                <hr></hr>
                <NewAvailableProperty data={PageData.availability} broucher={PageData.broucher} email={PageData.email} />
              </>
            }

            <>
              <hr></hr>
              <div className="description-block">
                <PropertyCalculator prop_price={PageData?.price} hideNote={true}  />
              </div>
            </>
          </div>
          <div className="right-section">
            <div className="sticky-section">
              {teams && teams.length > 0 &&
                <>
                  {teams?.map(
                    (item, i) => {
                      return <PropertyNegotiator prop_address={PageData?.display_address} altPhone={PageData.team_phone} altEmail={PageData.team_email} prop_url={url} teamData={item} isLinkDisable={true} />
                    }
                  )}
                </>

              }
              <NewAppraisalCard executeRegisterFormScroll={executeRegisterFormScroll} />
            </div>
          </div>
        </Container>
        {PageData?.latitude && PageData?.longitude ? (
          <div className="property-map">
            <div className="scrolling-top" ref={myRef}></div>
            <LocRatingMap data={mapItems} />
          </div>
        ) : null}
        <div ref={registerform}></div>
        <RegisterYourIntrest email={PageData?.email}/>
        <CookieConsent/>
      </Layout>
    </div>
  )
}


export const query = graphql`
  query ($strapi_id: Int) { 
    strapiNewProperty(strapi_id: { eq: $strapi_id }) {
      id
      strapi_id
      title 
      display_address 
      meta_title
      meta_description
      slug
      price_prefix
      price
      images{
        url
        name
      }
      broucher{
        url
        name
      } 
      latitude
      longitude
      phone
      email
      whatsapp
      description{
        data {
          description
        }
      } 
      features {
        text
      }
      availability{
        type
        beds
        baths
        price
        status
      }
      team_phone
      team_email
      imagetransforms {
        images_Transforms
      }
      teams {
          name
          email
          phone
          imagetransforms {
            image_Transforms
          }
          image {
            url
            name
          }
          position
          slug
      }

    }
  }
`
export const Head = ({ data }) => {
  const propertyData = data?.strapiNewProperty
  return <SEO title={propertyData.meta_title} description={propertyData.meta_description} />
}

const NewPropertyDetail = props => {
  return (
    <NewPropertyDetailsTemplate {...props} />
  )
}

export default NewPropertyDetail
