import { useLocation } from "@react-hooks-library/core"
import { useEffect, useState } from "react"

const TABS = {
  residential: "residential",
  commercial: "commercial",
  new_homes: "new_developments",
}

const useSearchTabs = () => {
  const [activeKey, setActiveKey] = useState(TABS.residential)

  const location = useLocation()
  const pathname = location?.pathname

  useEffect(() => {
    if (pathname) {
      if (pathname.includes("new-homes")) {
        setActiveKey(TABS.new_homes)
      } else if (pathname.includes("commercial")) {
        setActiveKey(TABS.commercial)
      } else {
        setActiveKey(TABS.residential)
      }
    }
  }, [location])

  useEffect(() => {
    // do something when activeKey changes
  }, [activeKey])

  return {
    TABS,
    activeKey,
    setActiveKey,
  }
}

export default useSearchTabs
