import React from "react"
import "./Sitemap.scss"
import { areaGuidesURL } from "../../common/utils/urls"
import { Container } from "react-bootstrap"
import useNavMenus from "../../hooks/dataHooks/useNavMenus"
import useAreaGuides from "../../hooks/dataHooks/useAreaGuides"
import CTALink from "../elements/CTALink"
import { Link } from "gatsby"
const Sitemap = props => {
  const menus = useNavMenus()
  const areaguides = useAreaGuides()
  return (
    <div className="sitemap-page-block">
      <Container>
        <div className="width-value">
          <h1>{props?.pageData?.title}</h1>
          <div className="data">
            {menus.map(menu => (
              <ul className="sitemap">
                {menu.main_cta.custom_link === "$find-a-property" ? (
                  <li>
                    <Link className={"main-menu"}>
                      {menu.main_cta?.cta_label}
                    </Link>
                    <ul className="sub-sitemap">
                      {menu.ctas.map(cta => (
                        <li>
                          <CTALink cta={cta} className={"sub-menu"} />
                        </li>
                      ))}
                    </ul>
                  </li>
                ) : (
                  <li>
                    <CTALink cta={menu.main_cta} className={"main-menu"} />
                    <ul className="sub-sitemap">
                      {menu.ctas.map(cta => (
                        <li>
                          <CTALink cta={cta} className={"sub-menu"} />
                          {cta?.cta_label === "Area Guides" && (
                            <ul className="sub-sub-sitemap">
                              {areaguides?.map((cta, i) => {
                                return (
                                  <li>
                                    <Link
                                      className={"sub-menu"}
                                      to={areaGuidesURL + cta?.slug}
                                    >
                                      {cta?.name}
                                    </Link>
                                  </li>
                                )
                              })}
                            </ul>
                          )}
                        </li>
                      ))}
                    </ul>
                  </li>
                )}
              </ul>
            ))}
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Sitemap
