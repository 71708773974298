import parse from "html-react-parser"
import axios from "axios"

export const getMenuLink = menu => {
  let link = {}

  if (menu) {
    const { slug, custom_link, external_link, strapi_parent } = menu
    const formattedCustomLink = custom_link?.replace(/ /g, "")

    if (formattedCustomLink) {
      if (formattedCustomLink.includes("http") || external_link) {
        link = {
          href: formattedCustomLink,
          target: "_blank",
        }
      } else {
        link = {
          to: formattedCustomLink,
        }
      }
    } else if (slug) {
      // TODO: add recursive function to get parent slugs
      if (strapi_parent) {
        link = {
          to: `/${strapi_parent.slug}/${slug}/`,
        }
      } else {
        link = {
          to: `/${slug}/`,
        }
      }
    }
  }

  return link
}

export const parseHTML = htmlString => {
  if (typeof htmlString === "string" && htmlString?.length > 0) {
    return parse(htmlString)
  }

  return null
}

export const getButtonClass = theme => {
  let buttonClass = "button "

  switch (theme) {
    case "white":
      buttonClass += "button-white"
      break
    case "white-outline":
      buttonClass += "button-white-outline"
      break
    case "green":
      buttonClass += "button-green"
      break
    case "green-outline":
      buttonClass += "button-green-outline"
      break

    default:
      buttonClass += "button-white"
  }

  return buttonClass
}

export const breakString = (string, breakCOunt) => {
  if (!string) return null

  const words = string.split(" ")

  if (words.length < breakCOunt) return string

  const lastWord = "<br />" + words.pop()
  const formattedString = words.join(" ") + lastWord

  return formattedString
}

// TODO: make this recursive
export const getBreadcrumbUrls = menuData => {
  let urls = []
  let current = menuData
  if (current?.strapi_parent?.strapi_parent) {
    urls.push({
      label: current.strapi_parent.strapi_parent.title,
      url: `/${current.strapi_parent.strapi_parent.slug}/`,
    })
    urls.push({
      label: current.strapi_parent.title,
      url: `/${current.strapi_parent.strapi_parent.slug}/${current.strapi_parent.slug}/`,
    })
    urls.push({
      label: current.title,
      url: `/${current.strapi_parent.strapi_parent.slug}/${current.strapi_parent.slug}/${current.slug}/`,
    })
  } else if (current?.strapi_parent) {
    urls.push({
      label: current.strapi_parent.title,
      url: `/${current.strapi_parent.slug}/`,
    })
    urls.push({
      label: current.title,
      url: `/${current.strapi_parent.slug}/${current.slug}/`,
    })
  } else {
    urls.push({
      label: current?.title,
      url: `/${current?.slug}/`,
    })
  }
  return urls
}

// Dropdown react select styles
export const customStylesNews = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isSelected ? "#001427" : "null",
      color: isSelected ? "#ffffff" : "#34373D",
      "&:hover": {
        color: "#ffffff",
        cursor: "pointer",
        backgroundColor: "#001427",
      },
    }
  },
  control: styles => ({
    ...styles,
    backgroundColor: null,
    border: 0,
    paddingLeft: 0,
    outline: 0,
    boxShadow: "none",
    color: "#fff",
    fontSize: "1rem",
  }),
  valueContainer: styles => ({
    ...styles,
    fontSize: "1rem",
    paddingLeft: 0,
    lineHeight: "21px",
    cursor: "pointer",
  }),
  dropdownIndicator: styles => ({
    ...styles,
    color: "#fff",
  }),
  indicatorsContainer: styles => ({
    ...styles,
    color: "#fff",
    cursor: "pointer",
  }),
  indicatorSeparator: () => null,
  placeholder: defaultStyles => {
    return {
      ...defaultStyles,
      color: "#34373D",
      marginLeft: 0,
    }
  },
}
// Dropdown react select styles

export const getStrapiDataFromURL = async url => {
  if (!url) return null

  const getData = () =>
    axios.get(`${process.env.GATSBY_STRAPI_SRC}${url}`, {
      headers: {
        Authorization: `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
      },
    })
  const res = await getData()

  return res?.data
}
export const trackShare = (event, shareURL) => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: "Share - social",
    formType: event + " - " + shareURL,
    formId: "Share",
    formName: "Share",
    formLabel: "Share",
  })
}

export const currencyFormat = (num, currency = "£", withSpace = false) => {
  var filterNum = filterNumber(num)
  if (!filterNum) {
    return `${currency} 0`
  }
  if (withSpace) {
    return `${currency} ${new Intl.NumberFormat("en-US", {}).format(filterNum)}`
  } else {
    return `${currency}${new Intl.NumberFormat("en-US", {}).format(filterNum)}`
  }
}

/** Get number only */
export const filterNumber = str => {
  if (!str) return 0
  str = str.toString()
  return parseInt(str.replace(/[^\d.]/g, ""), 10)
}

export const eventTracking = ({ eventName , url }) => {
  // tracking event
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: eventName,
    formType: url,
    formId: eventName,
    formName: eventName,
    formLabel: eventName,
  })
}