import React from "react"
import { Container } from "react-bootstrap"
import { parseHTML } from "../../common/utils/utils"
import "./StaticPageBlock.scss"
const StaticPageBlock = props => {
  // console.log(props)
  return (
    <div className="static-page-block">
      <Container>
        <div className="width-value">
          {props?.isStatic ? null : <h1>{props?.pageData?.title}</h1>}
          <div className="data">
            {parseHTML(props?.module?.content?.data?.content)}
          </div>
        </div>
      </Container>
    </div>
  )
}

export default StaticPageBlock
