import React from "react";
import { isEmpty } from "lodash";
import { ImageModule } from "@starberry/gatsby-theme-utils/Modules/ImageModule";

const ImageCallBack = ({ imageSrc, processedImages, renderer, strapi_id, imagename, altText, classNames }) => {
  const processRes = JSON.parse(processedImages);
  const ggfx_env = process.env.GATSBY_STRAPI_GGFX_ENV;
  const image_url = imageSrc?.url;
  const getSrcUrl = image_url?.substring(image_url?.indexOf(ggfx_env));
  const checkGGFXImg = processRes?.[getSrcUrl]?.["webp"];
  
  const imgProps = {
    src: imageSrc?.url || imageSrc?.srcUrl,
    alt: altText,
    className: classNames,
  };

  return !isEmpty(getSrcUrl) ? (
    <ImageModule
      ImageSrc={imageSrc}
      altText={altText}
      imagetransforms={processedImages}
      renderer={renderer}
      imagename={imagename}
      strapi_id={strapi_id}
      classNames={classNames}
    />
  ) : (
    <img {...imgProps} />
  );
};

export default ImageCallBack;