import React from "react"
import "./elements.scss"
import clsx from "clsx"

const CheckBox = ({ label, checked, setChecked, className }) => {
  return (
    <div
      className={clsx("check-box", className)}
      onClick={() => setChecked(!checked)}
    >
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {checked && (
          <path
            d="M13.4375 8.125L8.85414 12.5L6.5625 10.3125"
            stroke={checked ? "#212616" : "#B3B7AA"}
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        )}
        <path
          d="M16.25 3.125H3.75C3.40482 3.125 3.125 3.40482 3.125 3.75V16.25C3.125 16.5952 3.40482 16.875 3.75 16.875H16.25C16.5952 16.875 16.875 16.5952 16.875 16.25V3.75C16.875 3.40482 16.5952 3.125 16.25 3.125Z"
          stroke={checked ? "#212616" : "#B3B7AA"}
          stroke-width="1.2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <p className="check-label">{label}</p>
    </div>
  )
}

export default CheckBox
