import React from "react"
import "./OurServicesBlock.scss"
import { Container } from "react-bootstrap"
import { getMenuLink } from "../../common/utils/utils"
import { parseHTML } from "../../common/utils/utils"
import { Link } from "gatsby"
import PlayVideoButton from "../PlayVideo/PlayVideoButton"
import { serviceDetails } from "../../common/utils/urls"
import Animation from "../elements/Animation"
import GGFXImage from "../elements/GGFXImage"

const OurServicesBlock = ({ module, imagetransforms, strapi_id }) => {
  const { heading, services, title } = module
  return (
    <div className="our-services-wrapper">
      <Container>
        <div className="our-services-component section-m-120">
          <Animation className="services-section">
            <p className="heading">{heading}</p>
            <h2 className="title">{parseHTML(title?.data?.title)}</h2>
            {services?.length > 0 && (
              <div className="services-list">
                {services.map((service, i) => {
                  // const link = getMenuLink(service?.menu)
                  let processedImages =
                    imagetransforms?.add_page_modules_image_Transforms
                  processedImages ||= JSON.stringify({})

                  return (
                    <Animation
                      animateInType={"up"}
                      delay={i * 100}
                      className="items"
                    >
                      <div className="image-section">
                        <Link {...getMenuLink(service.menu)}>
                          <GGFXImage
                            ImageSrc={service?.image}
                            title={service?.label}
                            altText={service?.label}
                            imagetransforms={processedImages}
                            renderer="srcSet"
                            imagename={
                              "page.add_page_modules_image.service_tile_img"
                            }
                            strapi_id={strapi_id}
                          />
                        </Link>
                        <PlayVideoButton videoUrl={service?.video_url} />
                      </div>
                      <Link
                        className="label-item"
                        {...getMenuLink(service.menu)}
                      >
                        {service?.label}
                      </Link>
                    </Animation>
                  )
                })}
              </div>
            )}
          </Animation>
        </div>
      </Container>
    </div>
  )
}

export default OurServicesBlock
