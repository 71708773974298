import React from "react"
import InstantValuationForm from "../forms/instant-valuation-form"
import FormFields from "../../../static/forms/instant_valuation.json"
import "./InstantValuation.scss"
import { parseHTML } from "../../common/utils/utils"
import { Container } from "react-bootstrap"
import { useState } from "react"

const InstantValuation = ({ data }) => {
  const [showContent, setShowContent] = useState(true)
  const title = data.title.data.title
  const content = data.content.data.content
  return (
    <div className="instant-valuation-wrapper">
      <Container>
        <div className="instant-valuation-module">
          {showContent && (
            <div className="content-section">
              <h1 className="title">{parseHTML(title)}</h1>
              <p className="content">{parseHTML(content)}</p>
            </div>
          )}
          <InstantValuationForm
            fields={FormFields}
            formtagclassname="instant-valuation"
            setShowContent={setShowContent}
          />
        </div>
      </Container>
    </div>
  )
}

export default InstantValuation
