import clsx from "clsx"
import React, { createContext, useContext, useState } from "react"
import "./CustomTabs.scss"

const TabContext = createContext()

export const useTabs = () => {
  return useContext(TabContext)
}

export const Tabs = ({
  activeKey,
  setActiveKey,
  children,
  className,
  onChange,
}) => {
  const onTabChange = key => {
    setActiveKey(key)
    onChange && onChange(key)
  }

  return (
    <TabContext.Provider
      value={{
        activeKey,
        onTabChange,
      }}
    >
      <div className={clsx("custom-tabs", className)}>{children}</div>
    </TabContext.Provider>
  )
}

export const Tab = ({ eventKey, children, className }) => {
  const { activeKey, onTabChange } = useTabs()

  return (
    <button
      className={clsx(
        "tab-button",
        activeKey === eventKey && "selected-tab",
        className
      )}
      onClick={() => onTabChange(eventKey)}
    >
      {children}
    </button>
  )
}
