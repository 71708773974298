import React, { useState } from "react"
import LoadExternalScript from "../utils/load-external-script"
import { markerImageSrc } from "./map-marker-image"
import classNames from "classnames"
import { Container } from "react-bootstrap"
/* TODO: To be moved to env. or config. file */
const locRatingScriptsJs =
  "https://www.locrating.com/scripts/locratingIntegrationScripts.js"

const LocRatingMap = props => {
  // console.log("Inside function LocRatingMap");

  let [activeCTA, setActiveCTA] = useState("location")
  // console.log('activeCTA : ' + activeCTA);

  let _data = props.data || ""
  // console.log('_data (below) : ');
  // console.log(_data);
  // console.log('typeof _data ' + typeof _data);
  // console.log('typeof _data.length : ' + typeof _data.length );
  // console.log('_data.length : ' + _data.length );

  let _mapClass = "single" // only one marker map
  if (_data.length && _data.length > 1) {
    // more than one markers map
    _mapClass = "all"
  }

  let _markerImage = markerImageSrc
  // console.log('_markerImage : ' + _markerImage);

  const _iframeId = "location-map"
  // console.log('_iframeId : ' + _iframeId);

  const _iframeTitle = "Location Map"
  // console.log('_iframeTitle : ' + _iframeTitle);

  const _location = "Location"
  const _schools = "Schools"
  const _localInfo = "Local info"

  const handleClick = (event, name) => {
    // console.log('Inside function handleClick()');
    // console.log('name : ' + name);
    event.preventDefault()
    setActiveCTA(name)
    renderMap(name)
  }

  const renderMap = _activeCTA => {
    // console.log('Inside function renderMap()');
    // console.log('activeCTA : ' + activeCTA);
    if (_activeCTA) {
      activeCTA = _activeCTA
    }
    // console.log('window (below) : ');
    // console.log(window);
    // console.log('_data (below) : ');
    // console.log(_data);

    let _type = ""
    switch (activeCTA) {
      case "location":
      default:
        _type = "transport"
        break
      case "schools":
        _type = "schools"
        break
      case "local":
        _type = "localinfo"
        break
    }
    // console.log('_type : ' + _type);

    if (typeof window !== "undefined") {
      const _baseUrl = window.location.origin
      // console.log('_baseUrl : ' + _baseUrl);
      if (_markerImage?.toLowerCase().indexOf(_baseUrl) === -1) {
        // if baseUrl not already added ...
        _markerImage = _baseUrl + _markerImage // ... add it
      }
      // console.log('_markerImage : ' + _markerImage);
      // console.log(typeof window.loadLocratingPlugin);
      if (typeof window.loadLocratingPlugin !== "undefined") {
        // console.log('Inside window.loadLocratingPlugin');
        try {
          // try
          // console.log('Inside try block');
          // console.log('_data (below) : ');
          // console.log(_data);
          let _firstLat = ""
          let _firstLng = ""
          if (_data) {
            if (_data[0]) {
              if (_data[0].lat) {
                _firstLat = _data[0].lat
              }
              if (_data[0].lng) {
                _firstLng = _data[0].lng
              }
            }
            // console.log("firstLat : " + _firstLat);
            // console.log("firstLng : " + _firstLng);
            // console.log('_iframeId : ' + _iframeId);
            // console.log('_markerImage : ' + _markerImage);
            // console.log('_mapClass : ' + _mapClass);
            let _firstMarkerImage = _markerImage
            if ("all" === _mapClass) {
              _firstMarkerImage = "."
            }
            // console.log('_firstMarkerImage : ' + _firstMarkerImage);
            if ("single" === _mapClass) {
              _type = "all"
            }
            // console.log('_type : ' + _type);
            window.loadLocratingPlugin({
              id: _iframeId,
              lat: _firstLat,
              lng: _firstLng,
              type: _type,
              zoom: 14,
              icon: _firstMarkerImage,
              hidestationswhenzoomedout: true,
              onLoaded: function () {
                // console.log('Inside function onLoaded()');
                let _n = 1
                for (let i in _data) {
                  // console.log('i : ' + i);
                  let _item = _data[i]
                  // console.log('_item (below) : ');
                  // console.log(_item);
                  // console.log('_n : ' + _n);
                  // let _markerId = "marker-" + _n
                  let _markerId =  _item.id
                 
                  // console.log('_markerId : ' + _markerId);
                  let _lat = _item.lat
                  // console.log('_lat : ' + _lat);
                  let _lng = _item.lng
                  // console.log('_lng : ' + _lng);
                  let _html = _item.html
                  // console.log('_html : (below) ');
                  // console.log(_html);
                  // console.log('_markerImage : ' + _markerImage);
                  // console.log(typeof window.addLocratingMapMarker);
                  if (typeof window.addLocratingMapMarker !== "undefined") {
                    // console.log('Inside window.addLocratingMapMarker');
                    window.addLocratingMapMarker(_iframeId, {
                      id: _markerId,
                      lat: _lat,
                      lng: _lng,
                      html: _html,
                      icon: _markerImage,
                      iconHeight: 68,
                      iconWidth: 69,
                      zoom: 11,
                    })
                  }
                  _n++
                } // for
                // console.log(typeof window.centerLocratingMapUsingMarkers);
                if (
                  typeof window.centerLocratingMapUsingMarkers !== "undefined"
                ) {
                  // console.log('Inside window.centerLocratingMapUsingMarkers');
                  window.centerLocratingMapUsingMarkers(_iframeId)
                }
              }, // onLoaded: function()
            }) // window.loadLocratingPlugin
          } // if (data)
        } catch (err) {
          //  console.log(err);
        } // catch (err)
      } // if (typeof window.loadLocratingPlugin !== 'undefined')
    } // if (typeof window !== 'undefined')
  } // const renderMap = ()

  return (
    <div id="map-holder" className={classNames("map", _mapClass)}>
      {/* <h1>LocRating Map</h1> */}
      <LoadExternalScript
        src={locRatingScriptsJs}
        // async="true"
        defer="true"
        loadScript={renderMap}
        // appendScriptTo="head"
      />
      {/* <div className="cta">
                <Container>
                <a href="/" className={classNames({'is-active': activeCTA === 'location'})}
                    onClick={(event) => handleClick(event, 'location')}>{_location}</a>
                <a href="/" className={classNames({'is-active': activeCTA === 'schools'})}
                    onClick={(event) => handleClick(event, 'schools')}>{_schools}</a>
                <a href="/" className={classNames({'is-active': activeCTA === 'local'})}
                    onClick={(event) => handleClick(event, 'local')}>{_localInfo}</a>
                </Container>
            </div> */}
      <iframe id={_iframeId} title={_iframeTitle} width="100%" height="500"></iframe>
    </div>
  )
}

export default LocRatingMap
