import React from "react"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import { propertydetailsNewhomesSaleURL } from "../../common/utils/urls"
import clsx from "clsx"
import GGFXImage from "../elements/GGFXImage"
import Animation from "../elements/Animation"
import imageConfig from "../../../static/images/config.json"
import NoImage from "../../images/noImage.png"
import { ShowProcessedImage } from "../../common/ggfx-client/module/components/show-image"
import { Link } from "gatsby"
import HeartIcon from "../elements/HeartIcon"
import { currencyFormat } from "../../common/utils/utils"
import bedIcon from "../../images/icons/Bed.svg"
import PropertyReadMore from "../PropertyReadMore/PropertyReadMore"
import "./NewHomesCard.scss"


const NewHomesCard = ({
  noanimation,
  image,
  price,
  address,
  description,
  slug,
  id,
  pid,
  strapi_id,
  search_type,
  status,
  imagetransforms,
  slider_name,
  bedroom,
  bathroom,
  reception,
  floorarea_type,
  floorarea_max,
  department,
  propertyPage,
  userObjects,
  price_qualifier,
  bedroom_range,
  price_range
}) => {
  const { isTablet } = useDeviceMedia()
  let url = ""
  if (department === "new_developments") {
    url = propertydetailsNewhomesSaleURL
  }

  let processedImages
  if (imagetransforms) {
    if (slider_name === "area-guide-slider" || slider_name === "no-hits") {
      processedImages = imagetransforms?.images_Transforms || JSON.stringify({})
    } else {
      processedImages =
        JSON.parse(imagetransforms)?.images_Transforms || JSON.stringify({})
    }
  }

  const imagename = "property.images.tileimg"
  const bedText="Bedroom properties for sale"

  let minPriceRange;
  let maxPriceRange;
  if(price_range?.length>1){
    maxPriceRange = Math.max(...price_range);
    minPriceRange = Math.min(...price_range);
  } 

  let minBed;
  let maxBed;
  if(bedroom_range?.length>1){
    minBed=Math.min(...bedroom_range)
    maxBed=Math.max(...bedroom_range)
  }

  return (
    <Animation
      animateInType={noanimation ? "no" : "up"}
      className={clsx("property-card-section-new-homes"
      )}
    >
      <div className="newhome-card-wrapper">
        <div className="newhome-card-sections">
          <div className="image-section img-zoom">
            <Link to={`${url}${slug}/${strapi_id}/`}>
              {image ? (
                propertyPage ? (
                  <ShowProcessedImage
                    images={image}
                    attr={{
                      className: "img-fluid",
                      alt: description,
                    }}
                    transforms={imageConfig.property.searchResults.sizes}
                    className={"property-card_image"}
                  />
                ) : (
                  <GGFXImage
                    ImageSrc={image}
                    altText={`${description}`}
                    imagetransforms={processedImages}
                    renderer="srcSet"
                    imagename={imagename}
                    strapi_id={strapi_id}
                    className={"property-card_image"}
                  />
                )
              ) : (
                <img
                  src={NoImage}
                  alt={description}
                  className={"property-card_image"}
                />
              )}
            </Link>
          </div>
          <div className="content">
            <h3 className="address">
              <Link to={`${url}${slug}/${strapi_id}/`}>{address}</Link>
              <HeartIcon pid={id} userObjects={userObjects} />
            </h3>
            <p className="price">
              {price_range?.length>1?`${currencyFormat(minPriceRange, "£", false)} - ${currencyFormat(maxPriceRange, "£", false)}`:currencyFormat(price, "£", false)}{" "}
            </p>
              {bedroom_range||bedroom ? (
                <div className="item d-flex">
                  <img src={bedIcon} alt="bed" className="bed-icon" />
                  <span>
                    {bedroom_range?.length>1? `${minBed}-${maxBed}`:bedroom}{" "}
                    {bedText}
                  </span>
                </div>
              ) : null}

              <PropertyReadMore
                  content={description?.replace(/<br><br><br>/g,"<br><br>")}
                  height={180}
                  className={"property-desc-text"}
                  newHomes
              />  
             <div className="details-button-newhomes">
             <Link to={`${url}${slug}/${strapi_id}/`}>
                <button className="button button-green-outline">View Details</button>
              </Link>
              </div>   
          </div>
        </div>
      </div>
    </Animation>
  )
}

export default NewHomesCard
