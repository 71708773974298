import { Link } from "gatsby"
import React from "react"
import CTALink from "../elements/CTALink"

const StaticPopularSearch = ({ popularSearchData, popular_search }) => {
  const getCategory = popularSearchData?.find(
    data => data?.node?.title === popular_search?.title
  )
  return (
    <div className="popular-links">
      {getCategory?.node?.links.map((link, index) => (
        <div className={`column column-${index + 1}`}>
          {link.ctas.map(data => {
            return <CTALink cta={data} key={"menu" + index} />
          })}
        </div>
      ))}
    </div>
  )
}

export default StaticPopularSearch
