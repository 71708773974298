import clsx from "clsx"
import _ from "lodash"
import React from "react"
import { parseHTML } from "../../common/utils/utils"
import reviewsImg from "../../images/google reviews.svg"
import PlayVideoButton from "../PlayVideo/PlayVideoButton"
import Animation from "../elements/Animation"
import CTALink from "../elements/CTALink"
import GGFXImage from "../elements/GGFXImage"
import HalfContainer from "../elements/HalfContainer"
import "./BannerListing.scss"

const BannerListing = ({
  data,
  pageData,
  rating_avg,
  testimonial_count,
  loader,
}) => {
  if (!module || !pageData) return null

  const { strapi_id, imagetransforms, banner } = pageData

  let processedImages = imagetransforms?.image_Transforms
  processedImages ||= JSON.stringify({})

  return (
    <>
      <div className={clsx("banner-listing-wrap")}>
        <Animation className="image-section">
          <GGFXImage
            ImageSrc={banner.image}
            title={banner.banner_title?.data?.banner_title}
            altText={"banner-img"}
            imagetransforms={processedImages}
            renderer="srcSet"
            imagename={"page.image.tile_image_full"}
            strapi_id={strapi_id}
            className={"image"}
          />
          <PlayVideoButton videoUrl={banner.video_url} />
        </Animation>
        <div className="banner-listing-container">
          <HalfContainer paddingLeft>
            <Animation animateInType={"up"} className="content-section">
              <h1 className="title">
                {parseHTML(banner.banner_title?.data?.banner_title)}
              </h1>
              <div className="description">
                {parseHTML(banner.banner_content?.data?.banner_content)}
              </div>
              <div className="cta-section">
                {!_.isEmpty(banner.ctas) &&
                  banner.ctas.map((cta, index) => (
                    <CTALink
                      cta={cta}
                      className={"button button-white-outline"}
                    />
                  ))}
              </div>
              {banner.show_reviews && (
                <div className="reviews-section">
                  {/* {loader && <div className="loader-icon-loader" />} */}
                  <img
                    src={reviewsImg}
                    alt="reviews-img"
                    className="reviews-img"
                  />
                  <p className="review-text">
                    <span>{parseFloat(rating_avg).toFixed(1)}</span> from{" "}
                    {testimonial_count} reviews
                  </p>
                </div>
              )}
            </Animation>
          </HalfContainer>
        </div>
      </div>
    </>
  )
}

export default BannerListing
