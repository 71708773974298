import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { Container } from "react-bootstrap"
import FormFields from "../../../static/forms/register_form.json"
import { parseHTML } from "../../common/utils/utils"
import BlockForm from "../forms/block-form-layout"
import "./RegisterYourIntrest.scss"

const RegisterYourIntrest = ({ email }) => { 
  return (
    <div className="register-form-component">
      <Container>
        <div className="contact-enquiry-section">
          <div className="contact-left-section">
            <div className="general-form-section">
              <div className="content-section">
                <h4>Register Your Interest</h4> 
                <hr></hr>
              </div>
              <BlockForm
                fields={FormFields}
                formtagclassname="general-enquiry"
                to_email_id={email}
              />
            </div>
          </div> 
         
        </div>
      </Container>
    </div>
  )
}

export default RegisterYourIntrest
