import _ from "lodash"
import React from "react"
import { connectHits } from "react-instantsearch-dom"
import PropertyCard from "../PropertyCard/PropertyCard"
import NewHomesCard from "../NewHomesCard/NewHomesCard"


const InfiniteHits = ({ hits, showMap, userObjects, savedSearchParams,secondaryNewHome }) => {
  if (showMap || _.isEmpty(hits)) return null

  return (
    <div className={`search-results-list-wrapper ${secondaryNewHome?"secondary-new-homes":""}`}>
      {hits.map(hit => {
        const {
          display_address,
          title,
          images,
          department,
          price,
          slug,
          crm_id,
          search_type,
          bedroom,
          bathroom,
          floorarea_max,
          floorarea_type,
          reception,
          status,
          description,
          price_qualifier,
          price_range,
          bedroom_range
        } = hit

        return (
          <>
          {secondaryNewHome?
            <NewHomesCard
            address={display_address}
            title={title}
            description={description}
            department={department}
            image={images?.[0]}
            price={price}
            slug={slug}
            id={crm_id}
            search_type={search_type}
            status={status}
            bedroom={bedroom}
            bathroom={bathroom}
            floorarea_max={floorarea_max}
            floorarea_type={floorarea_type}
            reception={reception}
            strapi_id={hit.objectID}
            propertyPage
            userObjects={userObjects}
            price_qualifier={price_qualifier}
            savedSearchParams={savedSearchParams}
            price_range={price_range}
            bedroom_range={bedroom_range}
            />
          :
            <PropertyCard
            address={display_address}
            description={title}
            department={department}
            image={images?.[0]}
            price={price}
            slug={slug}
            id={crm_id}
            search_type={search_type}
            status={status}
            bedroom={bedroom}
            bathroom={bathroom}
            floorarea_max={floorarea_max}
            floorarea_type={floorarea_type}
            reception={reception}
            strapi_id={hit.objectID}
            propertyPage
            userObjects={userObjects}
            price_qualifier={price_qualifier}
            savedSearchParams={savedSearchParams}
          />
        }
          </>
        )
      })}
    </div>
  )
}

const CustomInfiniteHits = connectHits(InfiniteHits)

export default CustomInfiniteHits
