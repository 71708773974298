import React from "react"
import { Container } from "react-bootstrap"
import FormFields from "../../../static/forms/property_enquiry.json"
import GGFXImage from "../elements/GGFXImage"
import DefaultForm from "../forms/default-form-layout"
import "./BookViewing.scss"

const BookViewing = ({ data, locationState }) => {
  let propertyData = {}
  let pageUrl = ""
  if (typeof window !== "undefined") {
    propertyData = JSON.parse(sessionStorage.getItem("propertydata"))
    pageUrl = JSON.parse(sessionStorage.getItem("pageUrl"))
  }
  // console.log(pageUrl)
  const image =
    propertyData?.images?.strapi_json_value?.length > 0
      ? propertyData?.images?.strapi_json_value[0]
      : ""
  const imagename = "property.images.tileimg"

  const backToPage = () =>
    typeof window !== "undefined" && window.history.back()

  let processedImages = propertyData?.imagetransforms?.images_Transforms
  processedImages ||= JSON.stringify({})
  return (
    <div className="book-viewing-wrapper">
      <Container>
        <div className="back-page" onClick={backToPage}>
          <i className="icon white-arrow-up-right"></i>
          <span>Back to previous page</span>
        </div>
        <div className="book-viewing-module">
          <div className="viewing-form-wrapper">
            <div>
              <div className="propety-info">
                <div className="content-section">
                  <p className="heading">property enquiry</p>
                  <h2 className="address">{propertyData?.display_address}</h2>
                </div>
                {image && (
                  <div className="image-section">
                    <GGFXImage
                      ImageSrc={image}
                      altText={`${propertyData?.display_address}`}
                      imagetransforms={processedImages}
                      renderer="srcSet"
                      imagename={imagename}
                      strapi_id={propertyData?.strapi_id}
                      className={"viewing-form-image"}
                    />
                  </div>
                )}
              </div>
              <div className="horizontal-line"></div>
              <DefaultForm
                fields={FormFields}
                formtagclassname="book-viewing"
                prop_url={pageUrl}
                prop_address={propertyData?.display_address}
                to_email_id={
                  propertyData?.search_type === "lettings"
                    ? "lettings@christopherhodgson.co.uk"
                    : propertyData?.search_type === "sales"
                    ? "sales@christopherhodgson.co.uk"
                    : "sales@christopherhodgson.co.uk"
                }
              />
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default BookViewing
