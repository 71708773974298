import { Link } from "gatsby"
import _ from "lodash"
import React, { useState } from "react"
import { Accordion, Offcanvas } from "react-bootstrap"
import useNavMenus from "../../hooks/dataHooks/useNavMenus"
import logo from "../../images/ch-logo-dark.svg"
import iconClose from "../../images/icons/x-mark.svg"
import BurgerIcon from "../Header/BurgerIcon"
import CTALink from "../elements/CTALink"
import "./BurgerMenu.scss"

const BurgerMenu = ({ isTransparent }) => {
  const [showModal, setShowModal] = useState(false)

  const menus = useNavMenus()

  return (
    <>
      <BurgerIcon white={isTransparent} onClick={() => setShowModal(true)} />
      <Offcanvas
        className="burger-menu-wrap"
        placement="end"
        show={showModal}
        onHide={() => setShowModal(false)}
      >
        <Offcanvas.Header className="burger-header">
          <Link to={"/"}>
            <img src={logo} alt="logo" className="brand-logo" />
          </Link>
          <img
            src={iconClose}
            alt="close icon"
            onClick={() => setShowModal(false)}
            className="close-icon"
          />
        </Offcanvas.Header>
        <Offcanvas.Body className="burger-menu-section">
          <div className="burger-body">
            {menus.map((menu, index) => {
              const hasCTAs = !_.isEmpty(menu.ctas)

              return (
                <>
                  <Accordion>
                    <Accordion.Item eventKey={menu.title}>
                      <Accordion.Header
                        as={"div"}
                        className={hasCTAs ? "has-ctas" : "no-ctas"}
                      >
                        {menu.main_cta.custom_link === "$find-a-property" ? <a class="main-menu" href="javascript:void(0);"><span>Find a Property</span></a>
                        :
                        <CTALink
                          cta={menu.main_cta}
                          className={"main-menu"}
                          key={"menu" + index}
                        />}
                      </Accordion.Header>
                      {hasCTAs && (
                        <Accordion.Body>
                          {menu.ctas.map(cta => (
                            <CTALink cta={cta} className={"sub-menu"} />
                          ))}
                        </Accordion.Body>
                      )}
                    </Accordion.Item>
                  </Accordion>
                </>
              )
            })}
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  )
}

export default BurgerMenu
