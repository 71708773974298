import clsx from "clsx"
import { graphql } from "gatsby"
import React from "react"
import Layout from "../components/Layout/Layout"
import { aboutURL, teamsURL } from "../common/utils/urls"
import TeamDetails from "../components/TeamDetails/TeamDetails"
import OtherTeamSlider from "../components/OtherTeamSlider/OtherTeamSlider"
import SEO from "../components/SEO/seo"

const TeamDetailTemplate = ({ data }) => {
  if (!data?.strapiTeam) return null
  const team = data.strapiTeam
  const breadcrumbURLs = [
    {
      url: aboutURL,
      label: "About",
    },
    {
      url: teamsURL,
      label: "Meet the Team",
    },
    {
      url: `${teamsURL}${team.slug}/`,
      label: team.name,
    },
  ]
  const metaTitle = `${team.name} | ${team.position}`
  const metaDescription = `Get to know about ${team.name} here. Contact one of our estate agents for assistance in finding the right property for you.`
 
  return (
    <div className={clsx("team-template")}>
      <Layout popular_search={{title: 'category1'}} breadcrumbURLs={breadcrumbURLs} footer={"dark"}>
      <SEO title={metaTitle} description={metaDescription} />
        <TeamDetails team={team} />
        <OtherTeamSlider teamID={team.strapi_id} />
      </Layout>
    </div>
  )
}

export default TeamDetailTemplate

export const query = graphql`
  query ($page_id: String) {
    strapiTeam(id: { eq: $page_id }) {
      ...TeamFragment
    }
  }
`
