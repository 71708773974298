import React from "react"
import "./AreaGuideListing.scss"
import { Container } from "react-bootstrap"
import { getMenuLink } from "../../common/utils/utils"
import { parseHTML } from "../../common/utils/utils"
import { Link } from "gatsby"
import PlayVideoButton from "../PlayVideo/PlayVideoButton"
import { areaGuidesURL } from "../../common/utils/urls"
import Animation from "../elements/Animation"
import GGFXImage from "../elements/GGFXImage"
const AreaGuideListing = ({ pageData, areaGuideData }) => {
  return (
    <div className="area-guides-wrapper">
      <Container>
        <div className="area-guides-component section-p-80">
          <Animation animateInType={"up"} className="area-guides-section">
            <h1 className="title">
              {parseHTML(pageData?.banner?.banner_title?.data?.banner_title)}
            </h1>
            <div className="description">
              {parseHTML(
                pageData?.banner?.banner_content?.data?.banner_content
              )}
            </div>
            {areaGuideData?.length > 0 && (
              <div className="area-guides-list">
                {areaGuideData.map((item, i) => {
                  // const link = getMenuLink(service?.menu)
                  // let processedImages = JSON.stringify({})
                  // if (data?.node?.imagetransforms?.tile_image_Transforms) {
                  //   processedImages = data?.node.imagetransforms.tile_image_Transforms
                  // }
                  // var imagename = "area-guide.tile_image.area_image"
                  let processedImages =
                    item?.node?.imagetransforms?.tile_img_Transforms
                  processedImages ||= JSON.stringify({})
                  return (
                    <Animation
                      animateInType={"up"}
                      key={"Areaguide" + i}
                      delay={i * 0.2}
                      className="items"
                    >
                      <div className="image-section">
                        <Link to={areaGuidesURL + item?.node?.slug + "/"}>
                          <GGFXImage
                            ImageSrc={item?.node?.tile_img}
                            title={item?.node?.name}
                            altText={item?.node?.name}
                            imagetransforms={processedImages}
                            renderer="srcSet"
                            imagename={"area-guide.tile_img.card_image"}
                            strapi_id={item?.node?.strapi_id}
                            className="area-tile-image"
                          />
                        </Link>
                        {item?.node?.video_url && (
                          <PlayVideoButton videoUrl={item?.node?.video_url} />
                        )}
                      </div>
                      <Link
                        className="label-item"
                        to={areaGuidesURL + item?.node?.slug + "/"}
                      >
                        {item?.node?.name}
                      </Link>
                    </Animation>
                  )
                })}
              </div>
            )}
          </Animation>
        </div>
      </Container>
    </div>
  )
}

export default AreaGuideListing
