import { getUser } from "@starberry/myaccount-website-utils"
import dateFormat from "dateformat"
import { Link } from "gatsby"
import $ from "jquery/dist/jquery.min.js"
import _ from "lodash"
import moment from "moment"
import * as qs from "query-string"
import React, { useEffect, useState } from "react"
import { Button, Form, Modal, Tab, Tabs } from "react-bootstrap"
import Calendar from "react-multi-date-picker"
import DatePanel from "react-multi-date-picker/plugins/date_panel"
import TimePicker from "react-multi-date-picker/plugins/time_picker"
import { PageLinks } from "../../common/site/page-static-links"
import { currencyFormat } from "../../utils/common/utils"
import "../forms/styles/_index.scss"
import { postFormData } from "./api/Api"
import ButtonField from "./elements/button"
import CheckboxField from "./elements/checkbox"
import FileField from "./elements/file"
import HtmlBox from "./elements/html"
import InputField from "./elements/input"
import PostcodeField from "./elements/postcode"
import RadioField from "./elements/radio"
import ReCaptchaBox from "./elements/recaptcha"
import SelectField from "./elements/select"
import TextAreaField from "./elements/textarea"
// import ContentModule from "../../modules/content-render";
import "./instant.scss"
// import LoaderImage from "../../images/loading.gif"
import { parseHTML } from "../../common/utils/utils"
import useDeviceMedia from "../../hooks/useDeviceMedia"

function FormComponent(props) {
  const userData = getUser()
  const [validated, setValidated] = useState(false)

  const [startDate, setStartDate] = useState()
  const [startTime, setStartTime] = useState()
  const [showerror, setShowerror] = useState(false)
  const [showthankyou, setThankyou] = useState(false)
  const [formvalues, setFormvalues] = useState("")
  const [token, setToken] = useState("")
  const [file, setFile] = useState("") // storing the uploaded file
  // storing the recived file from backend
  const [] = useState({ name: "", path: "" })
  const [] = useState(0) // progess bar
  const myRef = React.createRef()

  const [show, setShow] = useState(false)

  const [radioval, setRadioval] = useState("To Sell")
  const [selectedOption, setSelectedOption] = useState("Sales")

  const [loader, setLoader] = useState(false)

  //////////
  const [postcode, setPostcode] = useState("")
  const [postCodeAddress, setPostCodeAddress] = useState(true)
  const [address, setAddress] = useState("")
  const [postCodeError, setPostCodeError] = useState(false)
  const [showerrormsg, setShowerrorMsg] = useState("")

  const closeModal = () => {
    setShow(false)
  }

  const recaptchaRef = React.createRef()
  const fields = props.fields

  const getRadio = event => {
    event.target.value = event.target.value.trimStart()
    setRadioval(event.currentTarget.value)
  }

  const selectHandelChange = (name, value) => {
    setPostcode(value)
    setPostCodeAddress(true)
  }

  const handleCheckboxChange = option => {
    if (selectedOption === option) {
      // setSelectedOption("")
    } else {
      setSelectedOption(option)
    }
  }

  const postcodeChange = suggestion => {
    setPostcode(suggestion.postcode)
    props.setGetPostCode(suggestion.postcode)
    var selected_address = suggestion.line_1 ? suggestion.line_1 + ", " : ""
    selected_address += suggestion.line_2 ? suggestion.line_2 + ", " : ""
    selected_address += suggestion.line_3 ? suggestion.line_3 + ", " : ""
    selected_address += suggestion.postcode ? suggestion.postcode + ", " : ""

    setAddress(`${selected_address}`)
    props.setGetAddress(`${selected_address}`)

    setPostCodeAddress(false)
  }

  const handlechange = event => {
    // remove initial empty spaces
    event.target.value = event.target.value.trimStart()
    if (event.target.name === "first_name") {
      props.setGetFirstName(event.target.value)
    }
    if (event.target.name === "last_name") {
      props.setGetLastName(event.target.value)
    }
    if (event.target.name === "telephone") {
      props.setGetPhone(event.target.value)
    }
    if (event.target.name === "email") {
      props.setGetEmail(event.target.value)
    }
  }

  const handleselectchange = event => {
    event.value = event?.value?.trimStart()
    if (event.target.name === "property_type") {
      props.setPropertyType(event.target.value)
    }
    if (event.target.name === "bedroom_count") {
      props.setGetPropertyBed(event.target.value)
    }
  }

  const handleAttachment = event => {
    const fileItem = event.target.files[0] // accesing file
    var d = document.getElementById("custom-file")
    //alert(d.value);
    $(".form-control-browsefile .custom-file-label").html(
      d.value.replace(/C:\\fakepath\\/, "")
    )
    setFile(fileItem) // storing file

    $(".form-control-browsefile").addClass("validated")
  }

  useEffect(() => {
    if (token !== "") {
      setLoader(true)
      const processFromData = async () => {
        formvalues["g-recaptcha-response"] = token
        const page_url =
          typeof window !== "undefined" ? window.location.href : ""

        let formData = new FormData()
        formvalues["first_name"] = formvalues.first_name
        formvalues["last_name"] = formvalues.last_name
        formvalues["email"] = formvalues.email
        formvalues["telephone"] = formvalues.telephone
        formvalues["email_subject_user"] = props?.email_subject_user
          ? props?.email_subject_user
          : fields[0].email_subject_user
        formvalues["email_subject_admin"] = props?.email_subject_admin
          ? props?.email_subject_admin
          : fields[0].email_subject_admin

        if (radioval) {
          formvalues["client_property"] = radioval
        }
        if (selectedOption) {
          formvalues["property_enquiry_type"] = selectedOption === "Both" ? "Sales and Lettings" : selectedOption
          if (selectedOption === "Lettings") {
            formvalues["to_email_id"] = "lettings@christopherhodgson.co.uk"
          } else if (selectedOption === "Sales"){
            formvalues["to_email_id"] = "sales@christopherhodgson.co.uk"
          }
          else {
            formvalues["to_email_id"] = "info@christopherhodgson.co.uk"
          }
        }
        formvalues["postcode"] = postcode
        formvalues["address"] = address

        if (address) {
          formvalues["postcode_address"] = address
        }
        formvalues["debug"] = true
        formvalues["form_name"] = "leadpro-valuation"

        formData.append("data", JSON.stringify(formvalues))
        setShowerror(false)
        setThankyou(true)

        postFormData(formData).then(async apiRes => {
          window.grecaptcha.reset()
          if (apiRes?.success === true) {
            // tracking event
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({
              event: "formSubmit",
              formType: "form-" + fields[0].event_tracking,
              formId: "form-" + fields[0].event_tracking,
              formName: fields[0].formname + " " + selectedOption,
              formLabel: fields[0].formname + " " + selectedOption,
            })

            setTimeout(() => {
              setThankyou(false)
            }, 6000)

            const mail_form_url =
              process.env.GATSBY_CLOUD_URL + "/api/functions"

            if (apiRes?.data?.leadpro_response?.status) {
              // await Mail_Function(formvalues);
              await window.fetch(mail_form_url, {
                method: `POST`,
                mode: "no-cors",
                headers: {
                  "Access-Control-Allow-Origin": "*",
                  "Content-Type": "application/x-www-form-urlencoded",
                },
                body: qs.stringify(formvalues),
              })
              // sessionStorage.setItem(
              //   "valuationResult",
              //   JSON.stringify(apiRes?.data?.leadpro_response?.data)
              // )
              setLoader(false)
              props?.setLeadProResult(apiRes?.data?.leadpro_response?.data)
              props?.setStep(2)
              props?.setShowContent(false)
              window.scrollTo(0, 0)
              // navigate("/valuation-result")
            }
          } else {
            setLoader(false)
            setShowerror(false)
            setThankyou(false)
            setShowerrorMsg("Form could not submitted!")
            myRef?.current?.scrollIntoView({ behavior: "smooth", block: "end" })
          }
          setToken("")
        })
        formvalues["g-recaptcha-response"] = token
      }
      processFromData()
    }
  }, [token])

  const handleonVerify = token => {
    // console.log("captcha verified")
    setToken(token)
    // setToken("")
  }

  const handleSubmit = event => {
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
      setShowerror(true)
      setValidated(true)
      setThankyou(false)
      // setShowerrorMsg(fields[0].error_text)

      myRef?.current?.scrollIntoView({ behavior: "smooth", block: "end" })
    } else {
      event.preventDefault()
      if (postCodeError) {
        setShowerror(false)
        setThankyou(false)
        setShowerrorMsg("Enter a valid postcode.")

        $(".postcode-field-wrapper .form-control").addClass("is-invalid")

        myRef?.current?.scrollIntoView({ behavior: "smooth", block: "end" })
      } else if (postCodeAddress) {
        setShowerror(false)
        setThankyou(false)
        setShowerrorMsg("Select a valid postcode address.")

        $(".postcode-field-wrapper .form-control").addClass("is-invalid")

        myRef?.current?.scrollIntoView({ behavior: "smooth", block: "end" })
      } else {
        const formvalues = event.target
        setShowerrorMsg("")

        const json = {}
        Object.keys(formvalues).map(key => {
          // if (formvalues[key].type === "checkbox") {
          //   if (formvalues[key].checked === true) {
          //     json[formvalues[key].name] = "yes"
          //   } else {
          //     setShowerrorMsg("Checkbox is empty")
          //   }
          // } else 
          if (formvalues[key].type === "radio") {
            if (formvalues[key].checked === true) {
              json[formvalues[key].name] = formvalues[key].value
            }
          } else {
            json[formvalues[key].name] = formvalues[key].value
          }
        })

        var date_time =
          dateFormat(startDate, "mmm dd, yyyy") +
          " " +
          dateFormat(startTime, "hh:MM TT")
        json["name"] = json.first_name + " " + json.last_name
        json["email_temp_user"] = fields[0].email_temp_user
        json["email_temp_admin"] = fields[0].email_temp_admin
        json["email_subject_user"] = fields[0].email_subject_user
        json["email_subject_admin"] = fields[0].email_subject_admin
        json["postcode"] = postcode
        json["property_address"] = json.address

        json["form_name"] = fields[0].formname
        json["g-recaptcha-response"] = token
        json["date_time"] = date_time

        setFormvalues(json)
        //setToken("test")

        recaptchaRef.current.execute()

        setValidated(false)

        setStartDate()
        setStartTime()
      }
    }
  }

  const url = typeof window !== "undefined" ? window.location.href : ""
  return (
    <div className={`${props.classname} form-component-wrapper`}>
      <div ref={myRef} />
      {loader && (
        <div className="loader">
          <div className="loader-icon-slider" />
        </div>
      )}
      <Form
        className={`form stb-form ${props.formtagclassname} ${
          loader ? "make-blur" : ""
        }`}
        id="contact-form"
        name={fields[0].formname}
        // action="/thank-you/"
        method="post"
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
      >
        {showerror && (
          <div className="alert alert-danger">{fields[0].error_text}</div>
        )}
        {showerrormsg && (
          <div className="alert alert-danger">{showerrormsg}</div>
        )}
        {/* {showthankyou && !props.thankyouMessage && (
          <div className="alert alert-success">{fields[0].success_text}</div>
        )} */}
        <input type="hidden" name="form_name" value={"leadpro-valuation"} />
        <input type="hidden" name="lead_type" value={"valuation"} />
        <input type="hidden" name="search_type" value={"vendor"} />
        <input type="hidden" name="to_email_id" value={props.to_email_id} />
        <input type="hidden" name="bot-field" />

        <input type="hidden" name="form_page" value={url} />
        <div className="step-block">
          {fields.map((field, index) => {
            if ("input" === field.element) {
              return (
                <InputField
                  name={field.name}
                  grpmd={field.grpmd}
                  ref={field.ref}
                  type={field.type}
                  startDate={field.type === "date_time" ? startDate : ""}
                  setStartDate={field.type === "date_time" ? setStartDate : ""}
                  startTime={field.type === "timePicker" ? startTime : ""}
                  setStartTime={field.type === "timePicker" ? setStartTime : ""}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  label={field.label}
                  labelClass={field.labelClass}
                  required={field.required}
                  key={`${field.element}~${index}`}
                  pattern={field.patternchk}
                  handlechange={handlechange}
                  defaultValue={
                    field.name === "first_name"
                      ? userData?.name
                      : field.name === "last_name"
                      ? userData?.surname
                      : field.name === "email"
                      ? userData?.email
                      : field.name === "telephone"
                      ? userData?.mobile
                      : field.defaultValue
                  }
                  className={field.className}
                  step={field.step}
                  id={field.id}
                />
              )
            }
            if ("postcode" === field.element) {
              return (
                <PostcodeField
                  name={field.name}
                  // divclass={field.divclass}
                  grpmd={field.grpmd}
                  label={field.label}
                  ref={field.ref}
                  type={field.type}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  labelClass={field.labelClass}
                  required={field.required}
                  key={`${field.element}~${index}`}
                  pattern={field.patternchk}
                  handlechange={handlechange}
                  errorMsg={field.errorMsg}
                  selectHandelChange={selectHandelChange}
                  postcodeChange={postcodeChange}
                  setPostCodeError={setPostCodeError}
                  id={field.id}
                />
              )
            }

            if ("select" === field.element) {
              return (
                <SelectField
                  name={field.name}
                  grpmd={field.grpmd}
                  label={field.label}
                  ref={field.ref}
                  required={field.required}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  values={field.values}
                  key={`${field.element}~${index}`}
                  handlechange={handleselectchange}
                  componentprops={props}
                  className={field.className}
                />
              )
            }
            if ("textarea" === field.element) {
              return (
                <TextAreaField
                  name={field.name}
                  grpmd={field.grpmd}
                  ref={field.ref}
                  rows={field.row}
                  fieldClass={field.class}
                  labelClass={field.labelClass}
                  placeholder={field.placeholder}
                  label={field.label}
                  required={field.required}
                  key={`${field.element}~${index}`}
                  handlechange={handlechange}
                  className={field.className}
                />
              )
            }
            if ("checkbox" === field.element && "p_type" == field.name) {
              return (
                <div className="valuation-custom-checkbox theme-form-list full-width">
                  {field?.values.map(option => (
                    <label key={option}>
                      <input
                        type="checkbox"
                        checked={selectedOption === option}
                        onChange={() => handleCheckboxChange(option)}
                      />
                      <div className="check-content"> {option}</div>
                    </label>
                  ))}
                </div>
              )
            }
            if ("checkbox" === field.element) {
              return (
                <CheckboxField
                  name={field.name}
                  ref={field.ref}
                  value={field.value}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.name}~${index}`}
                  handlechange={handlechange}
                  className={field.className}
                />
              )
            }
            if ("file" === field.element) {
              return (
                <FileField
                  name={field.name}
                  grpmd={field.grpmd}
                  ref={field.ref}
                  type={field.type}
                  fieldClass={field.fieldClass}
                  label={field.label}
                  labelClass={field.labelClass}
                  placeholder={field.placeholder}
                  key={`${field.element}~${index}`}
                  accept={field.accept}
                  handlechange={handleAttachment}
                  required={field.required}
                  className={field.className}
                  file={file}
                />
              )
            }
            if ("radio" === field.element) {
              return (
                <RadioField
                  label={field.label}
                  name={field.name}
                  ref={field.ref}
                  values={field.values}
                  fieldClass={field.class}
                  checked={field.checked}
                  selected={radioval}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.name}~${index}`}
                  handlechange={getRadio}
                  lastchild={field.lastchild}
                  className={field.className}
                />
              )
            }

            if ("html" === field.element) {
              return (
                <HtmlBox
                  text={field.text}
                  fieldClass={field.class}
                  key={`${field.element}~${index}`}
                  className={field.className}
                  id={field.id}
                />
              )
            }
            if ("captcha" === field.element) {
              return (
                <ReCaptchaBox
                  fieldClass={field.class}
                  captRef={recaptchaRef}
                  key={`${field.element}~${index}`}
                  handleonVerify={handleonVerify}
                  className={field.className}
                />
              )
            }
            if ("button" === field.element) {
              return (
                <ButtonField
                  name={field.name}
                  fieldClass={field.class}
                  step={field.step}
                  formclass={field.formclass}
                  type={field.type}
                  value={field.value}
                  key={`${field.element}~${index}`}
                  className={field.className}
                />
              )
            }
          })}
        </div>
      </Form>
    </div>
  )
}

const KEYS = {
  sell: "Sell",
  let: "Let",
}

const InstantValuationForm = props => {
  const [leadProResult, setLeadProResult] = useState(null)
  const [step, setStep] = useState(1)
  const [show, setShow] = useState(false)
  const [thankyou, setThanksyou] = useState(false)
  const [values, setValues] = useState([])
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const [type, setType] = useState("")

  const { isMobile } = useDeviceMedia()

  //
  // const [getName, setGetName]=useState("");
  const [getPostCode, setGetPostCode] = useState("")
  const [getAddress, setGetAddress] = useState("")
  const [getFirstName, setGetFirstName] = useState("")
  const [getLastName, setGetLastName] = useState("")
  const [getEmail, setGetEmail] = useState("")
  const [getPhone, setGetPhone] = useState("")
  const [getPropertyBed, setGetPropertyBed] = useState("")

  //
  const [propertyType, setPropertyType] = useState(KEYS.sell)

  const [key, setKey] = useState(propertyType)

  useEffect(() => {
    setKey(propertyType)
  }, [propertyType])

  const appoinmentSubmit = () => {
    var dates = []
    if (values) {
      _.map(values, date => {
        dates.push(moment(date?.toDate?.().toString()).utc().format())
      })
    }
    //console.log(dates)
    let formData = new FormData()
    var formValues = {
      form_name: "leadpro-valuation",
      form_type: "valuation",
      form_label: "leadpro-valuation",
      lead_type: "valuation",
      postcode: getPostCode,
      address: getAddress,
      first_name: getFirstName,
      last_name: getLastName,
      name: getFirstName + getLastName,
      //   form_name: "leadpro-valuation",
      email: getEmail,
      // "office_id":"",
      telephone: getPhone,
      bedroom_count: getPropertyBed ? getPropertyBed : "1",
      //   property_type: propertyType === "Sell" ? "sale" : "let",
      //   type: propertyType === "Sell" ? "sale" : "let",
      search_type: "vendor",
      form_name: "leadpro-valuation",
      debug: true,
      appointment_availability: {
        hours: dates,
      },
    }
    formData.append("data", JSON.stringify(formValues))

    //console.log("formData ==>", formData);

    postFormData(formData).then(async apiRes => {
      handleClose()
      setThanksyou(true)
      setStep(3)
      window.scrollTo(0, 0)

      // tracking event
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: "formSubmit",
        formType: "form-face-to-face-valuation",
        formId: "form-face-to-face-valuation",
        formName: "Face to Face Valuation",
        formLabel: "Face to Face Valuation - " + formValues.proprty_type,
      })
    })
  }

  return (
    <div className="instant-valuation-form-wrapper">
      {step === 1 ? (
        <div className="valuation-section">
          <div className="text-content">
            <h1>{props?.PageData?.title}</h1>
            <div className="contact-text">
              {
                props?.PageData?.content?.data?.content &&
                  parseHTML(props?.PageData?.content?.data?.content)
                //   <ContentModule Content={props?.PageData?.content?.data?.content} />
              }
            </div>
          </div>
          <FormComponent
            leadProResult={leadProResult}
            setLeadProResult={setLeadProResult}
            step={step}
            setStep={setStep}
            fields={props.fields}
            classname={props.classname}
            to_email_id={props.to_email_id}
            property_title={props.title}
            formtagclassname={props.formtagclassname}
            prop_url={props?.prop_url}
            prop_address={props?.prop_address}
            prop_img_url={props?.prop_img_url}
            email_template_type={props?.email_template_type}
            thankyouMessage={props?.thankyouMessage}
            email_subject_user={props?.email_subject_user}
            email_subject_admin={props?.email_subject_admin}
            plan={props?.plan}
            setPropertyType={setPropertyType}
            setGetFirstName={setGetFirstName}
            setGetPhone={setGetPhone}
            setGetPostCode={setGetPostCode}
            setGetAddress={setGetAddress}
            setGetEmail={setGetEmail}
            setGetLastName={setGetLastName}
            setGetPropertyBed={setGetPropertyBed}
            setShowContent={props.setShowContent}
          />
        </div>
      ) : step === 2 ? (
        <div className="valuation-section">
          {leadProResult?.minimum_sale_estimation || leadProResult?.average_sale_estimation || leadProResult?.minimum_sale_estimation ||
          leadProResult?.minimum_rent_estimation || leadProResult?.average_rent_estimation || leadProResult?.minimum_rent_estimation ?
          <div className="text-content">
            <h1 className="dark-title">Your Instant Valuation Report</h1>
            <p>{leadProResult?.address}</p>
          </div> : 
          <div className="text-content">
          {/* <h1 className="dark-title">Your Instant Valuation Report</h1> */}
          <p className="no-bottom">Unfortunately we can't find valuation data for this address. Please choose a different address or book a home valuation by clicking below.</p>
          <div className="d-md-flex d-block btn-wrap">
          <Link className="button button-green" to={PageLinks.homevaluation}>
          book a home valuation
                </Link>
                <Link className="button button-outline" to={process.env.GATSBY_SITE_URL+PageLinks.instvaluation}>
          Back to Instant Valuation
                </Link></div>
        </div>
          }
          {leadProResult?.minimum_sale_estimation || leadProResult?.average_sale_estimation || leadProResult?.minimum_sale_estimation ||
          leadProResult?.minimum_rent_estimation || leadProResult?.average_rent_estimation || leadProResult?.minimum_rent_estimation ? (
            <Tabs
              activeKey={key}
              onSelect={k => setKey(k)}
              // defaultActiveKey={"vendor"}
              id="uncontrolled-tab-example"
            >
              <Tab eventKey={KEYS.sell} title="Sales">
                {isMobile ? (
                  <div className="steps-container">
                    <div class="step step-active">
                      <div>
                        <div class="circle"></div>
                      </div>
                      <div>
                        <div class="title">
                          <span className="value">Min Value</span>
                        </div>
                        <div class="caption">
                          {leadProResult?.minimum_sale_estimation && (
                            <span className="total">
                              {currencyFormat(
                                leadProResult?.minimum_sale_estimation
                              )}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div class="step step-2">
                      <div>
                        <div class="circle"></div>
                      </div>
                      <div>
                        <div class="title">
                          <span className="value">Est Value</span>
                        </div>
                        <div class="caption">
                          {leadProResult?.average_sale_estimation && (
                            <span className="total">
                              {currencyFormat(
                                leadProResult?.average_sale_estimation
                              )}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div class="step">
                      <div>
                        <div class="circle"></div>
                      </div>
                      <div>
                        <div class="title">
                          <span className="value">Max Value</span>
                        </div>
                        <div class="caption">
                          {leadProResult?.maximum_sale_estimation && (
                            <span className="total">
                              {currencyFormat(
                                leadProResult?.maximum_sale_estimation
                              )}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="valuation-line">
                      <span className="circle"></span>
                      <span className="circle"></span>
                      <span className="circle"></span>
                    </div>
                    <div className="valuation-info-wrap">
                      <div className="value-info">
                        <span className="value">Min Value</span>
                        {leadProResult?.minimum_sale_estimation && (
                          <span className="total">
                            {currencyFormat(
                              leadProResult?.minimum_sale_estimation
                            )}
                          </span>
                        )}
                      </div>
                      <div className="value-info">
                        <span className="value">Est Value</span>
                        {leadProResult?.average_sale_estimation && (
                          <strong className="total">
                            {currencyFormat(
                              leadProResult?.average_sale_estimation
                            )}
                          </strong>
                        )}
                      </div>
                      <div className="value-info">
                        <span className="value">Max Value</span>
                        {leadProResult?.maximum_sale_estimation && (
                          <span className="total">
                            {currencyFormat(
                              leadProResult?.maximum_sale_estimation
                            )}
                          </span>
                        )}
                      </div>
                    </div>
                  </>
                )}

                <Button className="button button-green" onClick={handleShow}>
                  book a home valuation
                </Button>
                <p className="note">
                  <b>Please note, this valuation is an estimate</b>. This online
                  valuation was generated automatically using Land Registry data
                  and property listings data. It doesn't take into account any
                  developments made to your property. For a more accurate
                  valuation, please book in a valuation.
                </p>
              </Tab>

              <Tab eventKey={KEYS.let} title="Lettings">
              {isMobile ? (
                  <div className="steps-container">
                    <div class="step step-active">
                      <div>
                        <div class="circle"></div>
                      </div>
                      <div>
                        <div class="title">
                          <span className="value">Min Value</span>
                        </div>
                        <div class="caption">
                          {leadProResult?.minimum_rent_estimation && (
                            <span className="total">
                              {currencyFormat(
                                leadProResult?.minimum_rent_estimation
                              )}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div class="step step-2">
                      <div>
                        <div class="circle"></div>
                      </div>
                      <div>
                        <div class="title">
                          <span className="value">Est Value</span>
                        </div>
                        <div class="caption">
                          {leadProResult?.average_rent_estimation && (
                            <span className="total">
                              {currencyFormat(
                                leadProResult?.average_rent_estimation
                              )}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div class="step">
                      <div>
                        <div class="circle"></div>
                      </div>
                      <div>
                        <div class="title">
                          <span className="value">Max Value</span>
                        </div>
                        <div class="caption">
                          {leadProResult?.maximum_rent_estimation && (
                            <span className="total">
                              {currencyFormat(
                                leadProResult?.maximum_rent_estimation
                              )}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="valuation-line">
                      <span className="circle"></span>
                      <span className="circle"></span>
                      <span className="circle"></span>
                    </div>
                    <div className="valuation-info-wrap">
                      <div className="value-info">
                        <span className="value">Min Value</span>
                        {leadProResult?.minimum_rent_estimation && (
                          <span className="total">
                            {currencyFormat(
                              leadProResult?.minimum_rent_estimation
                            )}
                          </span>
                        )}
                      </div>
                      <div className="value-info">
                        <span className="value">Est Value</span>
                        {leadProResult?.average_rent_estimation && (
                          <strong className="total">
                            {currencyFormat(
                              leadProResult?.average_rent_estimation
                            )}
                          </strong>
                        )}
                      </div>
                      <div className="value-info">
                        <span className="value">Max Value</span>
                        {leadProResult?.maximum_rent_estimation && (
                          <span className="total">
                            {currencyFormat(
                              leadProResult?.maximum_rent_estimation
                            )}
                          </span>
                        )}
                      </div>
                    </div>
                  </>
                )}

                <Button
                  className="button button-green"
                  onClick={handleShow}
                  type="submit"
                >
                  book a valuation appointment
                </Button>
                <p className="note">
                  <b>Please note, this valuation is an estimate</b>. This online
                  valuation was generated automatically using Land Registry data
                  and property listings data. It doesn't take into account any
                  developments made to your property. For a more accurate
                  valuation, please book in a valuation.
                </p>
              </Tab>
            </Tabs>
          ) : null}
          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            // centered
            dialogClassName="modal-appointment-form"
          >
            <Modal.Header closeButton>
              <Modal.Title>Request a valuation appointment</Modal.Title>
            </Modal.Header>
            <Form action="javascript:;" onSubmit={appoinmentSubmit}>
              <Modal.Body>
                <h5>
                  Select as many times as you would like and we will contact you
                  shortly to confirm your appointment.
                </h5>
                <Calendar
                  // ref={datePickerRef}
                  value={values}
                  onChange={setValues}
                  minDate={new Date()}
                  format="DD/MMM/YYYY hh:mm A"
                  multiple
                  inputClass="form-control"
                  placeholder="Select appointment date"
                  plugins={[
                    <TimePicker
                      position="bottom"
                      hideSeconds
                      className="valuation-time-picker"
                    />,
                    <DatePanel markFocused />,
                  ]}
                  required={true}
                  // onClose={() => false}
                />
              </Modal.Body>
              <Modal.Footer>
                <Button className="button button-green" type="submit">
                  Submit
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
        </div>
      ) : step === 3 ? (
        <div className="valuation-section">
          <div className="valuation-info">
            <div className="thankyou">
              <h2> Thank you</h2>
              <p>
                One of our property consultants will contact you shortly to
                confirm your appointment. In the meantime, if you are looking
                for your next home, you can browse through our selection of
                exquisite properties.
              </p>
              <Link
                to={`/properties/for-sale/in-kentish-town/`}
                className={"btn button button-filled"}
              >
                Find a Property
              </Link>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default InstantValuationForm
