import React, { useState, useEffect } from "react"
import { Container, Tab, Tabs } from "react-bootstrap"
import _ from "lodash"
import PropertyCard from "../PropertyCard/PropertyCard"
import CustomSlider from "../CustomSlider/CustomSlider"
import "./SimilarProperties.scss"
import { ApiRequest } from "../../common/utils/api_request_utils"
import { parseHTML } from "../../common/utils/utils"
const KEYS = {
  sale: "for-sale",
  rent: "to-rent",
  newHomes: "new-homes",
}

const SimilarProperties = props => {
  const [propItems, setPropItems] = useState([])
  const [key, setKey] = useState(KEYS.sale)

  useEffect(() => {
    if (props?.prop_id) {
      ApiRequest(
        {
          method: "GET",
          url: `${process.env.GATSBY_STRAPI_SRC}/api/stb-lists/item/property-details?pid=${props?.prop_id}`,
        },
        result => {
          if (result.length > 0) {
            setPropItems(result)
          }
        }
      )
    }
  }, [props])

  const SliderTab = ({ items }) => {
    if (_.isEmpty(items)) return null

    return (
      <CustomSlider className="similar-slider-wrap">
        {items.map(property => {
          const parseImage = property?.images && JSON.parse(property.images)
          const imageUrl = parseImage?.length > 0 && parseImage[0]
          return (
            <PropertyCard
              address={property.display_address}
              description={property.title}
              image={imageUrl}
              price={property.price}
              slug={property.slug}
              id={property.crm_id}
              strapi_id={property.id}
              search_type={property.search_type}
              status={property.status}
              bedroom={property?.bedroom}
              department={property?.department}
              bathroom={property?.bathroom}
              floorarea_max={property?.floorarea_max}
              floorarea_type={property?.floorarea_type}
              reception={property?.reception}
              imagetransforms={property.imagetransforms}
              price_qualifier={property?.price_qualifier}
            />
          )
        })}
      </CustomSlider>
    )
  }

  return propItems?.length > 0 ? (
    <section className="similar-properties-section">
      <Container className="similar-properties-container">
        <h2 className="title">{parseHTML(props?.title)}</h2>

        <Tabs
          activeKey={key}
          onSelect={k => setKey(k)}
          // className="similar-properties-tab-section"
        >
          <Tab eventKey={KEYS.sale}>
            <SliderTab items={propItems} />
          </Tab>
        </Tabs>
      </Container>
    </section>
  ) : null
}
export default SimilarProperties
