import clsx from "clsx"
import React, { useEffect, useRef } from "react"
import { Container } from "react-bootstrap"
import useDeviceMedia from "../../hooks/useDeviceMedia"

const HalfContainer = ({ children, className, paddingLeft }) => {
  const { isTablet } = useDeviceMedia()
  const ref = useRef(null)
  const customRef = useRef(null)

  useEffect(() => {
    if (isTablet) return

    const container = document.querySelector(".container")
    if (!container) return
    const { offsetLeft } = container

    const padding = `${offsetLeft}px`

    if (!customRef.current) return
    if (paddingLeft) customRef.current.style.paddingLeft = padding
    else customRef.current.style.paddingRight = padding
  }, [isTablet])

  const CustomContainer = isTablet ? Container : "div"

  return (
    <>
      <CustomContainer
        className={clsx("half-container", className)}
        ref={customRef}
      >
        {children}
      </CustomContainer>
    </>
  )
}

export default HalfContainer
