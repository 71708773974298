import React from "react"
import { newHomesSaleURL } from "../../common/utils/urls"
import useInitPropertyData from "../../components/SearchResults/hooks/useInitPropertyData"
import SearchResultsTemplate from "../../templates/search-results-template"

const PropertySale = props => {
  const location = props.location

  useInitPropertyData({
    type: "sales",
    department: "new_developments",
    status: ["For Sale"],
    locationName: newHomesSaleURL,
  })

  return <SearchResultsTemplate isChildOnly location={location} pSubPath="for-sale" />
}

export default PropertySale
