import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { Container } from "react-bootstrap"
import FormFields from "../../../static/forms/contact_form.json"
import { parseHTML } from "../../common/utils/utils"
import DefaultForm from "../forms/default-form-layout"
import "./ContactFormComponent.scss"

const ContactFormComponent = ({ data }) => {
  const officeData = useStaticQuery(graphql`
    query {
      strapiSiteConfig {
        ...siteConfigFragment
      }
    }
  `)

  const title = data.title.data.title
  const content = data.content.data.content
  const { phone, email, address } = officeData?.strapiSiteConfig
  const officeOpen =
    officeData?.strapiSiteConfig?.opening_hours?.data?.opening_hours
  const gtmEvent = () => {
    // tracking event
    if (typeof window !== "undefined") {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: "click",
        formType: "Phone number click",
        formId: "Phone number click",
        formName: "Phone number click",
        formLabel: "Phone number click",
      })
    }
  }
  const gtmEventEmail = () => {
    // tracking event
    if (typeof window !== "undefined") {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: "click",
        formType: "Email id click",
        formId: "Email id click",
        formName: "Email id click",
        formLabel: "Email id click",
      })
    }
  }


  return (
    <div className="contact-form-component">
      <Container>
        <div className="contact-enquiry-section">
          <div className="contact-left-section">
            <div className="general-form-section">
              <div className="content-section">
                <h1>General Enquiry</h1>
                <p className="title">{parseHTML(title)}</p>
                <p className="content">{parseHTML(content)}</p>
              </div>
              <DefaultForm
                fields={FormFields}
                formtagclassname="general-enquiry"
                to_email_id={"info@christopherhodgson.co.uk"}
              />
            </div>
          </div>

          <div className="contact-right-section">
            <div className="address-section">
              <h3>Office Address</h3>
              <p>{address}</p>
            </div>
            <div className="vertical-line"></div>
            <div className="contact-section">
              <h3>Contact Details</h3>
              <div className="contant-mode">
                <div className="office-number">
                  <a onClick={gtmEvent} href={`tel:${phone}`}>
                    {phone}
                  </a>
                </div>
                <div className="office-mail">
                  <a onClick={gtmEventEmail} href={`mailto:${email}`}>{email}</a>
                  {/* {email} */}
                </div>
              </div>
            </div>
            <div className="vertical-line"></div>
            <div className="opening-time-section">
              <h3>Opening Hours</h3>
              <div className="office-opening-time">{parseHTML(officeOpen)}</div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default ContactFormComponent
