import React from "react"
import { Container } from "react-bootstrap"
import "./StatsComponent.scss"
import CTALink from "../elements/CTALink"
import { parseHTML } from "../../common/utils/utils"
import ScrollAnimation from "react-animate-on-scroll"
import Animation from "../elements/Animation"

const StatsComponent = ({ module }) => {
  const { content, cta, stats, title } = module
  return (
    <div className="stat-component">
      <Container>
        <div className="stat-container section-m-120">
          <Animation animateInType={"up"} className="content-section">
            <h2 className="heading">{parseHTML(title?.data?.title)}</h2>
            <p className="content">{parseHTML(content?.data?.content)}</p>
            <CTALink
              cta={cta}
              className={`button button-green-outline valuation-btn ${cta.cta_label==="Book a Valuation"?"book_val_but":""}`}
            />
          </Animation>
          {stats?.length > 0 && (
            <Animation animateInType={"up"} className="stat-section">
              {stats.map(stat => (
                <>
                  <div className="stat-result">
                    <h3>{stat.stat_value}</h3>
                    <div className="stat_content">
                      <p className="stat-title">{stat?.stat_title}</p>
                      <p className="stat_description">
                        {stat?.stat_description}
                      </p>
                    </div>
                  </div>
                  <div className="horizontal-line"></div>
                </>
              ))}
            </Animation>
          )}
        </div>
      </Container>
    </div>
  )
}

export default StatsComponent
