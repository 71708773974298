import React from "react"
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs"
import Footer from "../Footer/Footer"
import Header from "../Header/Header"
import ValuationPopup from "../ValuationPopup/ValuationPopup"

const Layout = ({
  children,
  pageData,
  transparentHeader,
  breadcrumbURLs,
  footer,
  popular_search,
  is404,
  propertydetails,
  afterFooter,
  noFooter
}) => {
  // const popular_search = popular_search
  const pageLayout = pageData?.layout
  const menuData = pageData?.menu
  var isCampaign = false;
  if(pageData?.page_class){
    isCampaign = pageData?.page_class.includes("campaign_landing");
  } 
  const isTransparent =
    pageLayout === "home_page" ||
    pageLayout === "landing_page" ||
    // pageLayout === "static_page" ||
    transparentHeader

  return (
    <div>
      {!isCampaign &&
       <ValuationPopup />
      }
      
      <Header isCampaign={isCampaign} isTransparent={isTransparent} />
      {!is404 && pageLayout !== "landing_page" && !isCampaign && (
        // pageLayout !== "static_page" &&
        <Breadcrumbs
          menuData={menuData}
          isTransparent={isTransparent}
          staticURLs={breadcrumbURLs}
          propertydetails={propertydetails}
        />
      )}
      {children}
      {!noFooter &&
      <Footer footer={footer} popular_search={popular_search} isCampaign={isCampaign} />
      }
      {afterFooter}
    </div>
  )
}

export default Layout
