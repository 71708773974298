import { navigate } from "gatsby"
import React, { useEffect, useState } from "react"
import { Modal } from "react-bootstrap"
import Select, { components } from "react-select"
import {
  commercialPropertiesRentURL,
  commercialPropertiesSaleURL,
  newHomesSaleURL,
  propertiesRentURL,
  propertiesSaleURL,
} from "../../common/utils/urls"
import { Tab, Tabs } from "../CustomTabs/CustomTabs"
import { SearchInput } from "../SearchResults/SearchFilter/FiltersElements"
import { usePropertyStore } from "../SearchResults/store/propertyStore"
import { getAreaPath } from "../SearchResults/utils"
import "./SearchBox.scss"
import useSearchTabs from "../SearchResults/hooks/useSearchTabs"
import _ from "lodash"

const OPTIONS = [
  { value: "buy", label: "Buy" },
  { value: "rent", label: "Rent" },
]

const SearchBox = ({ label }) => {
  const { TABS, activeKey, setActiveKey } = useSearchTabs()

  const searchSlug = usePropertyStore(state => state.searchSlug)
  const searchText = usePropertyStore(state => state.searchText)
  const areaJSON = usePropertyStore(state => state.areasJSON)

  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)

  const [activeOption, setActiveOption] = useState(OPTIONS[0])
  const handleSelectChange = selectedOption => {
    setActiveOption(selectedOption)
  }

  useEffect(() => {
    if (!show) return
    // find the x,y coordinates of the search-overlay-btn
    const searchOverlayBtn = document.getElementById("search-overlay-btn")
    if (!searchOverlayBtn) return
    const searchOverlayBtnRect = searchOverlayBtn.getBoundingClientRect()

    // position the search-box-container
    const searchBoxContainer = document.getElementById("search-box-container")
    if (!searchBoxContainer) return
    searchBoxContainer.style.top = `${searchOverlayBtnRect.y + 20}px`
    searchBoxContainer.style.left = `${searchOverlayBtnRect.x - 664}px`
  }, [show])

  const onSearch = () => {
    let url = ""

    if (activeKey === TABS.residential) {
      if (activeOption.value === "buy") {
        url = propertiesSaleURL
      } else {
        url = propertiesRentURL
      }
    } else if (activeKey === TABS.commercial) {
      if (activeOption.value === "buy"){
        url = commercialPropertiesSaleURL
      }
      else{
      url = commercialPropertiesRentURL
      }
    } else if (activeKey === TABS.new_homes) {
      url = newHomesSaleURL
    }

    const area = areaJSON?.find(
      area =>
        area.name.toLowerCase() === searchText.toLowerCase() ||
        area.slug === searchText ||
        area.slug === searchSlug
    )
    url += getAreaPath(!_.isEmpty(area) ? searchSlug : searchText)

    navigate(url+'sortby-price-desc/')
    setShow(false)
  }

  return (
    <>
      <div
        className="nav-item"
        onClick={() => setShow(true)}
        id="search-overlay-btn"
      >
        <button className={"main-menu"}>{label}</button>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        as="div"
        className="search-box-wrap"
      >
        <Modal.Body>
          <div className="search-box-container" id="search-box-container">
            <Tabs
              className="search-tab-section"
              activeKey={activeKey}
              setActiveKey={setActiveKey}
            >
              <Tab eventKey={TABS.residential}>residential</Tab>
              <Tab eventKey={TABS.commercial}>commercial</Tab>
              <Tab eventKey={TABS.new_homes}>new homes</Tab>
            </Tabs>
            <div className="search-section">
              <div className="search-input-wrap">
                {(activeKey === TABS.residential || activeKey === TABS.commercial) && (
                  <>
                    <Select
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          border: "none",
                          boxShadow: "none",
                          backgroundColor: "transparent",
                          "&:hover": {
                            border: "none",
                          },
                        }),
                        indicatorSeparator: (provided, state) => ({
                          ...provided,
                          display: "none",
                        }),
                        dropdownIndicator: (provided, state) => ({
                          ...provided,
                          color: "#555C65",
                          transform: state.selectProps.menuIsOpen
                            ? "rotate(180deg)"
                            : "",
                        }),
                        singleValue: (provided, state) => ({
                          ...provided,
                          color: "#484848",
                          // fontFamily: "Gilroy",
                          fontSize: "14px",
                          lineHeight: "140%",
                        }),
                      }}
                      components={{
                        DropdownIndicator: props => (
                          <components.DropdownIndicator {...props}>
                            <i className="icon down-arrow-icon" />
                          </components.DropdownIndicator>
                        ),
                      }}
                      options={OPTIONS}
                      value={activeOption}
                      onChange={handleSelectChange}
                    />
                    <div className="search-separator"></div>
                  </>
                )}

                <SearchInput />
              </div>
              <button
                className="button button-green search-button"
                onClick={onSearch}
              >
                Search
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default SearchBox
