import clsx from "clsx"
import { Link } from "gatsby"
import React from "react"
import imageConfig from "../../../static/images/config.json"
import { ShowProcessedImage } from "../../common/ggfx-client/module/components/show-image"
import {
  propertydetailsCommercialRentURL,
  propertydetailsCommercialSaleURL,
  propertydetailsNewhomesSaleURL,
  propertydetailsRentURL,
  propertydetailsSaleURL,
} from "../../common/utils/urls"
import { currencyFormat } from "../../common/utils/utils"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import bathIcon from "../../images/icons/Baths.svg"
import bedIcon from "../../images/icons/Bed.svg"
import receptionIcon from "../../images/icons/Reception.svg"
import areaIcon from "../../images/icons/arrows-pointing-out.svg"
import NoImage from "../../images/noImage.png"
import Animation from "../elements/Animation"
import GGFXImage from "../elements/GGFXImage"
import HeartIcon from "../elements/HeartIcon"
import "./PropertyCard.scss"

const PropertyCard = ({
  noanimation,
  image,
  price,
  address,
  description,
  slug,
  id,
  pid,
  strapi_id,
  search_type,
  status,
  imagetransforms,
  slider_name,
  bedroom,
  bathroom,
  reception,
  floorarea_type,
  floorarea_max,
  department,
  propertyPage,
  userObjects,
  price_qualifier,
}) => {
  const { isTablet } = useDeviceMedia()

  let url = ""

  if (department === "residential") {
    if (search_type === "sales") {
      url = propertydetailsSaleURL
    } else if (search_type === "lettings") {
      url = propertydetailsRentURL
    }
  } else if (department === "commercial") {
    if (search_type === "sales") {
      url = propertydetailsCommercialSaleURL
    } else if (search_type === "lettings") {
      url = propertydetailsCommercialRentURL
    }
  } else if (department === "new_developments") {
    url = propertydetailsNewhomesSaleURL
  }
  // console.log(url)

  let processedImages
  if (imagetransforms) {
    if (slider_name === "area-guide-slider" || slider_name === "no-hits") {
      processedImages = imagetransforms?.images_Transforms || JSON.stringify({})
    } else {
      processedImages =
        JSON.parse(imagetransforms)?.images_Transforms || JSON.stringify({})
    }
  }

  const bedText = bedroom > 1 ? "Beds" : "Bed"
  const bathText = bathroom > 1 ? "Baths" : "Bath"
  const receptionText = reception > 1 ? "Receptions" : "Reception"

  let floorarea = ""

  if (floorarea_type === "sqft" && floorarea_max !== 0 && floorarea_max) {
    floorarea = Math.floor(floorarea_max / 10.764)
  }

  const imagename = "property.images.tileimg"
  return (
    <Animation
      animateInType={noanimation ? "no" : "up"}
      className={clsx(
        "property-card-section",
        propertyPage && "property-page-card"
      )}
    >
      <div className="image-section img-zoom">
        <Link to={`${url}${slug}/${strapi_id}/`}>
          {image ? (
            propertyPage ? (
              <ShowProcessedImage
                images={image}
                attr={{
                  className: "img-fluid",
                  alt: description,
                }}
                transforms={imageConfig.property.searchResults.sizes}
                className={"property-card_image"}
              />
            ) : (
              <GGFXImage
                ImageSrc={image}
                altText={`${description}`}
                imagetransforms={processedImages}
                renderer="srcSet"
                imagename={imagename}
                strapi_id={strapi_id}
                className={"property-card_image"}
              />
            )
          ) : (
            <img
              src={NoImage}
              alt={description}
              className={"property-card_image"}
            />
          )}
        </Link>
        {(status === "Sold" ||
          status === "Sold Subject To Contract" ||
          status === "Let" ||
          status === "Let Agreed") && (
          <div className="status-tag">
            <p className="status">{status}</p>
          </div>
        )}
      </div>
      <div className="content">
        <p className="address">
          <Link to={`${url}${slug}/${strapi_id}/`}>{address}</Link>
          <HeartIcon pid={id} userObjects={userObjects} />
        </p>
        <p className="price">
        {search_type === "sales" && price_qualifier?
          <span>{price_qualifier === "PCM"
            ? "pcm"
            : price_qualifier
            ? price_qualifier
            : ""}
          </span>: null}
          {currencyFormat(price, "£", false)}{" "}
          {search_type === "lettings"?
          <span>
          {price_qualifier === "PCM"
            ? "pcm"
            : price_qualifier
            ? price_qualifier
            : ""}
          </span>: null}
        </p>
        {/* <p className="description">{description}</p> */}
        <div className="icon-list d-flex">
          {bedroom ? (
            <div className="item d-flex">
              <img src={bedIcon} alt="bed" className="bed-icon" />
              <span>
                {bedroom} {!isTablet && propertyPage && !noanimation && bedText}
              </span>
            </div>
          ) : null}
          {bathroom ? (
            <div className="item d-flex">
              <img src={bathIcon} alt="bath" className="bath-icon" />
              <span>
                {bathroom}{" "}
                {!isTablet && propertyPage && !noanimation && bathText}
              </span>
            </div>
          ) : null}
          {reception ? (
            <div className="item d-flex">
              <img
                src={receptionIcon}
                alt="reception"
                className="reception-icon"
              />
              <span>
                {reception}{" "}
                {!isTablet && propertyPage && !noanimation && receptionText}
              </span>
            </div>
          ) : null}

          {floorarea_max&&floorarea_type && !noanimation ? (
            <div className="item d-flex">
              <img src={areaIcon} alt="area" className="area-icon" />
              <span>{floorarea_max} {floorarea_type} ({floorarea} sqm)</span>
            </div>
          ) : null}
        </div>
      </div>
    </Animation>
  )
}

export default PropertyCard
