import React from "react"
import "./ReviewsTotal.scss"
import reviewsImg from "../../images/googlereviewscolor.svg"
import reviewsWhiteImg from "../../images/google reviews.svg"
import { Link } from "gatsby"

const ReviewsTotal = ({ rating_avg, testimonial_count, isWhite }) => {
  return (
    <div className="reviews-section">
      <Link to="/about/reviews/">
        {isWhite ?
          <img src={reviewsWhiteImg} alt="reviews-img" className="reviews-img" />
          :
          <img src={reviewsImg} alt="reviews-img" className="reviews-img" />
        }

      </Link>
      <Link to="/about/reviews/">
        <p className="review-text">
          <span>{parseFloat(rating_avg).toFixed(1)}/5</span> from{" "}
          {testimonial_count} reviews
        </p>
      </Link>
    </div>
  )
}

export default ReviewsTotal
