import getVideoId from "get-video-id"
import React, { useRef, useState } from "react"
import VideoModal from "./VideoModal"
import { eventTracking} from "../../common/utils/utils"
const PlayVideoButton = ({ videoUrl }) => {
  const [isOpen, setIsOpen] = useState(false)

  if (!videoUrl) return null

  const url = typeof window !== 'undefined' ? window.location.href : '';

  return isOpen ? (
    <VideoModal isOpen={isOpen} setIsOpen={setIsOpen} videoUrl={videoUrl} />
  ) : (
    <button className="play-button" onClick={() => {
      setIsOpen(true) 
      eventTracking({eventName :"Video Click",url:url})
    }} />
  )
}

export default PlayVideoButton
