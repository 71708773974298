import clsx from "clsx"
import React from "react"
import { SaveItem } from "@starberry/myaccount-website-utils"
const HeartIcon = ({ className, pid, userObjects }) => {
  return className === "filter-icon" ? (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx("heart-icon", className)}
    >
      <path
        opacity="0.8"
        d="M10.0009 16.875C10.0009 16.875 2.18842 12.5 2.18842 7.18751C2.18857 6.24855 2.51393 5.33863 3.10915 4.61244C3.70438 3.88626 4.53275 3.38863 5.45341 3.20418C6.37407 3.01972 7.3302 3.15982 8.15924 3.60066C8.98828 4.04149 9.63905 4.75585 10.0009 5.62227L10.0009 5.62228C10.3628 4.75585 11.0135 4.04149 11.8426 3.60066C12.6716 3.15983 13.6278 3.01973 14.5484 3.20418C15.4691 3.38863 16.2974 3.88625 16.8927 4.61244C17.4879 5.33862 17.8133 6.24855 17.8134 7.18751C17.8134 12.5 10.0009 16.875 10.0009 16.875Z"
        fill="white"
        stroke="#484848"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ) : (
    <SaveItem type="property" pid={pid} userObjects={userObjects} >
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={clsx("heart-icon", className)}
      >
        <path
          opacity="0.8"
          d="M10.0009 16.875C10.0009 16.875 2.18842 12.5 2.18842 7.18751C2.18857 6.24855 2.51393 5.33863 3.10915 4.61244C3.70438 3.88626 4.53275 3.38863 5.45341 3.20418C6.37407 3.01972 7.3302 3.15982 8.15924 3.60066C8.98828 4.04149 9.63905 4.75585 10.0009 5.62227L10.0009 5.62228C10.3628 4.75585 11.0135 4.04149 11.8426 3.60066C12.6716 3.15983 13.6278 3.01973 14.5484 3.20418C15.4691 3.38863 16.2974 3.88625 16.8927 4.61244C17.4879 5.33862 17.8133 6.24855 17.8134 7.18751C17.8134 12.5 10.0009 16.875 10.0009 16.875Z"
          fill="white"
          stroke="#484848"
          stroke-width="1.2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </SaveItem>
  )
}

export default HeartIcon
