import React, { useState } from "react"
import heartIcon from "../../images/icons/Heart.svg"
import shareIcon from "../../images/icons/ShareNetwork.svg"
import envIcon from "../../images/icons/envelope.svg"
import callIcon from "../../images/icons/phone.svg"
import "./PropertyCta.scss"

import { SaveItem } from "@starberry/myaccount-website-utils"
import { Link } from "gatsby"
import { bookViewingURL } from "../../common/utils/urls"
import { eventTracking, trackShare } from "../../common/utils/utils"
import ShareIcons from "../SocialShare/ShareIcons"
const PropertyCta = (props) => {
  const { soldrules, id, data, userObjects }=props
  const [showShareIcons, setShowShareIcons] = React.useState(false)
  const [showModal, setShowModal] = useState(false)

  const handleModal = () => {
    setShowModal(true)
  }

  const handleClose = () => {
    setShowModal(false)
  }

  const pageUrl=typeof window!=="undefined"?window.location.href:""

  const image =
    data?.images?.strapi_json_value?.length > 0
      ? data?.images?.strapi_json_value[0]
      : ""
  const imagename = "property.images.tileimg"

  let processedImages = data?.imagetransforms?.images_Transforms
  processedImages ||= JSON.stringify({})

  const url = typeof window !== 'undefined' ? window.location.href : '';


  return (
    <div className="property-cta">
      {showShareIcons && (
        <div className="d-block d-xl-none">
          <ShareIcons propertyData={props.data} isProperty />
        </div>
      )}
      <div className="top-bk d-flex">
        {soldrules ? null : (
          <Link
            to={bookViewingURL}
            state={{
              data: {
                property: props.data,
                pageUrl:pageUrl,
              },
            }}
            onClick={() => {
              sessionStorage.setItem(
                  "propertydata",
                  JSON.stringify(props.data)
                );
                sessionStorage.setItem(
                  "pageUrl",
                  JSON.stringify(pageUrl)
                );
            }}
            className="button button-green"
          >
            <span className="d-none d-md-inline">enquire now</span>
            <span className="d-inline d-md-none">enquire</span>
            <span className="d-inline d-xl-none">
              <img src={envIcon} alt="envolop" className="env-icon" />
            </span>
          </Link>
        )}
        {soldrules ? null : (
          <Link
            to={`tel:01227 266 441`}
            className="button button-green d-flex d-xl-none"
          >
            <span className="d-none d-md-inline">01227 266 441</span>
            <span className="d-inline d-md-none">Call</span>
            <span className="d-inline d-xl-none">
              <img src={callIcon} alt="envolop" className="call-icon" />
            </span>
          </Link>
        )}
        <SaveItem type="property" pid={data.crm_id} userObjects={userObjects}>
          <button className="button button-icon heart-button" onClick={()=>eventTracking({eventName :"Save property",url:url})}>
            <img src={heartIcon} alt="heart" className="bath-icon heart-icon" />
          </button>
        </SaveItem>
        <button
          className="button button-icon share-btn"
          onClick={() => {
            setShowShareIcons(!showShareIcons)
            trackShare("Share Property")
          }}
        >
          <img src={shareIcon} alt="share" className="bath-icon" />
        </button>
      </div>

      {showShareIcons && (
        <div className="d-none d-xl-block">
          <ShareIcons propertyData={props.data} isProperty />
        </div>
      )}
      {soldrules ? null : (
        <div className="bot-bk d-none d-xl-block">
          <Link to={`tel:01227 266 441`}>
            {" "}
            or call on <span>01227 266 441</span>
          </Link>
        </div>
      )}
    </div>
  )
}

export default PropertyCta
