import { Link } from "gatsby"
import React, { useState } from "react"
import { Modal } from "react-bootstrap"
import FormFields from "../../../static/forms/team_form.json"
import { teamsURL } from "../../common/utils/urls"
import Animation from "../elements/Animation"
import GGFXImage from "../elements/GGFXImage"
import DefaultForm from "../forms/default-form-layout"

const PropertyNegotiator = ({ prop_address, prop_url, teamData, altPhone, altEmail, isLinkDisable }) => {
  const [show, setShow] = useState(false)

  // const [teamData, setTeamData] = useState(teamData)
  // const [loading, setLoading] = useState(false)

  // useEffect(() => {
  //   if (!_.isEmpty(teamData)) return

  //   const url = `${process.env.GATSBY_STRAPI_SRC}/api/teams?filters[publish][$eq]=true&filters[negotiator_id][$eq]=${nego_id}&populate=image`
  //   fetch(url, {
  //     headers: new Headers({
  //       Authorization: `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
  //     }),
  //   })
  //     .then(response => response.json())
  //     .then(({ data }) => {
  //       setTeamData(data)
  //       setLoading(false)
  //     })
  //     .catch(err => {
  //       // console.log(err)
  //       setLoading(false)
  //     })
  // }, [nego_id])

  // if (!teamData?.length > 0) {
  //   return
  // }

  // const teamMember = teamData?.length > 0 ? teamData[0] : ""
  const { name, image, phone, strapi_id, imagetransforms, slug, position, email } =
    teamData
  let processedImages = imagetransforms?.image_Transforms
  processedImages ||= JSON.stringify({})
  const link = `${teamsURL}${slug}/`

  const handleModal = () => {
    setShow(true)
  }

  const handleClose = () => {
    setShow(false)
  }

  let team_phone = phone;
  if(altPhone){
    team_phone = altPhone;
  }
  let team_email = email;
  if(altEmail){
    team_email = altEmail;
  }

  return (
    <Animation className="nego-card">
      {isLinkDisable ?
      <div className="nego__img img-zoom">
        <GGFXImage
          ImageSrc={image}
          title={name}
          altText={name}
          imagetransforms={processedImages}
          renderer="srcSet"
          imagename={"team.image.img"}
          strapi_id={strapi_id}
        />
      </div>
      :
      <Link to={link} className="nego__img img-zoom">
        <GGFXImage
          ImageSrc={image}
          title={name}
          altText={name}
          imagetransforms={processedImages}
          renderer="srcSet"
          imagename={"team.image.img"}
          strapi_id={strapi_id}
        />
      </Link>
      } 
      <div className="nego__content">
        <p className="name">{name}</p>
        <p className="designation">{position}</p>
        <div className="nego__contact">
          <a href={`tel:${team_phone}`} className="phone">
            {team_phone}
          </a>
          <span>/</span>
          <a onClick={handleModal} href="javascript:void(0)" className="email">
            Email
          </a>
        </div>
      </div>
      <Modal
        show={show}
        // onHide={handleClose}
        backdrop="static"
        centered
        dialogClassName="modal-popup-form"
      >
        <Modal.Header>
          <div className="modal-header-container">
            <div className="logo">
              <i className="icon black-logo-icon"></i>
            </div>
            <div className="close-modal" onClick={() => handleClose()}>
              <i className="icon white-close-icon"></i>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="popup-form-wrapper">
            <div>
              <div className="propety-info">
                <p className="heading">Team Enquiry</p>
              </div>
              <DefaultForm
                fields={FormFields}
                formtagclassname="team-enquiry"
                teammembername={name}
                to_email_id={team_email}
                email_subject_user={`Contact ${name}`}
                email_subject_admin={`Contact ${name}`}
                prop_address={prop_address}
                prop_url={prop_url}
                propertyTeam={true}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Animation>
  )
}

export default PropertyNegotiator
