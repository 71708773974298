import clsx from "clsx"
import { graphql } from "gatsby"
import React from "react"
import wordsToNumbers from "words-to-numbers"
import BannerListing from "../components/BannerListing/BannerListing"
import BannerListing2 from "../components/BannerListing2/BannerListing2"
import ImageCtaComponent from "../components/ImageCtaComponent/ImageCtaComponent"
import Layout from "../components/Layout/Layout"
import NewsListing from "../components/NewsListing/NewsListing"
import Reviews from "../components/Reviews/Reviews"
import SEO from "../components/SEO/seo"
import SocialWall from "../components/SocialWall/SocialWall"
import TeamListing from "../components/TeamListing/TeamListing"
const ListingTemplate = ({ data }) => {
  const pageData = data?.strapiPage
  const modules = pageData?.add_page_modules
  const banner = pageData?.banner
  let testimonials = data?.greviews?.reviews
  if(testimonials){
    const fiveStarTestimonials = testimonials?.filter(review => review.starRating === "FIVE");
    testimonials = fiveStarTestimonials;
  }
  // const [loader, setloader] = useState(false)

  // const strapiconfig = {
  //   headers: {
  //     Authorization: `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
  //   },
  // }
  // useEffect(() => {
  //   let url = `${process.env.GATSBY_STRAPI_SRC}/api/stb-google-reviews/google-review-reviews`
  //   getitems(url)
  // }, [])

  // // var ggl_reviews = [];
  // const getitems = async url => {
  //   setloader(true)
  //   try {
  //     const { data } = await axios.get(url, strapiconfig) // could be from env files
  //     setTestimonials(data)
  //     setloader(false)
  //   } catch (error) {
  //     // cache it if fail/error;
  //     console.error(error)
  //     setloader(false)
  //   }
  // }
  var testimonial_count = testimonials ? testimonials.length : 0
  var rating_count = 0
  testimonials &&
    testimonials.length > 0 &&
    testimonials.map((item, ind) => {
      rating_count += parseInt(wordsToNumbers(item.starRating))
    })
  var rating_avg = rating_count / testimonial_count
  return (
    <div className={clsx("listing-template", pageData.page_class)}>
      <Layout
        popular_search={pageData?.select_popular_search}
        pageData={pageData}
      >
        <SEO
          title={
            pageData?.seo?.meta_title
              ? pageData?.seo?.meta_title
              : pageData?.title
          }
          description={
            pageData?.seo?.meta_description
              ? pageData?.seo?.meta_description
              : pageData?.title
          }
        />
        {pageData.layout === "listing_page" && (
          <BannerListing
            // loader={loader}
            rating_avg={rating_avg}
            testimonial_count={testimonial_count}
            pageData={pageData}
          />
        )}
        {pageData.layout === "listing_page_2" && (
          <BannerListing2 data={banner} />
        )}

        {modules?.map((module, index) => {
          return (
            <div>
              {module.__typename ===
                "STRAPI__COMPONENT_PAGE_MODULES_LISTING_MODULE" &&
                module.select_module === "team" && <TeamListing />}
              {module.__typename ===
                "STRAPI__COMPONENT_PAGE_MODULES_IMAGE_BANNER" && (
                <ImageCtaComponent module={module} />
              )}
              {module.__typename ===
                "STRAPI__COMPONENT_PAGE_MODULES_NEWS_LISTING" && (
                <NewsListing module={module} />
              )}

              {module.__typename ===
                "STRAPI__COMPONENT_PAGE_MODULES_LISTING_MODULE" &&
                module.select_module === "reviews" && (
                  <Reviews testimonials={testimonials} />
                )}
              {module.__typename ===
                "STRAPI__COMPONENT_PAGE_MODULES_LISTING_MODULE" &&
                module.select_module === "social_wall" && <SocialWall />}
            </div>
          )
        })}
      </Layout>
    </div>
  )
}

export default ListingTemplate

export const query = graphql`
  query ($page_id: String) {
    strapiPage(id: { eq: $page_id }) {
      ...PageFragment
      add_page_modules {
        ... on STRAPI__COMPONENT_PAGE_MODULES_LISTING_MODULE {
          __typename
          id
          select_module
          strapi_id
        }
        ... on STRAPI__COMPONENT_PAGE_MODULES_IMAGE_BANNER {
          ...ImageCtaBanner
        }

        ... on STRAPI__COMPONENT_PAGE_MODULES_NEWS_LISTING {
          ...NewsListingFragment
        }
      }
    }
      greviews(reviews: {elemMatch: {starRating: {eq: "FIVE"}}}) {
      reviews {
        id
        name
        comment
        starRating
        reviewer {
          displayName
          profilePhotoUrl
        }
      }
    }
  }
`
