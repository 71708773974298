module.exports.PageLinks = {
  about: "about",
  contact: "contact",
  enquiry: "contact/general-enquiry",
  news: "property-news-and-insights",
  team: "about/our-team",
  career: "about/careers",
  homevaluation: "/property-valuation/home-valuation/",
  instvaluation: "/property-valuation/instant-valuation/"
}
