import { graphql, useStaticQuery } from "gatsby"

const useAreaGuides = () => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiAreaGuide(filter: { publish: { eq: true } }) {
        nodes {
          ...AreaGuideFragment
        }
      }
    }
  `)

  return data.allStrapiAreaGuide.nodes
}

export default useAreaGuides
