import { Link } from "gatsby"
import React, { useState } from "react"
import { Modal } from "react-bootstrap"
import FormFields from "../../../static/forms/team_form.json"
import { teamsURL } from "../../common/utils/urls"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import Animation from "../elements/Animation"
import GGFXImage from "../elements/GGFXImage"
import DefaultForm from "../forms/default-form-layout"
import "./NegoCard.scss"

const NegoCard = ({ team, isBottomLine }) => {
  const { name, position, image, phone, slug, imagetransforms, strapi_id, email, office_email, office_phone } =
    team

  const [show, setShow] = useState(false)

  const { isMobile } = useDeviceMedia()

  const link = `${teamsURL}${slug}/`

  const handleModal = () => {
    setShow(true)
  }

  const handleClose = () => {
    setShow(false)
  }

  let processedImages = imagetransforms?.image_Transforms
  processedImages ||= JSON.stringify({})
  let team_phone = phone;
  if(office_phone){
    team_phone = office_phone;
  }
  let team_email = email;
  if(office_email){
    team_email = office_email;
  }

  return (
    <>
      <Animation className="nego-card">
        <Link to={link} className="nego__img img-zoom">
          <GGFXImage
            ImageSrc={image}
            title={name}
            altText={name}
            imagetransforms={processedImages}
            renderer="srcSet"
            imagename={"team.image.img"}
            strapi_id={strapi_id}
          />
        </Link>
        <div className="nego__content">
          <p className="name">{name}</p>
          <p className="designation">{position}</p>
          <div className="nego__contact">
            <a href={`tel:${team_phone}`} className="phone">
              {team_phone}
            </a>
            <span>/</span>
            <a onClick={handleModal} href="javascript:void(0)" className="email">
              Email
            </a>
          </div>
        </div>
        <Modal
          show={show}
          // onHide={handleClose}
          backdrop="static"
          centered
          dialogClassName="modal-popup-form"
        >
          <Modal.Header>
            <div className="modal-header-container">
              <div className="logo">
                <i className="icon black-logo-icon"></i>
              </div>
              <div className="close-modal" onClick={() => handleClose()}>
                <i className="icon white-close-icon"></i>
              </div>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="popup-form-wrapper">
              <div>
                <div className="propety-info">
                  <p className="heading">Team Enquiry</p>
                </div>
                <DefaultForm
                  fields={FormFields}
                  formtagclassname="team-enquiry"
                  teammembername={name}
                  to_email_id={team_email}
                  email_subject_user={`Contact ${name}`}
                  email_subject_admin={`Contact ${name}`}
                />
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </Animation>
      {isBottomLine &&
        <span className="hr-line"></span>
      }
    </>
  )
}

export default NegoCard
