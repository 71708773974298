import { graphql, useStaticQuery } from "gatsby"
import React, { useEffect } from "react"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import CustomSlider from "../CustomSlider/CustomSlider"
import "./FeatureProperties.scss"
import FeaturedPropertyCard from "./FeaturedPropertyCard"
const FeatureProperties = props => {
  const data = useStaticQuery(graphql`
    query {
      resisales: allStrapiProperty(
        filter: {
          publish: { eq: true }
          status: { eq: "For Sale" }
          department: { eq: "residential" }
          search_type: { eq: "sales" }
        }
        sort: { fields: createdAt, order: DESC }
        limit: 10
      ) {
        edges {
          node {
            title
            strapi_id
            department
            images {
              strapi_json_value {
                url
                srcUrl
              }
            }
            display_address
            description {
              data {
                description
              }
            }
            price
            slug
            crm_id
            id
            search_type
            status
            bedroom
            bathroom
            floorarea_max
            floorarea_type
            reception
            imagetransforms {
              images_Transforms
            }
            price_qualifier
          }
        }
      }
      resilettings: allStrapiProperty(
        filter: {
          publish: { eq: true }
          status: { eq: "To Let" }
          department: { eq: "residential" }
          search_type: { eq: "lettings" }
        }
        sort: { fields: createdAt, order: DESC }
        limit: 10
      ) {
        edges {
          node {
            title
            strapi_id
            department
            images {
              strapi_json_value {
                url
              }
            }
            display_address
            description {
              data {
                description
              }
            }
            price
            slug
            crm_id
            id
            search_type
            status
            bedroom
            bathroom
            floorarea_max
            floorarea_type
            reception
            imagetransforms {
              images_Transforms
            }
            price_qualifier
          }
        }
      }

      commercial: allStrapiProperty(
        filter: {
          publish: { eq: true }
          status: { in: ["For Sale", "To Let"] }
          department: { eq: "commercial" }
          search_type: { in: ["lettings", "sales"] }
        }
        sort: { fields: createdAt, order: DESC }
        limit: 10
      ) {
        edges {
          node {
            title
            strapi_id
            department
            images {
              strapi_json_value {
                url
              }
            }
            display_address
            description {
              data {
                description
              }
            }
            price
            slug
            crm_id
            id
            search_type
            status
            bedroom
            bathroom
            floorarea_max
            floorarea_type
            reception
            imagetransforms {
              images_Transforms
            }
            price_qualifier
          }
        }
      }
      new_developments: allStrapiProperty(
        filter: {
          publish: { eq: true }
          status: { eq: "For Sale" }
          department: { eq: "new_developments" }
          search_type: { eq: "sales" }
        }
        sort: { fields: createdAt, order: DESC }
        limit: 10
      ) {
        edges {
          node {
            title
            strapi_id
            department
            images {
              strapi_json_value {
                url
              }
            }
            display_address
            description {
              data {
                description
              }
            }
            price
            slug
            crm_id
            id
            search_type
            status
            bedroom
            bathroom
            floorarea_max
            floorarea_type
            reception
            imagetransforms {
              images_Transforms
            }
            price_qualifier
          }
        }
      }
    }
  `)
  let propertyData = []
  const pageName = props.pageData?.page_class
  if (pageName === "buy_page" || pageName === "sell_page") {
    propertyData = data?.resisales?.edges
  } else if (pageName === "rent_page" || pageName === "landlords_page") {
    propertyData = data?.resilettings?.edges
  } else if (pageName === "commercial_page") {
    propertyData = data?.commercial?.edges
  } else if (pageName === "land_Developments_page") {
    propertyData = data?.new_developments?.edges
  } else {
    propertyData = data?.resisales?.edges
  }
  // useEffect(() => {
  //   if (!_.isEmpty(pageData)) return

  //   const url = `${process.env.GATSBY_STRAPI_SRC}/api/properties?filters[publish][$eq]=true&filters[department][$eq]=${department}${search_type}${sort}`
  //   fetch(url, {
  //     headers: new Headers({
  //       Authorization: `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
  //     }),
  //   })
  //     .then(response => response.json())
  //     .then(({ data }) => {
  //       setPageData(data.slice(0, 10))
  //       setLoading(false)
  //       setTimeout(() => {
  //         if (
  //           typeof window !== "undefined" &&
  //           window.location.href.includes("mortgage-calculator")
  //         ) {
  //           const getId = document.getElementById("mortgage-calculator")
  //           getId?.scrollIntoView({ behavior: "smooth" })
  //         }
  //       }, 1000)
  //     })
  //     .catch(err => {
  //       // console.log(err)
  //       setLoading(false)
  //     })
  // }, [pageName])

  const { isTablet } = useDeviceMedia()

  useEffect(() => {
    setTimeout(() => {
      let getClass = document.querySelector(".featured-slider")
      if (!getClass) return
      let slickDots = getClass?.querySelector(".slick-dots")
      if (!slickDots) return

      let getId = document.getElementById("feature-btn")

      const { offsetLeft } = getId

      let padding
      if (isTablet) {
        padding = `${offsetLeft - 6}px`
      } else {
        padding = `${offsetLeft - 8}px`
      }

      slickDots.style.paddingLeft = padding

      if (!isTablet) {
        slickDots.style.position = "absolute"
      } else {
        slickDots.style.position = "relative"
      }
    }, 3000)
  }, [isTablet, pageName])

  // if (!pageData.length > 0) {
  //   return
  // }

  return (
    propertyData?.length > 0 && (
      <div className="featured-properties-wrapper">
        <CustomSlider
          className="featured-slider"
          slider_type="feature-properties"
        >
          {propertyData?.map(({ node }, i) => {
            const imageUrl =
              node?.images?.strapi_json_value?.length > 0
                ? node?.images?.strapi_json_value[0]
                : ""
            // const imageUrl =
            //   node?.images?.length > 0
            //     ? node?.images[0]
            //     : ""
            return (
              <FeaturedPropertyCard
                address={node?.display_address}
                description={node?.description}
                image={imageUrl}
                price={node?.price}
                title={node?.title}
                slug={node?.slug}
                id={node?.crm_id}
                strapi_id={node?.strapi_id}
                search_type={node?.search_type}
                status={node?.status}
                bedroom={node?.bedroom}
                department={node?.department}
                bathroom={node?.bathroom}
                floorarea_max={node?.floorarea_max}
                floorarea_type={node?.floorarea_type}
                reception={node?.reception}
                imagetransforms={node?.imagetransforms}
                price_qualifier={node?.price_qualifier}
              />
            )
          })}
        </CustomSlider>
      </div>
    )
  )
}

export default FeatureProperties
