import React from "react"
import useInitPropertyData from "../../components/SearchResults/hooks/useInitPropertyData"
import SearchResultsTemplate from "../../templates/search-results-template"
import { propertiesRentURL } from "../../common/utils/urls"

const PropertySale = props => {
  const location = props.location

  useInitPropertyData({
    type: "lettings",
    department: "residential",
    status: ["To Let"],
    locationName: propertiesRentURL,
  })

  return <SearchResultsTemplate location={location} pSubPath="to-rent" />
}

export default PropertySale
