import React, { useState, useRef } from "react"
import { Modal } from "react-bootstrap"
import { isIOS, isMobile } from "react-device-detect"
import getVideoId from "get-video-id"
import "./PlayVideo.scss"
import loadable from "@loadable/component"
const ReactPlayer = loadable(() => import("react-player"))

const VideoModal = ({ videoUrl, isOpen, setIsOpen }) => {
  const vidRef = useRef(null)
  const fullscreen = true

  let video_url = ""
  const videoid = videoUrl && getVideoId(videoUrl)
  if (videoid) {
    video_url = "https://www.youtube-nocookie.com/embed/" + videoid.id
  }

  if (!videoUrl) return null

  return (
    <React.Fragment>
      <Modal
        show={isOpen}
        onHide={() => setIsOpen(false)}
        dialogClassName="modal-fullscreen modal-video"
        aria-labelledby="example-custom-modal-styling-title"
        backdropClassName="video-backdrop"
        fullscreen={fullscreen}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <ReactPlayer
            onEnded={() => {
              setIsOpen(false)
            }}
            frameborder="0"
            webkitallowfullscreen
            mozallowfullscreen
            allowfullscreen
            ref={vidRef}
            url={video_url}
            modestbranding="1"
            controls={true}
            autoplay={true}
            muted={isMobile && isIOS ? true : false}
            playsinline={true}
            playing={isOpen}
            width="100%"
            height="100%"
          />
        </Modal.Body>
      </Modal>
    </React.Fragment>
  )
}

export default VideoModal
