import React from "react"

function createMarkup(props) {
  return { __html: props.html }
}

function MyComponent(props) {
  return <div dangerouslySetInnerHTML={createMarkup(props)} />
}

const HtmlBox = ({ fieldClass, text, step, className, id }) => (
  <div
    className={`form-group ${step} ${className} form-html-text-element`}
    id={`${id}`}
  >
    <div className={fieldClass}>
      <MyComponent html={text} />
    </div>
  </div>
)

export default HtmlBox
