import _ from "lodash"
import React from "react"
import { Container } from "react-bootstrap"
import useTeams from "../../hooks/dataHooks/useTeams"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import usePagination from "../../hooks/usePagination"
import Pagination from "../Pagination/Pagination"
import TeamCard from "../TeamCard/TeamCard"
import "./TeamListing.scss"

const TeamListing = () => {
  const teams = useTeams()

  const { isMobile, isTablet } = useDeviceMedia()

  const itemsPerPage = isMobile ? 12 : isTablet ? 10 : 12
  const paginationProps = usePagination({
    items: teams,
    itemsPerPage,
  })

  if (_.isEmpty(teams)) return null

  return (
    <div className="team-listing-wrap">
      <Container className="team-listing-container">
        <div className="team-listing-grid">
          {paginationProps.currentItems.map((team, index) => (
            <TeamCard index={index} team={team} />
          ))}
        </div>
        <Pagination {...paginationProps} />
      </Container>
    </div>
  )
}

export default TeamListing
