import "animate.css/animate.min.css"
import React from "react"
import ScrollAnimation from "react-animate-on-scroll"

const Animation = ({ children, animateInType, ...props }) => {
  let animateIn = ""

  switch (animateInType) {
    case "no":
      animateIn = ""
      break
    case "left":
      animateIn = "animate__fadeInLeft"
      break
    case "right":
      animateIn = "animate__fadeInRight"
      break
    case "up":
      animateIn = "animate__fadeInUp"
      break
    case "down":
      animateIn = "animate__fadeInDown"
      break
    default:
      animateIn = "animate__fadeIn"
      break
  }

  return animateInType === "no" ? (
    <div {...props}> {children}</div>
  ) : (
    <ScrollAnimation animateIn={animateIn} animateOnce {...props}>
      {children}
    </ScrollAnimation>
  )
}

export default Animation
