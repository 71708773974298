import React, { useEffect } from "react"
import Modal from 'react-bootstrap/Modal'
import {
  EmailShareButton,
  FacebookShareButton,
  // LinkedinShareButton,
  // PinterestShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from "react-share"
import FormFields from "../../../static/forms/share_to_friend_form.json"
import { trackShare } from "../../common/utils/utils"
import mailIcon from "../../images/icons/Mail.svg"
import whatsappIcon from "../../images/icons/WhatsApp.svg"
import fbIcon from "../../images/icons/facebook.svg"
// import linkedInIcon from "../../images/icons/linkedin.svg"
// import pintrestIcon from "../../images/icons/pinterest.svg"
import twitterIcon from "../../images/icons/twitter.svg"

import DefaultForm from "../forms/default-form-layout"
const ShareIcons = ({ url, mediaImg, propertyData, isProperty }) => {
  const [shareURL, setShareURL] = React.useState(url)
  const [modalSharefrndformOpen,setSharefrndformOpen] = React.useState(false);
  const openSharefrndformModal = () => {
    setSharefrndformOpen(true);
  }
  const closeSharefrndformModal = () => {
    setSharefrndformOpen(false);
  }
  useEffect(() => {
    if (!url) {
      setShareURL(window.location.href)
    }
  }, [url])

  return (
    <div className="share-social-icons">
      <FacebookShareButton
        onClick={() => trackShare("FacebookShareButton", shareURL)}
        url={shareURL}
        className="my-share-button facebook-share"
      >
        <img src={fbIcon} alt="social icon" />
      </FacebookShareButton>
      {/* <LinkedinShareButton
        onClick={() => trackShare("LinkedinShareButton", shareURL)}
        url={shareURL}
        className="my-share-button linkedin-share"
      >
        <img src={linkedInIcon} alt="social icon" />
      </LinkedinShareButton> */}
      <TwitterShareButton
        onClick={() => trackShare("TwitterShareButton", shareURL)}
        url={shareURL}
        className="my-share-button twitter-share"
      >
        <img src={twitterIcon} alt="social icon" />
      </TwitterShareButton>
      <WhatsappShareButton
            onClick={() => trackShare("WhatsappShareButton", shareURL)}
            url={shareURL}
            className="my-share-button whatsapp-share"
          >
            {/* <WhatsappIcon size={32} round={false} borderRadius="10" /> */}
            <img src={whatsappIcon} alt="social icon" />
          </WhatsappShareButton>
          {isProperty ?
          <EmailShareButton url='#' onClick={()=> { openSharefrndformModal();trackShare('SendToFriend', shareURL
          )}} className="my-share-button email-share">
                                                {/* <EmailIcon size={32} round={false} borderRadius={`10`} /> */}
                                                <img src={mailIcon} alt="social icon" />
                                            </EmailShareButton>: null}
      {/* {mediaImg && (
        <PinterestShareButton
          onClick={() => trackShare("PinterestShareButton", shareURL)}
          url={shareURL}
          className="my-share-button pinterest-share"
          media={mediaImg}
        >
          <img src={pintrestIcon} alt="social icon" />
        </PinterestShareButton>
      )} */}
       <Modal
        show={modalSharefrndformOpen}
        onHide={() => { closeSharefrndformModal(false) }}
        backdrop="static"
        centered
        dialogClassName="modal-popup-form"
      >
        <Modal.Header>
          <div className="modal-header-container">
            <div className="logo">
              <i className="icon black-logo-icon"></i>
            </div>
            <div className="close-modal" onClick={() => closeSharefrndformModal(false)}>
              <i className="icon white-close-icon"></i>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="popup-form-wrapper">
            <div>
              <div className="propety-info">
                <p className="heading">Share to a friend</p>
              </div>
              <DefaultForm
                fields={FormFields}
                formtagclassname="team-enquiry"
                to_email_id={"info@christopherhodgson.co.uk"}
                prop_url={shareURL}
                prop_address={propertyData?.display_address}
                // email_subject_user={`Contact ${teammember.name}`}
                // email_subject_admin={`Contact ${teammember.name}`}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default ShareIcons
