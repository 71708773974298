import { graphql, useStaticQuery } from "gatsby"

const useCompanyInfo = () => {
  const data = useStaticQuery(graphql`
    query {
      strapiSiteConfig {
        facebook_link
        email
        address
        id
        instagram_link
        linkedin_link
        name
        phone
        strapi_id
        twitter_link
      }
    }
  `)

  const {
    facebook_link,
    email,
    address,
    id,
    instagram_link,
    linkedin_link,
    name,
    phone,
    strapi_id,
    twitter_link,
  } = data.strapiSiteConfig

  return {
    facebook_link,
    email,
    address,
    id,
    instagram_link,
    linkedin_link,
    name,
    phone,
    strapi_id,
    twitter_link,
  }
}

export default useCompanyInfo
