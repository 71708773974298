import clsx from "clsx"
import React from "react"
import { parseHTML } from "../../common/utils/utils"
import { areaGuidesURL } from "../../common/utils/urls"
import PlayVideoButton from "../PlayVideo/PlayVideoButton"
import Animation from "../elements/Animation"
import CTALink from "../elements/CTALink"
import GGFXImage from "../elements/GGFXImage"
import HalfContainer from "../elements/HalfContainer"


const AreaGuideBlock = ({ module, pageData }) => {

  const { strapi_id, name, video_url, slug } = module;
  const { imagetransforms, office_area_guide_image, office_area_guide_content } = pageData;
  console.log('pageData', imagetransforms, office_area_guide_image)
 

  console.log('moduledddd', module)



  let processedImages = JSON.stringify({}) //imagetransforms?.areaguide_content_image_Transforms
  processedImages ||= JSON.stringify({})

  return (
    <div
      className={clsx("tile-block-full-wrap", `bg-white, right`)}
    >
      <Animation className="image-section">
        {office_area_guide_image &&
          <GGFXImage
            ImageSrc={office_area_guide_image}
            title={name}
            altText={"banner-img"}
            imagetransforms={processedImages}
            renderer="srcSet"
            imagename={"office.areaguide_content_image.detail_image"}
            strapi_id={strapi_id}
            className={"image"}
          />
        }

        {video_url &&
          <PlayVideoButton videoUrl={video_url} />
        }

      </Animation>
      <HalfContainer paddingLeft className="tile-block-container">
        <Animation animateInType={"up"} className="content-section">
          <h2 className="tilte">{name} <i>Area Guide</i></h2>
          {office_area_guide_content &&
            <div className="description">{parseHTML(office_area_guide_content.data.office_area_guide_content)}</div>
          }


          <div className="cta-section">
            <CTALink cta={{ cta_label: "Find out more", custom_link: `${areaGuidesURL}${slug}` }} className={"button button-green-outline"} />
          </div>

        </Animation>
      </HalfContainer>
    </div>
  )
}

export default AreaGuideBlock
