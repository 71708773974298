import React from "react"
import Layout from "../components/Layout/Layout"
import { graphql } from "gatsby"
import ValuationComponent from "../components/ValuationComponent/ValuationComponent"
import SEO from "../components/SEO/seo"

const NoBannerTemplate = ({ data }) => {
  const pageData = data?.strapiPage
  const modules = pageData?.add_page_modules
  return (
    <div className="nobanner-template">
      <Layout footer={"dark"} popular_search={{title: 'category1'}}>
      <SEO title={pageData?.seo?.meta_title ? pageData?.seo?.meta_title : pageData?.title} description={pageData?.seo?.meta_description ? pageData?.seo?.meta_description : pageData?.title} />
        {modules.map((module, index) => {
          return (
            <div>
              {module.__typename ===
                "STRAPI__COMPONENT_PAGE_MODULES_VALUATION" && (
                <ValuationComponent data={module} pageData={pageData} />
              )}
            </div>
          )
        })}
      </Layout>
    </div>
  )
}

export default NoBannerTemplate

export const query = graphql`
  query ($page_id: String) {
    strapiPage(id: { eq: $page_id }) {
      ...PageFragment
      add_page_modules {
        ... on STRAPI__COMPONENT_PAGE_MODULES_VALUATION {
          ...ValuationFragment
        }
      }
    }
  }
`
