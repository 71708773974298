const aboutURL = "/about/"
const propertyServicesURL = "/property-services/"
const propertiesURL = "/properties/"
const commercialPropertiesURL = "/commercial-properties/"
const newHomesURL = "/new-homes/"
const secondaryNewHomeUrl = "/new-developments/"

module.exports = {
  valuationURL: "/property-valuation/",
  propertyServicesURL,
  buyURL: `${propertyServicesURL}buy/`,
  sellURL: `${propertyServicesURL}sell/`,
  rentURL: `${propertyServicesURL}rent/`,
  landlordsURL: `${propertyServicesURL}landlords/`,
  commercialURL: `${propertyServicesURL}commercial/`,
  landAndDevelopmentsURL: `${propertyServicesURL}land-and-developments/`,
  probateAndEstateServicesURL: `${propertyServicesURL}probate-and-estate-services/`,
  aboutURL,
  teamsURL: `${aboutURL}our-team/`,
  reviewsURL: `${aboutURL}reviews/`,
  newsBlogsURL: `${aboutURL}property-news/`,
  areaGuidesURL: `${aboutURL}area-guides/`,
  contactURL: "/contact-christopher-hodgson",
  sitemapURL: "/sitemap/",
  licensingAndRedressSchemesURL: `${aboutURL}licensing-and-redress-schemes/`,
  complaintsProcedure: "/complaints-procedure/",
  privacyPolicyURL: "/privacy-policy/",
  termsAndConditionsURL: "/terms-and-conditions/",
  cookiePolicyURL: "/cookie-policy/",
  serviceDetails: "/property-services/service-details/",
  propertiesURL,
  propertiesSaleURL: `${propertiesURL}for-sale/`,
  propertiesRentURL: `${propertiesURL}to-rent/`,
  propertydetailsSaleURL: `/property-for-sale/`,
  propertydetailsRentURL: `/property-to-rent/`,
  propertydetailsCommercialRentURL: `/commercial-property-to-rent/`,
  propertydetailsCommercialSaleURL: `/commercial-property-for-sale/`,
  propertydetailsNewhomesSaleURL: `/new-homes-for-sale/`,
  commercialPropertiesURL,
  commercialPropertiesSaleURL: `${commercialPropertiesURL}for-sale/`,
  commercialPropertiesRentURL: `${commercialPropertiesURL}to-rent/`,
  newHomesURL,
  secondaryNewHomeUrl,
  secondaryNewHomeSaleUrl: `${secondaryNewHomeUrl}for-sale/`,
  newHomesSaleURL: `${newHomesURL}for-sale/`,
  bookViewingURL: `/book-a-viewing/`,
}
