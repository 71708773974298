import React from "react"
import { Container } from "react-bootstrap"
import { parseHTML } from "../../common/utils/utils"
import GGFXImage from "../elements/GGFXImage"
import { Link } from "gatsby"
import "./ValuationComponent.scss"

const ValuationComponent = ({ data, pageData }) => {
  const { content, title, val_items } = data
  const { strapi_id, imagetransforms } = pageData
  let processedImages = imagetransforms?.image_Transforms
    ? imagetransforms?.image_Transforms
    : JSON.stringify({})

  return (
    <div className="valuation-landing">
      <Container>
        <div className="valuation-components">
          <div className="top-content">
            <h1>{parseHTML(title.data.title)}</h1>
            <p className="content">{parseHTML(content.data.content)}</p>
          </div>
          <div className="valuation-sections">
            {val_items.map((item, index) => {
              return (
                <div className="valuation-items">
                  <GGFXImage
                    ImageSrc={item.image}
                    //   title={title?.data?.title}
                    altText={"Valuation landing"}
                    imagetransforms={processedImages}
                    renderer="srcSet"
                    imagename={"page.image.val_image"}
                    strapi_id={strapi_id}
                    className={"image"}
                  />
                  <div className="val-list">
                    <h3>{parseHTML(item.title.data.title)}</h3>
                    <div className="valuation-list-holder">
                      {item.contents?.map(content => (
                        <p className="valuations-counts">{content.text}</p>
                      ))}
                    </div>
                    <Link
                      to={`/property-valuation/${item.menu.slug}/`}
                      className={`button ${
                        item.valuation_form === "home_valuation"
                          ? "button-green"
                          : "button-green-outline"
                      }`}
                    >
                      {item.cta_label}
                    </Link>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </Container>
    </div>
  )
}

export default ValuationComponent
