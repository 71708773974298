import React, { useEffect, useState } from "react"
import { Container } from "react-bootstrap"
import CustomSlider from "../CustomSlider/CustomSlider"
import GetReview from "../GetReview/GetReview"
import "./ReviewsSlider.scss"

import { Link, graphql, useStaticQuery } from "gatsby"
import wordsToNumbers from "words-to-numbers"
import ReviewsTotal from "../ReviewsTotal/ReviewsTotal"
import Animation from "../elements/Animation"
const ReviewsSlider = () => {
  const strapireviewData = useStaticQuery(graphql`
    {
      greviews(reviews: {elemMatch: {starRating: {eq: "FIVE"}}}) {
        reviews {
          id
          name
          comment
          starRating
          reviewer {
            displayName
            profilePhotoUrl
          }
        }
      }
    }
  `)
  const [loading, setloading] = useState(false)
  const [reviewData, setReviewData] = useState([])
  const [testimonials, setTestimonials] = useState([])
  useEffect(() => {
    setTestimonials(strapireviewData?.greviews?.reviews)
    setReviewData(strapireviewData?.greviews?.reviews?.slice(0, 6))
  }, [])

  // console.log(reviewData, "-")
  var testimonial_count = testimonials ? testimonials.length : 0
  var rating_count = 0
  testimonials &&
    testimonials.length > 0 &&
    testimonials.map((item, ind) => {
      rating_count += parseInt(wordsToNumbers(item.starRating))
    })
  var rating_avg = rating_count / testimonial_count
  return (
    <Animation animateInType={"up"} className="review-slider-component">
      {loading && <div className="loader-icon-slider" />}
      <Container className="review-container">
        {!loading && (
          <ReviewsTotal
            rating_avg={rating_avg}
            testimonial_count={testimonial_count}
          />
        )}

        {reviewData?.length > 0 && (
          <CustomSlider className="reviews-slider-wrap" googlereview>
            {reviewData?.map((review, i) => {
              return (
                <>
                  <Animation
                    animateInType={"up"}
                    delay={i * 200}
                    className="review-list"
                  >
                    <GetReview starRating={review.starRating} />
                    <p className="comments">{review?.comment}</p>
                    <p className="name">{review?.reviewer?.displayName}</p>
                  </Animation>
                </>
              )
            })}
          </CustomSlider>
        )}
        <div className="d-flex align-items-center justify-content-center">
          <Animation animateInType={"up"}>
            <Link
              to={"/about/reviews/"}
              className="button button-green review-btn"
            >
              View All
            </Link>
          </Animation>
        </div>
      </Container>
    </Animation>
  )
}

export default ReviewsSlider
