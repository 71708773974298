import {
  UserObjectStoreProvider,
  isAuthenticated,
  useUserObjectState,
} from "@starberry/myaccount-website-utils"
import clsx from "clsx"
import { graphql } from "gatsby"
import React, { useEffect, useRef, useState } from "react"
import { Container } from "react-bootstrap"
import voca from "voca"
import {
  commercialPropertiesRentURL,
  commercialPropertiesSaleURL,
  newHomesSaleURL,
  propertiesRentURL,
  propertiesSaleURL,
} from "../common/utils/urls"
import AppraisalCard from "../components/AppraisalCard/AppraisalCard"
import AppraisalComponent from "../components/AppraisalComponent/AppraisalComponent"
import AvailableProperty from "../components/AvailableProperty/AvailableProperty"
import Layout from "../components/Layout/Layout"
import PropertyBanner from "../components/PropertyBanner/PropertyBanner"
import PropertyCalculator from "../components/PropertyCalculator/PropertyCalculator"
import { numberFormat } from "../components/PropertyCalculator/utils"
import PropertyCta from "../components/PropertyCta/PropertyCta"
import PropertyHead from "../components/PropertyHead/PropertyHead"
import PropertyNegotiator from "../components/PropertyNegotiator/PropertyNegotiator"
import PropertyReadMore from "../components/PropertyReadMore/PropertyReadMore"
import SEO from "../components/SEO/seo"
import SimilarProperties from "../components/SimilarProperties/SimilarProperties"
import LocRatingMap from "../components/map/loc-rating-map"
import { MapMarkerInfoBox } from "../components/map/map-marker"
import useDeviceMedia from "../hooks/useDeviceMedia"
import "../styles/other/PropertyDetailTemplate.scss"

const PropertyDetailsTemplate = ({ data, children }) => {
  const { isTablet, isMobile } = useDeviceMedia()
  const myRef = useRef(null)
  const executeScroll = () => {
    myRef?.current?.scrollIntoView({ behavior: "smooth" })
  }
  const PageData = data?.strapiProperty
  const teamData = data?.strapiTeam
  const availabilityData = data?.allStrapiProperty?.edges
  const [loading, setLoading] = useState(false)

  const { state, services } = useUserObjectState()
  const authUser = isAuthenticated()

  useEffect(() => {
    if (authUser) {
      services.getUserobject({ type: "property", state: true })
    }
  }, [])
  const userObjects = state.userObjects

  // console.log(PageData)
  var imagename = "property.images.details"
  let processedImages = JSON.stringify({})
  if (data?.strapiProperty?.imagetransforms?.images_Transforms) {
    processedImages = data?.strapiProperty?.imagetransforms?.images_Transforms
  }
  // console.log(processedImages)
  // let propertyDetailImg = []
  // if((data?.strapiProperty?.images).length > 0) {
  // for (
  //   let i = 0;
  //   i < data?.strapiProperty?.images?.strapi_json_value?.length;
  //   i++
  // ) {
  //   if (data.strapiProperty?.images?.strapi_json_value[i].url) {
  //     propertyDetailImg.push(
  //       data.strapiProperty?.images?.strapi_json_value[i]?.url
  //     )
  //   }
  // }

  //}
  // we could server resized version instead of original one to avoid load time
  //features = []
  //if(data.strapiProperty?.accommodation_summary?.strapi_json_value)
  let features = [] //data.strapiProperty?.accommodation_summary?.strapi_json_value;

  const url = typeof window !== "undefined" ? window.location.href : ""
  let mapItems = []
  let mapItem = {}
  mapItem["lat"] = PageData?.latitude
  mapItem["lng"] = PageData?.longitude
  const mapMarkerImageShowFlag = 0
  let mapItemHtml = MapMarkerInfoBox("test", mapMarkerImageShowFlag)
  mapItem["html"] = mapItemHtml
  mapItems.push(mapItem)

  let backUrl = ""

  if (PageData?.department === "residential") {
    if (PageData?.search_type === "sales") {
      backUrl = propertiesSaleURL
    } else {
      backUrl = propertiesRentURL
    }
  } else if (PageData?.department === "commercial") {
    if (PageData?.search_type === "sales") {
      backUrl = commercialPropertiesSaleURL
    } else {
      backUrl = commercialPropertiesRentURL
    }
  } else if (PageData?.department === "new_developments") {
    backUrl = newHomesSaleURL
  }

  const breadcrumbURLs = [
    {
      url: backUrl,
      label: "Find a Property",
    },
    {
      url: ``,
      label: PageData?.display_address,
    },
  ]

  const propertyCta = (
    <div className="mob-tab-view d-block d-xl-none">
      <PropertyCta
        userObjects={userObjects}
        data={PageData}
        id={PageData?.id}
        soldrules={
          data?.strapiProperty?.status === "Sold" ||
          data?.strapiProperty?.status === "Let" ||
          data?.strapiProperty?.status === "Sold Subject To Contract" ||
          data?.strapiProperty?.status === "Let Agreed"
            ? true
            : false
        }
      />
    </div>
  )

  // useEffect(() => {
  //   if (!_.isEmpty(availabilityData)) return

  //   const url = `${process.env.GATSBY_STRAPI_SRC}/api/properties?filters[publish][$eq]=true&filters[master_id][$eq]=${PageData?.property_id}`
  //   setLoading(true)
  //   fetch(url, {
  //     headers: new Headers({
  //       Authorization: `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
  //     }),
  //   })
  //     .then(response => response.json())
  //     .then(({ data }) => {
  //       setAvailabilityData(data)
  //       setLoading(false)
  //     })
  //     .catch(err => {
  //       console.log(err)
  //     })
  // }, [PageData?.property_id])

  return (
    <div className={clsx("property-template")}>
      <Layout
        propertydetails={true}
        breadcrumbURLs={breadcrumbURLs}
        className="property-details-page-layout"
        afterFooter={propertyCta}
        popular_search={
          PageData?.search_type === "sales"
            ? { title: "category2" }
            : { title: "category3" }
        }
      >
        {!isTablet && !isMobile ?
        <div className="d-none d-xl-block">
          <PropertyHead data={PageData} />
        </div> : null}
        <PropertyBanner
          executeScroll={executeScroll}
          latitude={PageData?.latitude}
          longitude={PageData?.longitude}
          {...data.strapiProperty}
          // propImg={propertyDetailImg}
          processedImages={processedImages}
          imagename={imagename}
          crm_id={data?.strapiProperty?.crm_id}
          prop_id={data?.strapiProperty?.strapi_id}
        />

        {isTablet ?
        <div className="d-block d-xl-none">
          <PropertyHead data={PageData} />
        </div> : null}
        <Container className="content-section section-p-80">
          <div className="left-section">
            {PageData?.description?.data?.description && (
              <div className="description-block">
                <h4>Property Description</h4>
                <PropertyReadMore
                  content={
                    PageData?.description?.data?.description +
                    `${
                      PageData?.introtext?.data?.introtext ? "<br/><br/>" : ""
                    }` +
                    PageData?.introtext?.data?.introtext?.replace(
                      /<br><br><br>/g,
                      "<br><br>"
                    )
                  }
                  height={270}
                  className={"property-desc-text"}
                />
              </div>
            )}

            {PageData?.accommodation_summary?.strapi_json_value?.length > 0 && (
              <div>
                <h4>Key Features</h4>
                <ul className="feature-ul">
                  {PageData?.accommodation_summary?.strapi_json_value?.map(
                    (item, i) => {
                      return <li>{item}</li>
                    }
                  )}
                  {PageData?.extra.tenure&&
                  <li>{PageData?.extra.tenure}</li>
                  }
                </ul>
              </div>
            )}

            {availabilityData?.length > 0 &&
              PageData?.department === "new_developments" && (
                <AvailableProperty data={availabilityData} />
              )}

            {PageData?.search_type === "sales" ? (
              <>
                <hr></hr>
                <div className="d-none d-xl-block">
                  <PropertyCalculator prop_price={PageData?.price} />
                </div>
              </>
            ) : null}
          </div>
          <div className="right-section">
            <div className="sticky-section">
              {teamData && <PropertyNegotiator prop_address={PageData?.display_address} prop_url={url} teamData={teamData} />}
              <AppraisalCard />
            </div>
          </div>
        </Container>
        {PageData?.latitude && PageData?.longitude ? (
          <div className="property-map">
            <div className="scrolling-top" ref={myRef}></div>
            <LocRatingMap data={mapItems} />
          </div>
        ) : null}
        <SimilarProperties
          prop_id={data?.strapiProperty?.strapi_id}
          title={`May also <i>interest you</i>...`}
        />
        <AppraisalComponent />
      </Layout>
    </div>
  )
}

export const query = graphql`
  query ($strapi_id: Int, $negotiator_id: String, $property_id: String) {
    strapiTeam(negotiator_id: { eq: $negotiator_id }) {
      image {
        url
      }
      imagetransforms {
        image_Transforms
      }
      name
      slug
      strapi_id
      email
      phone
      position
    }
    allStrapiProperty(
      filter: { publish: { eq: true }, master_id: { eq: $property_id } }
    ) {
      edges {
        node {
          brochure {
            strapi_json_value {
              srcUrl
            }
          }
          bathroom
          bedroom
          extra {
            type
          }
          price
          status
          slug
          strapi_id
          id
        }
      }
    }
    strapiProperty(strapi_id: { eq: $strapi_id }) {
      id
      title
      crm_id
      display_address
      area
      latitude
      strapi_id
      longitude
      department
      status
      long_description {
        data {
          long_description
        }
      }
      introtext {
        data {
          introtext
        }
      }
      price
      images {
        strapi_json_value {
          srcUrl
          url
        }
      }
      description {
        data {
          description
        }
      }
      building {
        strapi_json_value
      }
      bedroom
      bathroom
      floorarea_max
      floorarea_type
      imagetransforms {
        images_Transforms
      }
      price_qualifier
      epc {
        strapi_json_value {
          srcUrl
        }
      }
      accommodation_summary {
        strapi_json_value
      }
      floorplan {
        strapi_json_value {
          srcUrl
        }
      }
      extra{
        tenure
      }
      reception
      search_type
      negotiator_id
      strapi_id
      property_id
      virtual_tour {
        strapi_json_value
      }
      brochure {
        strapi_json_value {
          srcUrl
        }
      }
    }
  }
`
export const Head = ({ data }) => {
  const propertyData = data?.strapiProperty

  const building =
    propertyData?.building?.strapi_json_value?.length > 0
      ? propertyData?.building?.strapi_json_value[0]
      : ""
  const action = propertyData?.search_type === "sales" ? "for sale" : "to rent"

  const title = `${
    propertyData?.department == "new_developments" ? "New " : ""
  }${voca.capitalize(building)} ${action} ${
    propertyData?.bedroom > 0
      ? " with " + propertyData?.bedroom + " bedroom "
      : propertyData?.bedroom > 1
      ? " with " + propertyData?.bedroom + " bedrooms "
      : ""
  }in ${propertyData?.display_address} at £${numberFormat(propertyData?.price)}`
  const description =
    propertyData?.department == "new_developments"
      ? `Find the details of New ${building} ${action} ${
          propertyData?.bedroom > 0
            ? " with " + propertyData?.bedroom + " bedroom "
            : propertyData?.bedroom > 1
            ? " with " + propertyData?.bedroom + " bedrooms "
            : ""
        }in ${propertyData?.display_address} at £${numberFormat(
          propertyData?.price
        )} with Christopher Hodgson. Request a viewing to get assistance in buying this new build ${building}`
      : `Know the details of ${building} ${action} ${
          propertyData?.bedroom > 0
            ? " with " + propertyData?.bedroom + " bedroom "
            : propertyData?.bedroom > 1
            ? " with " + propertyData?.bedroom + " bedrooms "
            : ""
        }in ${propertyData?.display_address} at £${numberFormat(
          propertyData?.price
        )}. Book a viewing with Christopher Hodgsonto get assistance in finding the right ${building} for you.`

  return <SEO title={title} description={description} />
}

const PropertyDetail = props => {
  return (
    <UserObjectStoreProvider>
      <PropertyDetailsTemplate {...props} />
    </UserObjectStoreProvider>
  )
}

export default PropertyDetail
