import clsx from "clsx"
import _ from "lodash"
import React from "react"
import { Container } from "react-bootstrap"
import Slider from "react-slick"
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"
import useTeams from "../../hooks/dataHooks/useTeams"
import TeamCard from "../TeamCard/TeamCard"
import Animation from "../elements/Animation"
import "./OtherTeamSlider.scss"

const OtherTeamSlider = ({ teamID }) => {
  const allTeams = useTeams()

  const filteredTeams = allTeams.filter(team => team.strapi_id !== teamID)

  if (_.isEmpty(filteredTeams)) return null

  const { heading, title, bg_color } = module

  const settings = {
    dots: true,
    infinite: true,
    speed: 800,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
  }

  return (
    <Animation
      animateInType={"up"}
      className={clsx("team-slider-wrap section-p-120")}
    >
      <Container className="team-slider-container">
        <h2 className="title">Other members of the team</h2>
        <div className="slider-section">
          <Slider {...settings}>
            {filteredTeams.map((team, index) => (
              <TeamCard index={index} team={team} />
            ))}
          </Slider>
        </div>
      </Container>
    </Animation>
  )
}

export default OtherTeamSlider
