import React, { useState } from "react"
import { Link, navigate } from "gatsby"
import loadable from "@loadable/component"
import { Container, Row, Modal } from "react-bootstrap"
import { isEmpty } from "lodash" 
import FormFields from "../../../static/forms/broucher_form.json" 
import DefaultForm from "../forms/default-form-layout"
import "./PropertyBanner.scss"
import GGFXImage from "../elements/GGFXImage"
import ImageCallBack from "../../modules/image-callback-module"
import PlayVideoButton from "../PlayVideo/PlayVideoButton"
import { eventTracking, trackShare } from "../../common/utils/utils"
import useDeviceMedia from "../../hooks/useDeviceMedia"
const FsLightbox = loadable(() => import("fslightbox-react"))
const Slider = loadable(() => import("react-slick"))
const { Site_Vars } = require("../../common/site/config")

const NewPropertyBanner = props => {
  // Lightbox image popup
  const [propertyEpc, setPropertyEpc] = useState(false);
  const [showGallery, setShowGallery] = useState(false)
  const [propertyVideo, setPropertyVideo] = useState(false)
  const [propertyVVideo, setPropertyVVideo] = useState(false)
  const [propertyFloor, setPropertyFloor] = useState(false)
  const [propertyBrochure, setPropertyBrochure] = useState(false)

  const [selectedImage, setSelectedImage] = useState(0)
  const [show, setShow] = useState(false)
  const handleModal = () => {
    setShow(true)
  }

  const handleClose = () => {
    setShow(false)
  }
   
  // Lightbox image popup
  let propertyVideoUrl
  //if((data?.strapiProperty?.images).length > 0) {
  //for (let i = 0; i < props?.epc.strapi_json_value.length; i++) {
  if (props?.video_tour?.strapi_json_value.length) {
    propertyVideoUrl = props?.video_tour?.strapi_json_value[0]?.url
  }

  let propertyFloorImg = []
  // for (let i = 0; i < props?.floorplan.strapi_json_value.length; i++) {
  if (props?.floorplan?.strapi_json_value.length > 0) {
    propertyFloorImg.push(props?.floorplan?.strapi_json_value[0].srcUrl)
  }

  // Slider settings
  // const [currentSlide, setCurrentSlide] = useState("");

  // const handleAfterChange = (index) => {
  //     setCurrentSlide(index);
  // };
  let propertyEPCImg = [];
  //if((data?.strapiProperty?.images).length > 0) {
  if (props?.epc?.strapi_json_value) {
    for (let i = 0; i < props?.epc?.strapi_json_value.length; i++) {
      propertyEPCImg.push(props?.epc?.strapi_json_value[i].srcUrl);
    }
  }


  let settings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: false,
    autoplay: false,
    autoplaySpeed: 2000,
    // afterChange: handleAfterChange,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }
  // Slider settings
  const url = typeof window !== "undefined" ? window.location.href : ""

  const { isTablet } = useDeviceMedia()

  const images = props?.images

  if (isEmpty(images)) return null

  const imagesWithUrl = images?.filter((image) => (image?.url ? image?.url : image?.srcUrl))
  const imageUrls = imagesWithUrl?.map((image) => (image?.url ? image?.url : image?.srcUrl))

  return (
    <section className="property-banner-wrapper position-relative">
      <Container>
        <div className="property-slider-grid">
          <div className="item-grid-1">
            <div className="property-banner-slider-wrapper position-relative">
              <Slider className="property-banner-slider" {...settings}>
                {props.images?.length > 0 ? (
                  (props.images).map(
                    (image, i) =>
                      image && (
                        <div className="property-banner-slide">
                          <button
                            className="property-banner-img-zoom img-zoom"
                            onClick={() => {
                              setSelectedImage(i)
                              setShowGallery(!showGallery)
                            }}
                          >
                            <ImageCallBack
                              imageSrc={image}
                              altText={`image`}
                              processedImages={props?.processedImages}
                              renderer="srcSet"
                              imagename={props?.imagename}
                              strapi_id={props?.strapi_id}
                              classNames="img-fluid position-static"
                            />
                          </button>
                        </div>
                      )
                  )
                ) : (
                  <div className="property-banner-slide">
                    <div className="property-banner-img-zoom img-zoom">
                      <GGFXImage
                        ImageSrc={""}
                        altText={`image`}
                        imagetransforms={props?.processedImages}
                        renderer="srcset"
                        // lazyLoading={true}
                        imagename={props?.imagename}
                        strapi_id={props?.strapi_id}
                        classNames="img-fluid position-static"
                      />
                    </div>
                  </div>
                )}
              </Slider>
              <div className="d-flex align-items-center btn-gallery-bk">
                {props.images?.length > 0 && (
                  <button
                    className="button button-filled d-flex align-items-center justify-content-center"
                    onClick={() => setShowGallery(!showGallery)}
                  >
                    {isTablet ?
                      <i className="icon gallery-icon" />
                      :
                      <span>Gallery</span>
                    }

                  </button>
                )}
 
                {props?.broucher && 
                    <button
                    className="button button-filled d-flex align-items-center justify-content-center"
                    onClick={handleModal}
                  >
                      {isTablet ?
                      <i className="icon brochure-icon" />
                      :
                      <span>Brochure</span>
                    }
                  </button>
                }

                {props?.latitude && props?.longitude ? (
                  <button
                    className="button button-filled d-flex align-items-center justify-content-center"
                    onClick={props?.executeScroll}
                  >
                    {isTablet ?
                      <i className="icon map-icon" />
                      :
                      <span>Map</span>
                    }

                  </button>
                ) : null}
              </div>
            </div>
          </div>
          <div className="item-grid-2 d-none d-xl-flex">
            <div className="sub-item-grid">
              <button
                className="property-banner-img-zoom position-relative img-zoom"
                onClick={() => {
                  setSelectedImage(1)
                  setShowGallery(!showGallery)
                }}
              >
                <ImageCallBack
                  imageSrc={props.images?.[1]}
                  altText={`image`}
                  processedImages={props?.processedImages}
                  renderer="srcset"
                  imagename={"new-property.images.detailssmall"}
                  strapi_id={props?.strapi_id}
                  classNames="img-fluid position-static"
                />
                {props?.video_url && (
                  <PlayVideoButton videoUrl={props?.video_url} />
                )}
              </button>
            </div>
            <div className="sub-item-grid">
              <button
                className="property-banner-img-zoom img-zoom"
                onClick={() => {
                  setSelectedImage(2)
                  setShowGallery(!showGallery)
                }}
              >
                <ImageCallBack
                  imageSrc={props.images?.[2]}
                  altText={`image`}
                  processedImages={props?.processedImages}
                  renderer="srcset"
                  imagename={"new-property.images.detailssmall"}
                  strapi_id={props?.strapi_id}
                  classNames="img-fluid position-static"
                />
              </button>
            </div>
          </div>
        </div>
        {/* Property Lightbox popup */}
        <FsLightbox
          toggler={showGallery}
          sources={imageUrls}
          sourceIndex={selectedImage}
          type="image"
        />
        <Modal
        show={show}
        // onHide={handleClose}
        backdrop="static"
        centered
        dialogClassName="modal-popup-form"
      >
        <Modal.Header>
          <div className="modal-header-container">
            <div className="logo">
              <i className="icon black-logo-icon"></i>
            </div>
            <div className="close-modal" onClick={() => handleClose()}>
              <i className="icon white-close-icon"></i>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="popup-form-wrapper">
            <div>
              <div className="propety-info">
                <p className="heading">Download Brochure</p>
              </div>
              <DefaultForm
                fields={FormFields}
                formtagclassname="team-enquiry"
                brochure_link={props?.broucher?.url}
                to_email_id={props.email}
              // email_subject_user={`Contact ${name}`}
              // email_subject_admin={`Contact ${name}`}
              // prop_address={prop_address}
              // prop_url={prop_url}
              // propertyTeam={true}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
      </Container>
      
    </section>
  )
}

export default NewPropertyBanner
