import React from "react"
import ReactSelect, { components } from "react-select"
import clsx from "clsx"
import { sortSelectStyles } from "../../SearchResults/utils"

const CustomSelect = ({
  options,
  placeholder,
  className,
  value,
  onChange,
  ...props
}) => (
  <ReactSelect
    styles={sortSelectStyles}
    components={{
      DropdownIndicator: props => (
        <components.DropdownIndicator {...props}>
          <i className="icon down-arrow-icon" />
        </components.DropdownIndicator>
      ),
    }}
    options={options}
    value={value}
    onChange={onChange}
    isSearchable={false}
    placeholder={placeholder}
    className={clsx("react-select", className)}
    classNamePrefix={"react-select"}
    {...props}
  />
)

export default CustomSelect
