import { Breadcrumb, Container } from "react-bootstrap"
import React from "react"
import { Link } from "gatsby"
import "./Breadcrumbs.scss"
import clsx from "clsx"
import { getBreadcrumbUrls } from "../../common/utils/utils"
import _ from "lodash"
const Breadcrumbs = ({
  staticURLs,
  menuData,
  page,
  isTransparent,
  propertydetails,
}) => {
  let breadcrumbs =
    staticURLs || (!_.isEmpty(menuData) && getBreadcrumbUrls(menuData))

  if (!breadcrumbs || menuData?.slug === "home") return null

  const handleBack = () => {
    window?.history?.back()
  }

  return (
    <>
      <div
        className={`breadcrumbs-wrap ${
          isTransparent ? "transparent-breadcrumb" : ""
        }`}
      >
        <div
          className={`breadcrumbs-container header-container ${
            page === "offplan-detail-page" ? "offplan-detail-container" : ""
          }`}
        >
          <Breadcrumb className="breadcrumbs">
            {propertydetails && (
              <Breadcrumb.Item
                className="back"
                onClick={() => handleBack()}
                onKeyPress={() => handleBack()}
                href="javascript:void(0)"
              >
                Back to Results
              </Breadcrumb.Item>
            )}
            <Breadcrumb.Item className="enable-link-home" href={"/"}>
              Home
            </Breadcrumb.Item>
            {breadcrumbs.map((breadcrumb, index) => (
              <Breadcrumb.Item
                key={index}
                active={index === breadcrumbs.length - 1}
              >
                <Link
                  className={clsx(
                    index === breadcrumbs.length - 1
                      ? "disable-link"
                      : "enable-link"
                  )}
                  to={breadcrumb.url}
                >
                  {breadcrumb.label}
                </Link>
              </Breadcrumb.Item>
            ))}
          </Breadcrumb>
        </div>
      </div>
    </>
  )
}

export default Breadcrumbs
