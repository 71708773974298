import {
  GoogleMap,
  InfoWindow,
  Marker,
  useLoadScript,
} from "@react-google-maps/api"
import { Link } from "gatsby"
import _ from "lodash"
import React, { useEffect, useState } from "react"
import { connectHits } from "react-instantsearch-dom"
import imageConfig from "../../../../static/images/config.json"
import { ShowProcessedImage } from "../../../common/ggfx-client/module/components/show-image"
import {
  propertiesSaleURL,
  propertydetailsCommercialRentURL,
  propertydetailsNewhomesSaleURL,
  propertydetailsRentURL,
  propertydetailsSaleURL,
} from "../../../common/utils/urls"
import { currencyFormat } from "../../../common/utils/utils"
import useDeviceMedia from "../../../hooks/useDeviceMedia"
import bathIcon from "../../../images/icons/Baths.svg"
import bedIcon from "../../../images/icons/Bed.svg"
import receptionIcon from "../../../images/icons/Reception.svg"
import activemap from "../../../images/icons/activemap.svg"
import noactivemap from "../../../images/icons/noactivemap.svg"
import Animation from "../../elements/Animation"
import HeartIcon from "../../elements/HeartIcon"
import { usePropertyStore } from "../store/propertyStore"
import { mapStyles } from "../styles"
const LeafletMapReults = React.lazy(() => import("../map/leaflet/results"))
const SearchSideMap_ = props => {
  const mapService =
    process.env.GATSBY_MAP_PROVIDER == "leaflet" ? "leaflet" : "google"
  const { hits } = props
  // console.log(props)
  const filteredHits = hits.filter(x => !_.isEmpty(x._geoloc))

  const [activeMarker, setActiveMarker] = useState(null)
  const [render, setRender] = useState(false)
  const { isMobile, isTablet } = useDeviceMedia()

  const showMap = usePropertyStore(state => state.showMap)
  const setShowMap = usePropertyStore(state => state.setShowMap)

  useEffect(() => {
    const timer = setTimeout(() => {
      setRender(true)
    }, 300)
    return () => clearTimeout(timer)
  }, [])

  const { isLoaded } = useLoadScript({
    // googleMapsApiKey: "", // Add your API key
    googleMapsApiKey: process.env.GATSBY_MAPS_API_KEY,
  })

  if (!isLoaded) return null

  if (!render) return null

  const handleOnLoad = map => {
    const bounds = new window.google.maps.LatLngBounds()
    filteredHits.map((pnt, ind) => {
      return bounds.extend(pnt._geoloc)
    })
    map.fitBounds(bounds)
  }

  let iconMarker, iconactiveMarker

  if (typeof window !== "undefined" && window.google) {
    iconMarker = new window.google.maps.MarkerImage(
      `${noactivemap}`,
      new window.google.maps.Size(20, 26) /* size is determined at runtime */,
      new window.google.maps.Point(0, 0) /* origin is 0,0 */,
      null /* anchor is bottom center of the scaled image */,
      new window.google.maps.Size(20, 26)
    )

    iconactiveMarker = new window.google.maps.MarkerImage(
      `${activemap}`,
      new window.google.maps.Size(20, 26) /* size is determined at runtime */,
      new window.google.maps.Point(0, 0) /* origin is 0,0 */,
      null /* anchor is bottom center of the scaled image */,
      new window.google.maps.Size(20, 26)
    )
  }

  const onMarkerClick = id => {
    setActiveMarker(id)
    const element = document.getElementById("property-" + id)
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" })
      element.classList.add("active")

      // remove sibling active class
      const siblings = element.parentNode.children
      for (let i = 0; i < siblings.length; i++) {
        if (siblings[i] !== element) {
          siblings[i].classList.remove("active")
        }
      }
    }
  }

  let center
  if (!isTablet) {
    center = { lat: 25.276987, lng: 55.296249 }
  }

  return (
    <Animation className="search-map-wrapper">
      <button
        className="back-button button button-white"
        onClick={() => setShowMap(false)}
      >
        <i className="icon left-arrow-icon"></i>
        {!isMobile && (
          <span>{props.secondaryNewHome ? "back to top" : "back to list"}</span>
        )}
      </button>
      <div className="search-map" id="map">
        {mapService == "leaflet" ? (
          <LeafletMapReults hits={props?.hits} propertyTypeVal={props?.pType} />
        ) : (
          // <MapContainer bounds={bounds} scrollWheelZoom={false} style={{ height: "100vh" }}>
          //   <TileLayer
          //     attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          //     url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          //   />
          //   { (filteredHits).map((location, i) => (
          //     <MyMarker key={(location.crm_id)} position={location?._geoloc} data={location} pcardkey={i} propertyTypeVal={props.propertyTypeVal} />
          //     ))
          //   }
          //   <LocationMarker />
          // </MapContainer>
          <GoogleMap
            onLoad={handleOnLoad}
            onClick={() => setActiveMarker(null)}
            mapContainerStyle={{
              width: "100%",
              height: "100%",
            }}
            // center={center}
            options={{
              styles: mapStyles,
              mapTypeControl: false,
              streetViewControl: false,
            }}
          >
            {filteredHits?.map((pnt, ind) => {
              let pathURL = propertiesSaleURL
              // console.log(pnt)
              // const pathURL =
              if (pnt.department === "residential") {
                if (pnt.search_type === "sales") {
                  pathURL = propertydetailsSaleURL
                } else if (pnt.search_type === "lettings") {
                  pathURL = propertydetailsRentURL
                }
              } else if (pnt.department === "commercial") {
                pathURL = propertydetailsCommercialRentURL
              } else if (pnt.department === "new_developments") {
                pathURL = propertydetailsNewhomesSaleURL
              }
              return (
                <div className="hybrid-marker">
                  <Marker
                    key={pnt.objectID + "-marker"}
                    position={pnt._geoloc}
                    onClick={() => onMarkerClick(pnt.objectID)}
                    icon={
                      activeMarker === pnt.objectID
                        ? iconactiveMarker
                        : iconMarker
                    }
                  >
                    {activeMarker === pnt.objectID ? (
                      <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                        <div className="map-info-card">
                          <Link
                            to={`${pathURL}${pnt.slug}/${pnt.objectID}/`}
                            className="image-section img-zoom"
                          >
                            <ShowProcessedImage
                              images={pnt.images[0]}
                              attr={{
                                className: "img-fluid",
                                alt: pnt.description,
                              }}
                              transforms={
                                imageConfig.property.searchResults.sizes
                              }
                            />
                          </Link>
                          <div className="content">
                            <p className="address">
                              <Link
                                to={`${pathURL}${pnt.slug}/${pnt.objectID}/`}
                              >
                                {pnt.display_address}
                              </Link>
                              <HeartIcon />
                            </p>
                            <p className="price">
                              {currencyFormat(pnt.price, "£", false)}
                            </p>
                            {/* <p className="description">{description}</p> */}
                            <div className="icon-list d-flex">
                              {pnt.bedroom ? (
                                <div className="item d-flex">
                                  <img
                                    src={bedIcon}
                                    alt="bed"
                                    className="bed-icon"
                                  />
                                  <span>{pnt.bedroom}</span>
                                </div>
                              ) : null}
                              {pnt.bathroom ? (
                                <div className="item d-flex">
                                  <img
                                    src={bathIcon}
                                    alt="bath"
                                    className="bath-icon"
                                  />
                                  <span>{pnt.bathroom}</span>
                                </div>
                              ) : null}
                              {pnt.reception ? (
                                <div className="item d-flex">
                                  <img
                                    src={receptionIcon}
                                    alt="reception"
                                    className="reception-icon"
                                  />
                                  <span>{pnt.reception}</span>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </InfoWindow>
                    ) : null}
                  </Marker>
                </div>
              )
            })}
          </GoogleMap>
        )}
      </div>
    </Animation>
  )
}

const SearchSideMap = connectHits(SearchSideMap_)

export default SearchSideMap
