import { graphql, useStaticQuery } from "gatsby"

const useNews = () => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiBlog(
        sort: { fields: date, order: DESC }
        filter: { publish: { eq: true } }
      ) {
        nodes {
          ...NewsFragment
        }
      }
    }
  `)

  return data.allStrapiBlog.nodes
}

export default useNews
