import React from "react"
import { Form } from "react-bootstrap"

const RadioboxField = ({
  name,
  required,
  placeholder,
  values,
  handlechange,
  fieldClass,
  label,
  grpmd,
  componentprops,
  selected
}) => {
  var selectvalues = []
  {
    values?.map(val =>
      selectvalues.push({ value: val.value, label: val.label })
    )
  }
  return (
    <Form.Group
      md={grpmd}
      controlId={`validation${name}`}
      className="select-option form-field-group dropdown"
    >
      {label ? (
        <Form.Label className="form-label radio_label">
          {label}
          {required ? "*" : ""}
        </Form.Label>
      ) : (
        ""
      )}
      <div className="radio-container form-page-radio">
        {selectvalues.length > 0 &&
          selectvalues.map((item, index) => (
            <Form.Check
              type={"radio"}
              label={item.label}
              id={item.label}
              required={required}
              value={item.value}
              name={name}
              onChange={handlechange}
              className="form-radio"
              checked={selected===item.value}
            />
          ))}
      </div>
    </Form.Group>
  )
}

export default RadioboxField
