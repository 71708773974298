import clsx from "clsx"
import { Link } from "gatsby"
import React from "react"
import { Container } from "react-bootstrap"
import imageConfig from "../../../static/images/config.json"
import { ShowProcessedImage } from "../../common/ggfx-client/module/components/show-image"
import {
  propertydetailsCommercialSaleURL,
  propertydetailsCommercialRentURL,
  propertydetailsNewhomesSaleURL,
  propertydetailsRentURL,
  propertydetailsSaleURL,
} from "../../common/utils/urls"
import { currencyFormat } from "../../common/utils/utils"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import bathIcon from "../../images/icons/Baths.svg"
import bedIcon from "../../images/icons/Bed.svg"
import receptionIcon from "../../images/icons/Reception.svg"
import areaIcon from "../../images/icons/arrows-pointing-out.svg"
import NoImage from "../../images/noImage.png"
import Animation from "../elements/Animation"
import GGFXImage from "../elements/GGFXImage"

const FeaturedPropertyCard = ({
  image,
  price,
  address,
  description,
  slug,
  id,
  title,
  pid,
  strapi_id,
  search_type,
  status,
  imagetransforms,
  slider_name,
  bedroom,
  bathroom,
  reception,
  floorarea_type,
  floorarea_max,
  department,
  propertyPage,
  userObjects,
  price_qualifier,
}) => {

  const {isTablet,isDesktopOnly}=useDeviceMedia()

  let url = ""

  if (department === "residential") {
    if (search_type === "sales") {
      url = propertydetailsSaleURL
    } else if (search_type === "lettings") {
      url = propertydetailsRentURL
    }
  } else if (department === "commercial") {
    url =
      search_type === "sales"
        ? propertydetailsCommercialSaleURL
        : search_type === "lettings"
        ? propertydetailsCommercialRentURL
        : propertydetailsCommercialSaleURL
  } else if (department === "new_developments") {
    url = propertydetailsNewhomesSaleURL
  }

  let processedImages
  if (imagetransforms) {
    processedImages = imagetransforms?.images_Transforms || JSON.stringify({})
  }
  const imagename = "property.images.featureImg"
  let desc=description?.data?.description?.replace(/(<([^>]+)>)/gi, "")
  let descriptionText=isTablet?desc?.slice(0,210):isDesktopOnly?desc?.slice(0,210):desc?.slice(0,270)
  let floorarea = ""
  if (floorarea_type === "sqft" && floorarea_max !== 0 && floorarea_max) {
    floorarea = Math.floor(floorarea_max / 10.764)
  }

  return (
    <Animation
      animateInType={"up"}
      className={clsx(
        "property-card-section",
        propertyPage && "property-page-card"
      )}
    >
      <div className="image-section">
      <Link to={`${url}${slug}/${strapi_id}/`}>
          {image ? (
            propertyPage ? (
              <ShowProcessedImage
                images={image}
                attr={{
                  className: "img-fluid",
                  alt: title,
                }}
                transforms={imageConfig.property.searchResults.sizes}
                className={"property-card_image"}
              />
            ) : (
              <GGFXImage
                ImageSrc={image}
                altText={`${title}`}
                imagetransforms={processedImages}
                renderer="srcSet"
                imagename={imagename}
                strapi_id={strapi_id}
                className={"property-card_image"}
              />
            )
          ) : (
            <img
              src={NoImage}
              alt={description}
              className={"property-card_image"}
            />
          )}
       </Link>
      </div>
      <div className="feature-content-section">
    <Container>
      <div className="feature-properties-content">
      <Link to={`${url}${slug}/${strapi_id}/`}>
        <h2 className="display_address">{address}</h2>
       </Link> 
        <p className="price">
        {search_type === "sales" && price_qualifier? <span>{price_qualifier==="PCM"?"pcm":price_qualifier?price_qualifier:""}</span> : null}
          {currencyFormat(price)}
           {" "}
           {search_type === "lettings"? <span>{price_qualifier==="PCM"?"pcm":price_qualifier?price_qualifier:""}</span> : null}
       </p>
        <div className="icon-list d-flex">
          {bedroom ? (
            <div className="item d-flex">
              <img src={bedIcon} alt="bed" className="bed-icon" />
              <span>
                {bedroom} 
              </span>
            </div>
          ) : null}
          {bathroom ? (
            <div className="item d-flex">
              <img src={bathIcon} alt="bath" className="bath-icon" />
              <span>
                {bathroom}
              </span>
            </div>
          ) : null}
          {reception ? (
            <div className="item d-flex">
              <img
                src={receptionIcon}
                alt="reception"
                className="reception-icon"
              />
              <span>
                {reception} 
              </span>
            </div>
          ) : null}
          {floorarea_max ? (
            <div className="item d-flex">
              <img src={areaIcon} alt="area" className="area-icon" />
              <span>
                {currencyFormat(floorarea_max).replace("£","")} sqft ({floorarea} sqm)
              </span>
            </div>
          ) : null}
        </div>
        <div className="description">
            <p>{descriptionText?descriptionText+"...":""}</p>
        </div>
        <Link to={`${url}${slug}/${strapi_id}/`}>
        <button id="feature-btn" className="button button-green-outline">view details</button>
        </Link>
      </div>
      </Container>
      </div>
    </Animation>
  )
}

export default FeaturedPropertyCard
