import loadable from "@loadable/component"
import clsx from "clsx"
import { graphql } from "gatsby"
import React from "react"
import Layout from "../components/Layout/Layout"
// import Seo from "../components/seo"
import _ from "lodash"
import { Container } from "react-bootstrap"
import { aboutURL, areaGuidesURL } from "../common/utils/urls"
import { parseHTML } from "../common/utils/utils"
import AppraisalCard from "../components/AppraisalCard/AppraisalCard"
import AppraisalComponent from "../components/AppraisalComponent/AppraisalComponent"
import AreaGuideBanner from "../components/AreaGuideBanner/AreaGuideBanner"
import AreaGuideSoldProperties from "../components/AreaGuideSoldProperties/AreaGuideSoldProperties"
import NegoCard from "../components/NegoCard/NegoCard"
import SEO from "../components/SEO/seo"
import ShareIcons from "../components/SocialShare/ShareIcons"
import Animation from "../components/elements/Animation"
import GGFXImage from "../components/elements/GGFXImage"
import LocRatingMap from "../components/map/loc-rating-map"
import { MapMarkerInfoBox } from "../components/map/map-marker"
import "../styles/other/AreaGuideDetailTemplate.scss"
const Slider = loadable(() => import("react-slick"))
const AreaGuideDetail = ({ data }, props) => {
  const PageData = data?.strapiAreaGuide
  const contents = PageData.areaguide_content
  const author = PageData.team

  let mapItems = []
  let mapItem = {}
  mapItem["lat"] = PageData?.latitude
  mapItem["lng"] = PageData?.longitude
  const mapMarkerImageShowFlag = 0
  let mapItemHtml = MapMarkerInfoBox("test", mapMarkerImageShowFlag)
  mapItem["html"] = mapItemHtml
  mapItems.push(mapItem)
  const sidebarData = data?.strapiGlobalMenu
  const metaTitle = `${PageData?.name} Area Guide`
  const metaDescription = `Know more about ${PageData?.name} here. Contact one of our estate agents for assistance in finding the right property for you in ${PageData?.name}.`
  const breadcrumbURLs = [
    {
      url: aboutURL,
      label: "About",
    },
    {
      url: areaGuidesURL,
      label: "Area Guides",
    },
    {
      url: `${areaGuidesURL}${PageData?.slug}/`,
      label: PageData?.name,
    },
  ]
  // console.log("areaguide", PageData)
  let processedImages =
    PageData?.imagetransforms?.areaguide_content_image_Transforms || {}
  let settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: false,
    autoplay: true,
    autoplaySpeed: 2000,
    // afterChange: handleAfterChange,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }
  return (
    <div className={clsx("areaguide-template")}>
      <Layout popular_search={{ title: "category1" }}>
        <SEO title={metaTitle} description={metaDescription} />
        {PageData && (
          <AreaGuideBanner
            breadcrumbURLs={breadcrumbURLs}
            pageData={PageData}
          />
        )}
        <div className="areaguide-page-block">
          <Container className="content-section">
            <div className="left-section">
              {contents.map(content => (
                <>
                  {content.__typename ===
                    "STRAPI__COMPONENT_COMPONENTS_IMAGE_BLOCK" &&
                    (content?.image?.length === 1 ? (
                      <Animation animateInType={"up"} className="content-img">
                        <GGFXImage
                          ImageSrc={content.image[0]}
                          title={PageData.name}
                          altText={PageData.name}
                          imagetransforms={processedImages}
                          renderer="srcSet"
                          imagename={
                            "area-guide.areaguide_content_image.detail_image"
                          }
                          strapi_id={PageData.strapi_id}
                        />
                      </Animation>
                    ) : content?.image?.length > 1 ? (
                      <div className="property-banner-slider-wrapper area-guide position-relative">
                        <Slider
                          className="property-banner-slider"
                          {...settings}
                        >
                          {content.image?.map((item, i) => {
                            // console.log(item)
                            return (
                              <div className="property-banner-slide">
                                <GGFXImage
                                  ImageSrc={item}
                                  title={PageData.name}
                                  altText={PageData.name}
                                  imagetransforms={processedImages}
                                  renderer="srcSet"
                                  imagename={
                                    "area-guide.areaguide_content_image.detail_image"
                                  }
                                  strapi_id={PageData.strapi_id}
                                />
                              </div>
                            )
                          })}
                        </Slider>
                      </div>
                    ) : null)}
                  {content.__typename ===
                    "STRAPI__COMPONENT_COMPONENTS_RICH_TEXT_BLOCK" && (
                    <Animation animateInType={"up"} className="content-text">
                      {parseHTML(content.content.data.content)}
                    </Animation>
                  )}
                </>
              ))}
              <Animation animateInType={"up"} className="share-section">
                <p className="title">Share this post</p>
                <div className="icons-section">
                  <ShareIcons mediaImg={PageData?.tile_img?.url} />
                </div>
              </Animation>
            </div>
            <div className="right-section">
              <div className="sticky-section">
                {!_.isEmpty(author) && <NegoCard team={author} />}
                <AppraisalCard />
              </div>
            </div>
          </Container>
          {PageData?.latitude && PageData?.longitude && (
            <Animation className="area-guide-map">
              <LocRatingMap data={mapItems} />
            </Animation>
          )}
          <AreaGuideSoldProperties
            title={`Find <i>your home</i> in ${PageData?.name}`}
            PageData={PageData}
          />
          <AppraisalComponent />
        </div>
        {/* <div className="layout-padding-top area-guide-details-page">
            <div className="areaguide-page-block">
            <Container>
          <Row>
            <Col md={9}>
              {PageData?.areaguide_content?.map((item, i)=> {
                return(
                  <>
                  {item?.__typename === "STRAPI__COMPONENT_COMPONENTS_RICH_TEXT_BLOCK" &&
                  <>{parseHTML(item?.content?.data?.content)}</>
                  }
                   {item?.__typename === "STRAPI__COMPONENT_COMPONENTS_IMAGE_BLOCK" &&
                   <div className="image-bk">
                  <GGFXImage
                  ImageSrc={item?.image}
                  title={PageData?.name}
                  altText={"area-guide-banner-img"}
                  imagetransforms={PageData?.processedImages}
                  renderer="srcSet"
                  imagename={"area-guide.module_image.details"}
                  strapi_id={PageData?.strapi_id}
                  className="bg-image"
                />
                </div>
                  }
                  </>
                )
              })}
            <div className="data">{parseHTML(props?.module?.content?.data?.content)}</div>
            </Col>
          </Row>
            </Container>
            </div> */}

        {/* </div> */}
      </Layout>
    </div>
  )
}

export default AreaGuideDetail

export const query = graphql`
  query ($page_id: String) {
    strapiAreaGuide(id: { eq: $page_id }) {
      ...AreaGuideFragment
      banner_img {
        url
      }
      banner_title
      areaguide_content {
        ... on STRAPI__COMPONENT_COMPONENTS_IMAGE_BLOCK {
          __typename
          id
          image {
            url
          }
        }
        ... on STRAPI__COMPONENT_COMPONENTS_RICH_TEXT_BLOCK {
          id
          __typename
          content {
            data {
              content
            }
          }
        }
      }
    }
  }
`
