import { graphql, useStaticQuery } from "gatsby"

const useNavMenus = () => {
  const data = useStaticQuery(graphql`
    query MyQuery {
      allStrapiGlobalMenu {
        nodes {
          nav_menus {
            title
            strapi_id
            main_cta {
              ...CTAFragment
            }
            ctas {
              ...CTAFragment
            }
          }
        }
      }
    }
  `)
  return data.allStrapiGlobalMenu.nodes[0].nav_menus
}

export default useNavMenus
