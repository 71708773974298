import _ from "lodash"
import qs from "qs"
import React from "react"
import { connectStats } from "react-instantsearch-dom"
import NewHomesCard from "../NewHomesCard/NewHomesCard"
import PropertyCard from "../PropertyCard/PropertyCard"
import Animation from "../elements/Animation"
import { usePropertyStore } from "./store/propertyStore"

const NoHits = ({
  nbHits,
  userObjects,
  savedSearchParams,
  secondaryNewHome,
}) => {
  const [properties, setProperties] = React.useState([])
  const [loading, setLoading] = React.useState(false)

  const pType = usePropertyStore(state => state.pType)
  const pDepartment = usePropertyStore(state => state.pDepartment)

  React.useEffect(() => {
    if (nbHits !== 0 || !_.isEmpty(properties)) return

    const query = qs.stringify(
      {
        filters: {
          search_type: {
            $eq: pType,
          },
          department: {
            $eq: pDepartment,
          },
        },
        pagination: {
          pageSize: 6,
        },
      },
      {
        encodeValuesOnly: true, // prettify URL
      }
    )

    const url = `${process.env.GATSBY_STRAPI_SRC}/api/properties?${query}`

    setLoading(true)
    fetch(url, {
      headers: new Headers({
        Authorization: `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
      }),
    })
      .then(response => response.json())
      .then(({ data }) => {
        setProperties(data)
        setLoading(false)
      })
      .catch(err => {
        console.error("🚀CustomNoHits.js useEffect", err)
      })
  }, [nbHits])

  if (loading) return <div>Loading...</div>

  if (nbHits !== 0 || _.isEmpty(properties)) return null

  return (
    <>
      <Animation animateInType={"up"} className="h1-and-filters-section">
        <div class="h1-section-wrap">
          <div class="h1-section">
            <h1>
              Unfortunately, we do not currently have any properties that match
              your search criteria.
            </h1>
          </div>
          <p className="h1-description">
            We have selected some of our showcase properties for you to browse
            below. Alternatively, you can search again in the bar above.
          </p>
        </div>
      </Animation>
      <div
        className={`search-results-list-wrapper ${
          secondaryNewHome ? "secondary-new-homes" : ""
        }`}
      >
        {properties.map(hit => {
          const {
            display_address,
            title,
            images,
            price,
            slug,
            crm_id,
            search_type,
            bedroom,
            bathroom,
            floorarea_max,
            floorarea_type,
            reception,
            description,
            status,
            imagetransforms,
            department,
            price_qualifier,
          } = hit?.attributes

          return (
            <>
              {secondaryNewHome ? (
                <NewHomesCard
                  address={display_address}
                  title={title}
                  description={description}
                  department={department}
                  image={images?.[0]}
                  price={price}
                  slug={slug}
                  imagetransforms={imagetransforms}
                  id={crm_id}
                  search_type={search_type}
                  status={status}
                  bedroom={bedroom}
                  bathroom={bathroom}
                  floorarea_max={floorarea_max}
                  floorarea_type={floorarea_type}
                  reception={reception}
                  strapi_id={hit.id}
                  userObjects={userObjects}
                  price_qualifier={price_qualifier}
                  savedSearchParams={savedSearchParams}
                  slider_name="no-hits"
                />
              ) : (
                <PropertyCard
                  address={display_address}
                  description={title}
                  image={images[0]}
                  price={price}
                  slug={slug}
                  department={department}
                  imagetransforms={imagetransforms}
                  id={crm_id}
                  pid={hit?.id}
                  strapi_id={hit.id}
                  search_type={search_type}
                  status={status}
                  bedroom={bedroom}
                  bathroom={bathroom}
                  floorarea_max={floorarea_max}
                  floorarea_type={floorarea_type}
                  reception={reception}
                  slider_name="no-hits"
                  price_qualifier={price_qualifier}
                  userObjects={userObjects}
                  savedSearchParams={savedSearchParams}
                />
              )}
            </>
          )
        })}
      </div>
    </>
  )
}

const CustomNoHits = connectStats(NoHits)

export default CustomNoHits
