import React,{useState} from "react"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import CTALink from "../elements/CTALink"
import "./FooterMenu.scss"

const FooterMenu = ({ footerMenus }) => {
  const { isMobile } = useDeviceMedia()
  const [selected, setSelected] = useState(null)

  const handlePlus = i => {
    if (selected === i) {
      return setSelected(null)
    }
    setSelected(i)
  }

  return (
    <div className="footer-menulist-component">
      <div className="menu-items">
        {footerMenus?.map((menuItems,index) => (
          <>
            {isMobile ? (
              <div className="footer-menu-list">
                <div className="footer-mobile-reveal" onClick={() => handlePlus(index)}>
                  <p className="title">{menuItems?.title}</p>
                  {selected===index?
                    <icon className="icon grey-minus-icon" />
                  :
                  <icon className="icon grey-plus-icon" />
                  }
                </div>
                <div className={`${selected=== index ?"link-items-reveal":"link-items"}`}>
                  {menuItems?.ctas?.map(item => {
                    return <CTALink cta={item} />
                  })}
                </div>
              </div>
            ) : (
              <div className="large-device-menu">
                <div className="footer-parent-menu">
                  <p className="title">{menuItems?.title}</p>
                </div>
                <div className="link-items">
                  {menuItems?.ctas?.map(item => {
                    return <CTALink cta={item} />
                  })}
                </div>
              </div>
            )}
          </>
        ))}
      </div>
    </div>
  )
}

export default FooterMenu
