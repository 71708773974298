import clsx from "clsx"
import React from "react"
import { Container } from "react-bootstrap"
import ReactPlayer from "react-player"
import { getButtonClass, parseHTML } from "../../common/utils/utils"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import watermark from "../../images/banner-watermark.svg"
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs"
import Animation from "../elements/Animation"
import CTALink from "../elements/CTALink"
import GGFXImage from "../elements/GGFXImage"
import "./BannerLanding.scss"

const BannerLanding = ({
  data,
  homePage,
  pageData,
  breadcrumbURLs,
  transparentHeader,
  isStatic,
}) => {
  const { isTablet, isMobile } = useDeviceMedia()

  if (!data || !pageData) return null

  const { banner_title, image, ctas, video, mobile_video } = data

  const { strapi_id, imagetransforms } = pageData

  let processedImages = imagetransforms?.banner_image_Transforms
  processedImages ||= JSON.stringify({})
  const pageLayout = pageData?.layout
  const menuData = pageData?.menu
  const isTransparent =
    pageLayout === "home_page" ||
    pageLayout === "landing_page" ||
    // pageLayout === "static_page" ||
    transparentHeader
  return (
    <Animation
      animateInType={"no"}
      className={clsx("banner-landing-wrap", homePage && "home-banner")}
    >
      {video?.url ? (
        <div className="html-vid">
          <ReactPlayer
            url={isTablet || isMobile ? mobile_video?.url : video?.url}
            muted={true}
            loop={true}
            controls={false}
            autoplay={true}
            playsinline={true}
            playing={true}
            // onPlay={trackerVideo(title)}
          />
        </div>
      ) : (
        <GGFXImage
          ImageSrc={image}
          title={banner_title?.data?.banner_title}
          altText={"banner-img"}
          imagetransforms={processedImages}
          renderer="srcSet"
          imagename={"page.banner_image.listing_banner_image"}
          strapi_id={strapi_id}
          className="bg-image"
        />
      )}
      <div className="bg-img-overlay"></div>
      {!isTablet && homePage && (
        <img src={watermark} alt="banner-watermark" className="bg-watermark" />
      )}
      <Container className="banner-landing-container">
        {isStatic ? null : (
          <Breadcrumbs
            menuData={menuData}
            isTransparent={isTransparent}
            staticURLs={breadcrumbURLs}
          />
        )}
        <h1 className="banner-landing-title">
          {parseHTML(banner_title?.data?.banner_title)}
        </h1>
        {ctas && (
          <div className="cta-section">
            {ctas?.map((cta, index) => (
              <CTALink
                cta={cta}
                className={`${getButtonClass(cta.cta_theme)} ${
                  cta.cta_label === "Book a Valuation" ? "book_val_but" : ""
                }`}
              />
            ))}
          </div>
        )}
      </Container>
    </Animation>
  )
}

export default BannerLanding
