import Loadable from "@loadable/component"
import {
  UserObjectStoreProvider,
  isAuthenticated,
  savedSearchParams,
  useUserObjectState,
} from "@starberry/myaccount-website-utils"
import { navigate } from "gatsby"
import React, { useEffect, useRef } from "react"
import { Container } from "react-bootstrap"
import { Configure, InstantSearch } from "react-instantsearch-dom"
import Layout from "../components/Layout/Layout"
import ConnectFields from "../components/SearchResults/ConnectFields"
import CustomInfiniteHits from "../components/SearchResults/CustomInfiniteHits"
import CustomNoHits from "../components/SearchResults/CustomNoHits"
import H1_Filters from "../components/SearchResults/H1_Filters"
import SearchFilter from "../components/SearchResults/SearchFilter/SearchFilter"
import SearchPagination from "../components/SearchResults/SearchPagination"
import "../components/SearchResults/search-results.scss"
import { usePropertyStore } from "../components/SearchResults/store/propertyStore"
import {
  INDEX_NAME,
  SEARCH_CLIENT,
  createURL,
  searchStateToUrl,
  urlToSearchState,
} from "../components/SearchResults/utils"
const { useLocation } = require("@reach/router")

const PropertyMap = Loadable(() =>
  import("../components/SearchResults/map/RenderMap")
)

const SearchResults = props => {
  const { location, pSubPath, secondaryNewHome, isChildOnly, isplus } = props

  const { state, services } = useUserObjectState()
  const authUser = isAuthenticated()
  const { pathname } = useLocation()
  useEffect(() => {
    if (authUser) {
      services.getUserobject()
    }

    let currentUrl = process.env.GATSBY_SITE_URL + pathname

    if (!currentUrl.endsWith("/")) {
      if (typeof window !== "undefined") {
        window.location.replace(currentUrl + "/")
      }
    }
  }, [])
  const userObjects = state.userObjects
  const { pType, pStatus, pDepartment, locationName, showMap, areaJSON } =
    usePropertyStore(state => ({
      pType: state.pType,
      pStatus: state.pStatus,
      pDepartment: state.pDepartment,
      locationName: state.locationName,
      pSubPath: state.pSubPath,
      showMap: state.showMap,
      areaJSON: state.areaJSON,
    }))

  // console.log(pStatus)

  const debouncedSetStateRef = useRef(null)

  const [searchState, setSearchState, reset] = usePropertyStore(state => [
    state.searchState,
    state.setSearchState,
    state.reset,
  ])

  useEffect(() => {
    const getSearchState = async () => {
      const state = await urlToSearchState(location, pSubPath)
      setSearchState(state)
    }
    getSearchState()

    return () => {
      clearTimeout(debouncedSetStateRef.current)
    }
  }, [location])

  // reset search state when user navigates to a new page
  useEffect(() => {
    return () => {
      reset()
    }
  }, [])

  const onSearchStateChange = searchState => {
    clearTimeout(debouncedSetStateRef.current)
    debouncedSetStateRef.current = setTimeout(() => {
      navigate(searchStateToUrl(props, searchState, locationName), searchState)
    }, 500)
    setSearchState(searchState)
  }

  if (!pType || !pStatus || !pDepartment) return null

  let status = ""
  if (pStatus) {
    if (Array.isArray(pStatus)) {
      status = '(status:"' + pStatus.join('" OR status:"') + '")'
    } else {
      status = '(status:"' + pStatus + '")'
    }
  }

  const handleMapScroll = () => {
    if (secondaryNewHome) {
      setTimeout(() => {
        let getId = document.getElementById("new-homes-map")
        if (getId) {
          getId && getId.scrollIntoView({ behavior: "smooth" })
        }
      }, 2000)
    }
  }

  return (
    <div className="search-results-template">
      <InstantSearch
        indexName={INDEX_NAME}
        searchClient={SEARCH_CLIENT}
        searchState={searchState}
        onSearchStateChange={onSearchStateChange}
        createURL={createURL}
        routing="true"
      >
        <Configure
          hitsPerPage={showMap ? 1000 : 8}
          page={0}
          filters={`publish:true AND search_type:${pType} AND department:${pDepartment} ${isplus ? `OR department:new_developments AND is_master:false` : ""} AND ${status} ${
            secondaryNewHome ? `AND is_master:true` : isChildOnly ? `AND is_master:false` : ""
          }`}
        />
        <ConnectFields />

        <Layout
          footer="dark"
          popular_search={
            pType === "sales" ? { title: "category2" } : { title: "category3" }
          }
        >
          <SearchFilter />
          {showMap && !secondaryNewHome ? (
            <PropertyMap
              pType={pType}
              userObjects={userObjects}
              savedSearchParams={savedSearchParams}
            />
          ) : (
            <>
              <Container className="search-results-container">
                <H1_Filters
                  userObjects={userObjects}
                  savedSearchParams={savedSearchParams}
                  handleMapScroll={handleMapScroll}
                />
                <CustomInfiniteHits
                  savedSearchParams={savedSearchParams}
                  userObjects={userObjects}
                  secondaryNewHome={secondaryNewHome}
                />
                <CustomNoHits
                  savedSearchParams={savedSearchParams}
                  userObjects={userObjects}
                  secondaryNewHome={secondaryNewHome}
                />
                <SearchPagination />
              </Container>
              {showMap && secondaryNewHome && (
                <div id="new-homes-map">
                  <PropertyMap
                    pType={pType}
                    userObjects={userObjects}
                    secondaryNewHome={secondaryNewHome}
                    savedSearchParams={savedSearchParams}
                  />
                </div>
              )}
            </>
          )}
        </Layout>
      </InstantSearch>
    </div>
  )
}

const SearchResultsTemplate = props => {
  return (
    <UserObjectStoreProvider>
      <SearchResults {...props} />
    </UserObjectStoreProvider>
  )
}

export default SearchResultsTemplate
