import React from "react"
import { propertiesSaleURL } from "../../common/utils/urls"
import useInitPropertyData from "../../components/SearchResults/hooks/useInitPropertyData"
import SearchResultsTemplate from "../../templates/search-results-template"

const PropertySale = props => {
  const location = props.location

  useInitPropertyData({
    type: "sales",
    department: "residential",
    status: ["For Sale"],
    locationName: propertiesSaleURL,
  })

  return <SearchResultsTemplate isplus pSubPath="for-sale" location={location} />
}

export default PropertySale
