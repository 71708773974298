import React, { useEffect } from "react"
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share"
import shareIcon from "../../images/icons/share.svg"
import "./SocialShare.scss"
import { trackShare } from "../../common/utils/utils"
import ShareIcons from "./ShareIcons"

const SocialShare = ({ label, url, children, mediaImg }) => {
  const [showShareIcons, setShowShareIcons] = React.useState(false)

  return (
    <div className="share-section-wrap">
      <div
        className="share-section"
        onClick={() => {
          setShowShareIcons(!showShareIcons)
          trackShare("Share Details")
        }}
      >
        {children ? (
          children
        ) : (
          <>
            <img src={shareIcon} alt="share icon" className="share-icon" />
            <span>{label}</span>
          </>
        )}
      </div>
      {showShareIcons && <ShareIcons url={url} mediaImg={mediaImg} />}
    </div>
  )
}

export default SocialShare
