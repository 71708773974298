import { useAtom } from "jotai"
import React, { useEffect } from "react"
import useDeviceMedia from "../../../hooks/useDeviceMedia"
import filtersIcon from "../../../images/icons/adjustments-horizontal.svg"
import { showPopoverAtom } from "../../ValuationPopup/ValuationPopup"
import { usePropertyStore } from "../store/propertyStore"
import { popOverHeight, popOverHeightMobile } from "../utils"
import {
  AreaSizeSelect,
  BedroomSelect,
  BuyRentSelect,
  LocationSelect,
  MaxPriceSelect,
  MinPriceSelect,
  PropertyTypeSelect,
  SearchButton,
  SearchInput,
} from "./FiltersElements"
import "./SearchFilter.scss"
import FilterModal from "./FilterModal"
import _ from "lodash"
import Animation from "../../elements/Animation"

const SearchFilter = () => {
  const { isMobile, isTablet, isTabletOnly } = useDeviceMedia()
  const [showPopover] = useAtom(showPopoverAtom)

  const [isModalOpen, setIsModalOpen] = React.useState(false)

  // This is to fix the filter bar position when the popover is open
  useEffect(() => {
    const popHeight = isMobile ? popOverHeightMobile : popOverHeight
    const headerHeight = isMobile ? 80 : 120

    const filterWrap = document.querySelector(".search-filter-wrap")

    if (!filterWrap) return

    if (showPopover) {
      filterWrap.style.top = popHeight + headerHeight + "px"
    } else {
      filterWrap.style.top = headerHeight + "px"
    }
  }, [showPopover, isMobile])

  const searchState = usePropertyStore(state => state.searchState)
  const areaJSON = usePropertyStore(state => state.areaJSON)
  const updateFiltersData = usePropertyStore(state => state.updateFiltersData)
  const pDepartment = usePropertyStore(state => state.pDepartment)

  const isResidential = pDepartment === "residential"
  const isCommercial = pDepartment === "commercial"
  const isNewHomes = pDepartment === "new_developments"

  useEffect(() => {
    updateFiltersData()
  }, [searchState, areaJSON])

  return (
    <Animation className="search-filter-wrap ">
      <div className="search-filter-section header-container">
        <div className="filters">
          {(isResidential || isCommercial) && (
            <>
              <BuyRentSelect />
              <div className="divider"></div>
            </>
          )}
          <SearchInput />
          <div className="divider"></div>
          {!isTablet && (
            <>
              <LocationSelect />
              <div className="divider"></div>
              <MinPriceSelect />
              <div className="divider"></div>
              <MaxPriceSelect />
              <div className="divider"></div>
              {isCommercial ? <AreaSizeSelect /> : <BedroomSelect />}
              <div className="divider"></div>
              <PropertyTypeSelect />
            </>
          )}
        </div>
        <div className="cta-section">
          {isTabletOnly && (
            <div className="filter-cta" onClick={() => setIsModalOpen(true)}>
              <img src={filtersIcon} alt="info icon" className="filter-icon" />
              <p className="filter-label">Filters</p>
            </div>
          )}
          <SearchButton />
          {isMobile && (
            <button
              className="button button-green-outline"
              onClick={() => setIsModalOpen(true)}
            >
              <img src={filtersIcon} alt="info icon" className="filter-icon" />
              <span>Filters</span>
            </button>
          )}
        </div>
      </div>
      <FilterModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        isPropertyPage
      />
    </Animation>
  )
}

export default SearchFilter
