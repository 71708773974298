import { navigate } from "gatsby"
import React, { useEffect } from "react"
import {
  commercialPropertiesRentURL,
  newHomesSaleURL,
  propertiesURL,
} from "../../common/utils/urls"
import { Tab, Tabs } from "../CustomTabs/CustomTabs"
import { SearchInput } from "../SearchResults/SearchFilter/FiltersElements"
import {
  areaSizeOptions,
  bedroomOptions,
  commercialPropertyTypeOptions,
  locationOptions,
  maxPriceOptionsResiRent,
  maxPriceOptionsResiSale,
  minPriceOptionsResiRent,
  minPriceOptionsResiSale,
  propertySaleRentOptions,
  resiPropertyTypeOptions,
} from "../SearchResults/SearchFilter/filterOptions"
import useSearchTabs from "../SearchResults/hooks/useSearchTabs"
import { usePropertyStore } from "../SearchResults/store/propertyStore"
import {
  addTraillingSlash,
  getAreaPath,
  getBedroomPath,
  getPricePath,
  getProppertyTypePath,
  getSizePath,
  getSortPath,
} from "../SearchResults/utils"
import CustomSelect from "../elements/CustomSelect/CustomSelect"
import _ from "lodash"

const SearchModalFilters = ({ setIsOpen }) => {
  const { TABS, activeKey, setActiveKey } = useSearchTabs()

  const pState = usePropertyStore()

  const [department, setDepartment] = React.useState()
  const [buyRent, setBuyRent] = React.useState()
  const [location, setLocation] = React.useState()
  const [minPriceOptions, setMinPriceOptions] = React.useState([])
  const [minPrice, setMinPrice] = React.useState()
  const [maxPriceOptions, setMaxPriceOptions] = React.useState([])
  const [maxPrice, setMaxPrice] = React.useState()
  const [bedroom, setBedroom] = React.useState()
  const [areaSize, setAreaSize] = React.useState()
  const [propertyType, setPropertyType] = React.useState()
  const [propertyTypeOptions, setPropertyTypeOptions] = React.useState([])

  useEffect(() => {
    const isLettings = pState?.pType === "lettings"

    let minPriceOptionsFirstRender = isLettings
      ? minPriceOptionsResiRent
      : minPriceOptionsResiSale

    let maxPriceOptionsFirstRender = isLettings
      ? maxPriceOptionsResiRent
      : maxPriceOptionsResiSale

    const propertyTypeOptionsFirstRender =
      pState?.pDepartment === TABS.commercial || activeKey === TABS.commercial
        ? commercialPropertyTypeOptions
        : resiPropertyTypeOptions

    if (pState?.minPrice?.value) {
      maxPriceOptionsFirstRender = maxPriceOptionsFirstRender.filter(
        option =>
          !option.value ||
          parseInt(option.value) > parseInt(pState?.minPrice?.value)
      )
    }

    if (pState?.maxPrice?.value) {
      minPriceOptionsFirstRender = minPriceOptionsFirstRender.filter(
        option =>
          !option.value ||
          parseInt(option.value) < parseInt(pState?.maxPrice?.value)
      )
    }

    setDepartment(pState?.pDepartment || activeKey)
    setBuyRent(
      isLettings ? propertySaleRentOptions[1] : propertySaleRentOptions[0]
    )
    setMinPriceOptions(minPriceOptionsFirstRender)
    setMaxPriceOptions(maxPriceOptionsFirstRender)
    setPropertyTypeOptions(propertyTypeOptionsFirstRender)
    setLocation(
      locationOptions.find(option => option.value === pState?.searchSlug)
    )
    setAreaSize(
      areaSizeOptions.find(option => option.value === pState?.areaSize?.value)
    )
    setBedroom(
      bedroomOptions.find(option => option.value === pState?.bedroom?.value)
    )
    setMinPrice(
      minPriceOptionsFirstRender.find(
        option => option.value === pState?.minPrice?.value
      )
    )
    setMaxPrice(
      maxPriceOptionsFirstRender.find(
        option => option.value === pState?.maxPrice?.value
      )
    )
    setPropertyType(
      propertyTypeOptionsFirstRender.find(
        option => option.value === pState?.propertyType?.value
      )
    )
  }, [])

  const isCommercial = department === TABS.commercial

  const submitSearch = () => {
    let url = ""

    if (department === TABS.residential) {
      url += `${propertiesURL}${buyRent?.value}/`
    } else if (department === TABS.commercial) {
      url += commercialPropertiesRentURL
    } else if (department === TABS.new_homes) {
      url += newHomesSaleURL
    }

    const area = pState.areaJSON?.find(
      area =>
        area.name.toLowerCase() === pState.searchText.toLowerCase() ||
        area.slug === pState.searchText ||
        area.slug === pState.searchSlug
    )

    url += getAreaPath(!_.isEmpty(area) ? pState.searchSlug : pState.searchText)
    url += getProppertyTypePath(propertyType?.value)
    url += getBedroomPath(bedroom?.value)
    url += getSizePath(areaSize?.value)
    url += getPricePath(minPrice?.value, maxPrice?.value)
    url += getSortPath(pState?.searchState?.sortBy)
    url = addTraillingSlash(url)

    navigate(url)
    setIsOpen(false)
  }

  const onTabChange = key => {
    setDepartment(key)
    setMinPrice(null)
    setMaxPrice(null)
    setPropertyType(null)
    setBedroom(null)
    setAreaSize(null)

    if (key === TABS.commercial) {
      setPropertyTypeOptions(commercialPropertyTypeOptions)
      setMinPriceOptions(minPriceOptionsResiRent)
      setMaxPriceOptions(maxPriceOptionsResiRent)
      setBuyRent(propertySaleRentOptions[1])
    } else {
      setPropertyTypeOptions(resiPropertyTypeOptions)
      setMinPriceOptions(minPriceOptionsResiSale)
      setMaxPriceOptions(maxPriceOptionsResiSale)
      setBuyRent(propertySaleRentOptions[0])
    }
  }

  const onBuyRentChange = option => {
    setBuyRent(option)

    if (option === propertySaleRentOptions[0]) {
      setMinPriceOptions(minPriceOptionsResiSale)
      setMaxPriceOptions(maxPriceOptionsResiSale)
    } else {
      setMinPriceOptions(minPriceOptionsResiRent)
      setMaxPriceOptions(maxPriceOptionsResiRent)
    }
  }

  const onLocationChange = option => {
    setLocation(option)
    pState.setSearchSlug(option.value)
    pState.setSearchText(option.label)
  }

  const onMinPriceChange = option => {
    setMinPrice(option)

    const maxPriceOptions =
      buyRent.label === "Buy"
        ? maxPriceOptionsResiSale
        : maxPriceOptionsResiRent

    setMaxPriceOptions(
      maxPriceOptions.filter(
        maxPriceOption =>
          !option.value ||
          !maxPriceOption.value ||
          parseInt(maxPriceOption.value) > parseInt(option.value)
      )
    )
  }

  const onMaxPriceChange = option => {
    setMaxPrice(option)

    const minPriceOptions =
      buyRent.label === "Buy"
        ? minPriceOptionsResiSale
        : minPriceOptionsResiRent

    setMinPriceOptions(
      minPriceOptions.filter(
        minPriceOption =>
          !option.value ||
          !minPriceOption.value ||
          parseInt(minPriceOption.value) < parseInt(option.value)
      )
    )
  }

  const onAreaSizeChange = option => {
    setAreaSize(option)
  }

  return (
    <>
      <Tabs
        className="search-tab-section"
        activeKey={activeKey}
        setActiveKey={setActiveKey}
        onChange={onTabChange}
      >
        <Tab eventKey={TABS.residential}>residential</Tab>
        <Tab eventKey={TABS.commercial}>commercial</Tab>
        <Tab eventKey={TABS.new_homes}>new homes</Tab>
      </Tabs>
      <div className="filter-section">
        {activeKey === TABS.residential && (
          <div className="filter filter-select">
            <CustomSelect
              options={propertySaleRentOptions}
              value={buyRent}
              onChange={onBuyRentChange}
              className={"buy-rent-select"}
            />
          </div>
        )}
        <SearchInput />
        <div className="filter filter-select">
          <CustomSelect
            options={locationOptions}
            value={location}
            onChange={onLocationChange}
            className={"location-select"}
          />
        </div>
        <div className="filter filter-select">
          <CustomSelect
            options={minPriceOptions}
            value={minPrice}
            onChange={onMinPriceChange}
            className={"min-price-select"}
            placeholder={"Min price"}
          />
        </div>
        <div className="filter filter-select">
          <CustomSelect
            options={maxPriceOptions}
            value={maxPrice}
            onChange={onMaxPriceChange}
            className={"max-price-select"}
            placeholder={"Max price"}
          />
        </div>
        {isCommercial ? (
          <div className="filter filter-select">
            <CustomSelect
              options={areaSizeOptions}
              value={areaSize}
              onChange={onAreaSizeChange}
              className={"area-size-select"}
              placeholder={"Size"}
            />
          </div>
        ) : (
          <div className="filter filter-select">
            <CustomSelect
              options={bedroomOptions}
              value={bedroom}
              onChange={setBedroom}
              className={"beds-select"}
              placeholder={"Bedrooms"}
            />
          </div>
        )}
        <div className="filter filter-select">
          <CustomSelect
            options={propertyTypeOptions}
            value={propertyType}
            onChange={setPropertyType}
            className={"propertyType-select"}
            placeholder={"Property type"}
          />
        </div>
        <button
          className="button button-green search-button"
          onClick={submitSearch}
        >
          <span>Search</span>
        </button>
      </div>
    </>
  )
}

export default SearchModalFilters
