import React from "react"
import SearchSideMap from "./SearchSideMap"
import useLoadMap from "./useLoadMap"
import { usePropertyStore } from "../store/propertyStore"

const RenderMap = (props) => {
  const { isLoaded } = useLoadMap()
  const showMap = usePropertyStore(state => state.showMap)
  if (!isLoaded || !showMap) return null

  return <SearchSideMap pType={props?.pType} secondaryNewHome={props.secondaryNewHome} />
}

export default RenderMap
