import React, { useState } from "react"
import envIcon from "../../images/icons/envelope.svg"
import callIcon from "../../images/icons/phone.svg"
import whatsapp from "../../images/icons/whatsapp_grey.svg"
import "./PropertyCta.scss"
import { Link } from "gatsby"

const NewPropertyCta = ({ data, executeRegisterFormScroll }) => {

  return (
    <div className="property-cta">
      <div className="top-bk d-flex">

        <button
          onClick={executeRegisterFormScroll}
          className="button button-green"
        >
          <span className="d-none d-md-inline">Register interest</span>
          <span className="d-inline d-md-none">enquire</span>
          <span className="d-inline d-xl-none">
            <img src={envIcon} alt="envolop" className="env-icon" />
          </span>
        </button>
        {/* <a target="_blank" href={`https://wa.me/${data.whatsapp}`} className="button button-icon heart-button d-none d-xl-flex">
          <img src={whatsapp} alt="heart" className="bath-icon heart-icon" />
        </a> */}
        {data.phone &&
          <Link
            to={`tel:${data.phone}`}
            className="button button-green d-flex d-xl-none"
          >
            <span className="d-none d-md-inline">{data.phone}</span>
            <span className="d-inline d-md-none">Call</span>
            <span className="d-inline d-xl-none">
              <img src={callIcon} alt="envolop" className="call-icon" />
            </span>
          </Link>
        }
         {/* <a target="_blank" href={`https://wa.me/${data.whatsapp}`} className="button button-icon heart-button d-flex d-xl-none">
          <img src={whatsapp} alt="heart" className="bath-icon heart-icon" />
        </a> */}
      </div>
      {data.phone &&
        <div className="bot-bk d-none d-xl-block">
          <Link to={`tel:01227 266 441`}>
            {" "}
            or call on <span>{data.phone}</span>
          </Link>
        </div>
      }
      

    </div>
  )
}

export default NewPropertyCta
