import { graphql } from "gatsby"
import React from "react"
import Layout from "../components/Layout/Layout"
import ContactFormComponent from "../components/ContactFormComponent/ContactFormComponent"
import HomeValuation from "../components/HomeValuation/HomeValuation"
import RentalValuation from "../components/RentalValuation/RentalValuation"
import InstantValuation from "../components/InstantValuation/InstantValuation"
import BookViewing from "../components/BookViewing/BookViewing"
import SEO from "../components/SEO/seo"
import { Helmet } from "react-helmet"

const FormTemplate = ({ data, location }) => {
  const pageData = data?.strapiPage
  const locationState = location.state
  const modules = pageData?.add_page_modules
  var isCampaign = false;
  if (pageData?.page_class) {
    isCampaign = pageData?.page_class.includes("campaign_landing");
  }

  return (
    <div className={`form-template ${pageData?.page_class}`}>
      <Layout footer={"dark"} pageData={pageData} popular_search={{ title: 'category1' }}>
        {isCampaign &&
          <Helmet>
            <meta name="robots" content="noindex, nofollow"/>
          </Helmet>
        } 
        <SEO title={pageData?.seo?.meta_title ? pageData?.seo?.meta_title : pageData?.title} description={pageData?.seo?.meta_description ? pageData?.seo?.meta_description : pageData?.title} />
        {modules.map((module, index) => {
          return (
            <div>
              {module.__typename ===
                "STRAPI__COMPONENT_PAGE_MODULES_PAGE_MODULE" &&
                module.select_module === "general_enquiry" && (
                  <ContactFormComponent data={module} />
                )}
              {module.__typename ===
                "STRAPI__COMPONENT_PAGE_MODULES_PAGE_MODULE" &&
                module.select_module === "home_valuation" && (
                  <HomeValuation data={module} />
                )}
              {module.__typename ===
                "STRAPI__COMPONENT_PAGE_MODULES_PAGE_MODULE" &&
                module.select_module === "letting_valuation" && (
                  <RentalValuation data={module} />
                )}
              {module.__typename ===
                "STRAPI__COMPONENT_PAGE_MODULES_PAGE_MODULE" &&
                module.select_module === "instant_valuation" && (
                  <InstantValuation data={module} />
                )}

              {module.__typename ===
                "STRAPI__COMPONENT_PAGE_MODULES_PAGE_MODULE" &&
                module.select_module === "book_a_viewing" && (
                  <BookViewing data={module} locationState={locationState} />
                )}
            </div>
          )
        })}
      </Layout>
    </div>
  )
}

export default FormTemplate

export const query = graphql`
  query ($page_id: String) {
    strapiPage(id: { eq: $page_id }) {
      ...PageFragment
      add_page_modules {
        ... on STRAPI__COMPONENT_PAGE_MODULES_GLOBAL_MODULE {
          __typename
          id
          select_module
        }
        ... on STRAPI__COMPONENT_PAGE_MODULES_PAGE_MODULE {
          __typename
          id
          select_module
          content {
            data {
              content
            }
          }
          title {
            data {
              title
            }
          }
        }
      }
    }
  }
`
