import { Link } from "gatsby"
import React from "react"
import { Modal } from "react-bootstrap"
import BrandLogo from "../../Header/BrandLogo"
import SearchModalFilters from "../../SearchBox/SearchModalFilters"
import { IncludeToggle } from "../H1_Filters"
import { usePropertyStore } from "../store/propertyStore"
import "./FilterModal.scss"
import {
  AreaSizeSelect,
  BedroomSelect,
  BuyRentSelect,
  LocationSelect,
  MaxPriceSelect,
  MinPriceSelect,
  PropertyTypeSelect,
  SearchButton,
  SearchInput,
} from "./FiltersElements"
import clsx from "clsx"

const FilterModal = ({ isOpen, setIsOpen, isPropertyPage }) => {
  const pDepartment = usePropertyStore(state => state.pDepartment)
  const isCommercial = pDepartment === "commercial"

  return (
    <Modal
      show={isOpen}
      onHide={() => setIsOpen(false)}
      dialogClassName={clsx(
        "modal-search-filter",
        isPropertyPage && "property-modal"
      )}
      backdropClassName={clsx(
        "modal-search-filter-backdrop",
        isPropertyPage && "property-modal"
      )}
    >
      <Modal.Header closeButton>
        {isPropertyPage ? (
          <h2 className="title">Refine your search</h2>
        ) : (
          <Link to={"/"} className="brand-logo">
            <BrandLogo />
          </Link>
        )}
      </Modal.Header>
      <Modal.Body>
        {isPropertyPage ? (
          <div className="filter-section">
            <BuyRentSelect />
            <SearchInput />
            <LocationSelect />
            <MinPriceSelect />
            <MaxPriceSelect />
            {isCommercial ? <AreaSizeSelect /> : <BedroomSelect />}
            <PropertyTypeSelect />
            <IncludeToggle />
            <SearchButton onClick={() => setIsOpen(false)} />
          </div>
        ) : (
          <SearchModalFilters setIsOpen={setIsOpen} />
        )}
      </Modal.Body>
    </Modal>
  )
}

export default FilterModal
