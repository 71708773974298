import { graphql, useStaticQuery } from "gatsby"

const useTeams = () => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiTeam(
        filter: { publish: { eq: true } }
        sort: { fields: rank, order: ASC }
        ) {
        nodes {
          ...TeamFragment
        }
      }
    }
  `)

  return data.allStrapiTeam.nodes
}

export default useTeams
