import React,{useState} from "react"
import "./TeamDetails.scss"
import { Container, Modal } from "react-bootstrap"
import { Link } from "gatsby"
import { parseHTML } from "../../common/utils/utils"
import SocialShare from "../SocialShare/SocialShare"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import { contactURL } from "../../common/utils/urls"
import Animation from "../elements/Animation"
import GGFXImage from "../elements/GGFXImage"
import FormFields from "../../../static/forms/team_form.json"
import DefaultForm from "../forms/default-form-layout"

const TeamDetails = ({ team }) => {
  const {
    name,
    position,
    email,
    about,
    image,
    phone,
    imagetransforms,
    strapi_id,
  } = team

  const { isTablet,isMobile } = useDeviceMedia()
  const [show, setShow] = useState(false)

  const handleModal = (data) => {
    setShow(true)
  }

  const handleClose = () => {
    setShow(false)
  }

  let processedImages = imagetransforms?.image_Transforms
  processedImages ||= JSON.stringify({})

  return (
    <div className="team-detail-wrap">
      <Container className="team-detail-container">
        <div className="top-detail-section">
          <Animation animateInType={"up"} className="left-detail-section">
            <h1 className="name">{name}</h1>
            <p className="position">{position}</p>
            <div className="email-section">
              <p className="label">Email:</p>
              <Link to={contactURL} className="email-link">
                {email}
              </Link>
            </div>
            {!isTablet && (
              <div className="cta-section">
                <a hred="javascript:viod(0)" onClick={handleModal} className="button button-green">
                  <i className="icon icon-mail"></i>
                  <span>Contact</span>
                </a>
                <a
                  href={`tel:${phone}`}
                  className="button button-green-outline"
                >
                  <i className="icon icon-phone"></i>
                  <span>{phone}</span>
                </a>
              </div>
            )}
            
            <SocialShare label={"Share Details"} />
          </Animation>
          <Animation animateInType={"up"} className="right-detail-section">
            <GGFXImage
              ImageSrc={image}
              title={name}
              altText={name}
              imagetransforms={processedImages}
              renderer="srcSet"
              imagename={"team.image.img"}
              strapi_id={strapi_id}
            />
          </Animation>
        </div>
        <Animation className="horizontal-divider"></Animation>
        <Animation animateInType={"up"} className="bottom-detail-section">
          <h3 className="title">About Me</h3>
          <div className="about">{parseHTML(about.data.about)}</div>
        </Animation>
        <Modal
        show={show}
        // onHide={handleClose}
        backdrop="static"
        centered
        dialogClassName="modal-popup-form"
      >
        <Modal.Header>
          <div className="modal-header-container">
            <div className="logo">
              <i className="icon black-logo-icon"></i>
            </div>
            <div className="close-modal" onClick={() => handleClose()}>
              <i className="icon white-close-icon"></i>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="popup-form-wrapper">
            <div>
              <div className="propety-info">
                <p className="heading">Team Enquiry</p>
              </div>
              <DefaultForm
                fields={FormFields}
                formtagclassname="team-enquiry"
                teammembername={name}
                to_email_id={email}
                email_subject_user={`Contact ${name}`}
                email_subject_admin={`Contact ${name}`}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
      </Container>
          {isTablet && (
              <div className="cta-section mobile-team">
                <a href="javascript:void(0)" onClick={handleModal} className="button button-green">
                  <span>Contact</span>
                  <i className="icon icon-mail"></i>
                </a>
                <a
                  href={`tel:${phone}`}
                  className="button button-green-outline"
                >
                  
                  <span>{isMobile?"call":phone}</span>
                  <i className="icon icon-phone"></i>
                </a>
              </div>
            )}
    </div>
  )
}

export default TeamDetails
