import React, {useEffect, useState} from "react"
import { Container } from "react-bootstrap"
import FormFields from "../../../static/forms/rental_valuation.json"
import { parseHTML } from "../../common/utils/utils"
import RentalValuationForm from "../forms/rental-valuation"
import Animation from "../elements/Animation"
import "./RentalValuation.scss"

const RentalValuation = ({ data }) => {
  const [render, setRender] = useState(false);
  const title = data.title.data.title;
  const content = data.content.data.content;

  useEffect(()=>{
    if(!render){
      setRender(true);
    }
  })
  return (
    <div className="rental-valuation-wrapper">
      <Container>
        <div className="rental-valuation-module">
          <div className="content-section">
            
            <h1 className="title">{parseHTML(title)}</h1>
            {/* <p className="content">{parseHTML(content)}</p> */}
            <p class="content"><p class="covering"><strong>Service Areas:</strong> Whitstable | Herne Bay | Canterbury | Faversham</p><p><strong>Landlord Services:</strong> Rental Valuation | Property Letting &amp; Management | Letting Only</p><p>Christopher Hodgson are premier letting agents in Whitstable, Herne Bay, Canterbury, and Faversham, offering exceptional landlord services. Our services are tailored to meet the unique needs of landlords by a team of letting experts with unrivalled local property knowledge.</p><p>If you’d like to find out the accurate rental value of your property to optimise yields, or if you're seeking professional property management services in Whitstable and the surrounding area, please complete the form below. Our dedicated letting specialists will promptly contact you, ready to assist.</p></p>
          </div>
          <RentalValuationForm fields={FormFields} formtagclassname="rental-valuation" />
        </div>
      </Container>
    </div>
  )
}

export default RentalValuation
