import { Link } from "gatsby"
import React, { useState } from "react"
import { Table, Modal } from "react-bootstrap"
import FormFields from "../../../static/forms/broucher_form.json"
import { currencyFormat } from "../../common/utils/utils"
import DefaultForm from "../forms/default-form-layout"
import "./AvailableProperty.scss"

const AvailableProperty = ({ data, broucher, email }) => {
  const [show, setShow] = useState(false)
  const handleModal = () => {
    setShow(true)
  }

  const handleClose = () => {
    setShow(false)
  }

  return (
    <div className="availability-wrapper description-block">
      <div className="availability-section">
        <h2>Availability</h2>
        <Table hover responsive className="new-homes-table">
          <thead>
            <tr>
              <th>Type</th>
              <th>Beds</th>
              <th>Baths</th>
              <th>Price</th>
              <th>Status</th>
              {/* <th>Details</th>
              <th>Brochure</th> */}
            </tr>
          </thead>
          {data.length > 0 && (
            <tbody>
              {data?.map((node, index) => {
                return (
                  <tr className={index + (1 % 2) === 0 ? "even" : "odd"}>
                    <td>{node?.type}</td>
                    <td>{node?.beds}</td>
                    <td>{node?.baths}</td>
                    <td>{currencyFormat(node?.price, "£", false)}</td>
                    <td>{node?.status}</td>
                  </tr>
                )
              })}
            </tbody>
          )}
        </Table>
        {broucher &&
          <div className="broucher_cta">
            <button
              className="button button-green d-flex"
              onClick={handleModal}
            >
              Download Brochure
            </button>
          </div>
        }


      </div>
      <Modal
        show={show}
        // onHide={handleClose}
        backdrop="static"
        centered
        dialogClassName="modal-popup-form"
      >
        <Modal.Header>
          <div className="modal-header-container">
            <div className="logo">
              <i className="icon black-logo-icon"></i>
            </div>
            <div className="close-modal" onClick={() => handleClose()}>
              <i className="icon white-close-icon"></i>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="popup-form-wrapper">
            <div>
              <div className="propety-info">
                <p className="heading">Download Brochure</p>
              </div>
              <DefaultForm
                fields={FormFields}
                formtagclassname="team-enquiry"
                brochure_link={broucher?.url}
                to_email_id={email}
              // email_subject_user={`Contact ${name}`}
              // email_subject_admin={`Contact ${name}`}
              // prop_address={prop_address}
              // prop_url={prop_url}
              // propertyTeam={true}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default AvailableProperty
