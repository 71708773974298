import clsx from "clsx"
import { Link } from "gatsby"
import _ from "lodash"
import React from "react"
import useNavMenus from "../../hooks/dataHooks/useNavMenus"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import useHasScrolled from "../../hooks/useHasScrolled"
import BurgerMenu from "../BurgerMenu/BurgerMenu"
import SearchBox from "../SearchBox/SearchBox"
import FilterModal from "../SearchResults/SearchFilter/FilterModal"
import CTALink from "../elements/CTALink"
import BrandLogo from "./BrandLogo"
import "./Header.scss"
import SearchIcon from "./SearchIcon"
import UserIcon from "./UserIcon"
const Header = ({ isTransparent, isCampaign }) => {
  const [showFilterModal, setShowFilterModal] = React.useState(false)
  const menus = useNavMenus()
  const showbugherd = process.env.GATSBY_SHOW_BUGHERD
  const { isTablet } = useDeviceMedia()

  const scrolled = useHasScrolled()
  const isTransparentHeader = isTransparent && !scrolled
  const myaccountURL = process.env.GATSBY_MYACCOUNT_URL || ""
  const MyAccountUser = () => {
    return (
      <Link to={myaccountURL} className="">
        <UserIcon white={isTransparentHeader} />
      </Link>
    )
  }

  return (
    <>
      {/* {showbugherd&&
            <Helmet>
            <script type="text/javascript" src="https://www.bugherd.com/sidebarv2.js?apikey=nyfjs57wjdmpj4zzcqoxwg" async="true"></script>
            </Helmet>
            } */}
      <div
        className={clsx(
          "header-wrap",
          isTransparent && "transparent-header",
          scrolled && "scrolled"
        )}
      >
        <div className="header header-container">
          {!isCampaign ? 
           <Link to={"/"} className="header-logo">
            <BrandLogo white={isTransparentHeader} />
          </Link>
          :
          <div className="header-logo">
           <BrandLogo white={isTransparentHeader} />
           </div>
          }
          {!isCampaign &&
          <div className="nav-section">
            {isTablet ? (
              <>
                <SearchIcon
                  white={isTransparentHeader}
                  onClick={() => {
                    setShowFilterModal(true)
                  }}
                />
                <MyAccountUser />
                <BurgerMenu isTransparent={isTransparentHeader} />
              </>
            ) : (
              <>
                {menus.map(menu => (
                  <div className="nav-item">
                    {menu.main_cta.custom_link === "$find-a-property" ? (
                      <SearchBox label={menu.main_cta.cta_label} />
                    ) : (
                      <>
                        <CTALink
                          cta={menu.main_cta}
                          className={`main-menu ${
                            menu.main_cta?.cta_label === "Valuation"
                              ? "valuation-header-cta"
                              : ""
                          }`}
                        />
                        {!_.isEmpty(menu.ctas) && (
                          <div className="sub-menu-wrap">
                            <div className="sub-menu-section">
                              {menu.ctas.map(cta =>
                                cta.custom_link === "$find-a-property" ? (
                                  <SearchBox label={cta.cta_label} />
                                ) : (
                                  <CTALink cta={cta} className={"sub-menu"} />
                                )
                              )}
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                ))}
                <MyAccountUser />
              </>
            )}
          </div>
          }
        </div>
      </div>
      <SearchBox />
      <FilterModal isOpen={showFilterModal} setIsOpen={setShowFilterModal} />
    </>
  )
}

export default Header
