import * as React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { Link } from "gatsby"
import Layout from "../components/Layout/Layout"

const NotFoundPage = () => (
  <Layout is404="true" footer="dark" popular_search={{title: 'category1'}}>
    <div className="error-page-wrapper d-flex align-items-center">
      <Container>
        <Row className="d-flex justify-content-center">
          <Col xl={7} className="text-center">
            <h1>Oops! Page Not Found.</h1>
            <p>
              The page you are looking for cannot be found. Please check the URL
              or try using our search bar to find what you're looking for.
            </p>
            <ul className="list-inline">
              <li className="list-inline-item">
                <Link to="/" className="button button-filled">
                  Homepage
                </Link>
              </li>
              <li className="list-inline-item">
                <Link to="/contact-christopher-hodgson/" className="button button-outline">
                  Contact us
                </Link>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </div>
  </Layout>
)

export default NotFoundPage
