import clsx from "clsx"
import React from "react"
import { Container } from "react-bootstrap"
import { parseHTML } from "../../common/utils/utils"
import PlayVideoButton from "../PlayVideo/PlayVideoButton"
import Animation from "../elements/Animation"
import CTALink from "../elements/CTALink"
import GGFXImage from "../elements/GGFXImage"
import "./TileBlock.scss"

const TileBlock = ({ module, pageData }) => {
  if (!module || !pageData) return null

  const { bg_color, content, cta, image, title, video_url, heading } = module

  const { strapi_id, imagetransforms } = pageData

  let processedImages = imagetransforms?.image_Transforms
  processedImages ||= JSON.stringify({})

  return (
    <div className={clsx("tile-block-wrap section-p-120", `bg-${bg_color}`)}>
      <Container className="tile-block-container">
        <Animation offset="300" className="image-section">
          <GGFXImage
            ImageSrc={image}
            title={title?.data?.title}
            altText={"banner-img"}
            imagetransforms={processedImages}
            renderer="srcSet"
            imagename={"page.image.tile_image_contain"}
            strapi_id={strapi_id}
            className={"image"}
          />
          <PlayVideoButton videoUrl={video_url} />
        </Animation>
        <Animation animateInType={"up"} className="content-section">
          <div>
            <h4 className="heading">{heading}</h4>
            <h2 className="tilte">{parseHTML(title.data.title)}</h2>
            <div className="description">{parseHTML(content.data.content)}</div>
            {cta?.cta_label && cta?.menu && (
              <div className="cta-section">
                <CTALink cta={cta} className={"button button-green-outline"} />
              </div>
            )}
          </div>
        </Animation>
      </Container>
    </div>
  )
}

export default TileBlock
