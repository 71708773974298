import CheckWebpFeature from "./src/common/ggfx-client/module/webp-checker"
CheckWebpFeature()

export { wrapRootElement } from "./gatsby-shared"

export const onRouteUpdate = ({ location }) => {
  // property alert was sent with property-to-let as base url so replace this here to it got to 404 page
  let pathname = window.location.pathname;
  if (pathname.startsWith("/property-to-let")) {
    pathname = pathname.replace('property-to-let', 'property-to-rent')
    window.location.replace(pathname);
  }
}

export const shouldUpdateScroll = () => {
  window.scrollTo(0, 0)

  //   delayed scroll to top for better UX
  setTimeout(() => {
    window.scrollTo(0, 0)
  }, 500)
  return false
}
