import React from "react"
import clsx from "clsx"
import { Link } from "gatsby"
import { getMenuLink } from "../../common/utils/utils"

const CTALink = ({ onClick, className, cta, children }) => {
  if (!cta) return null

  const { cta_label, custom_link, menu, external_link } = cta

  let linkHref = null

  linkHref = menu && getMenuLink(menu)

  const isExternal =
    linkHref?.href?.includes("http") ||
    custom_link?.includes("http") ||
    external_link

  if (custom_link)
    linkHref = isExternal ? { href: custom_link } : { to: custom_link }

  if (!(linkHref?.to || linkHref?.href || custom_link)) return null

  return (
    <Link
      className={clsx(className)}
      onClick={onClick}
      {...linkHref}
      {...(isExternal && { target: "_blank" })}
    >
      {children ? children : <span>{cta_label}</span>}
    </Link>
  )
}

export default CTALink
