import React from "react"
import { secondaryNewHomeSaleUrl } from "../../common/utils/urls"
import useInitPropertyData from "../../components/SearchResults/hooks/useInitPropertyData"
import SearchResultsTemplate from "../../templates/search-results-template"

const PropertySale = props => {
  const location = props.location

  useInitPropertyData({
    type: "sales",
    department: "new_developments",
    status: ["For Sale"],
    locationName: secondaryNewHomeSaleUrl,
  })

  return <SearchResultsTemplate location={location} pSubPath="for-sale" secondaryNewHome />
}

export default PropertySale
