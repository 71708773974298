import $ from "jquery"
import React from "react"
import { connectPagination } from "react-instantsearch-dom"
import ReactSelect from "react-select"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import downArrow from "../../images/icons/CaretDown.svg"
import Animation from "../elements/Animation"

const CustomPagination = ({ currentRefinement, nbPages, refine }) => {
  // generate an array of page numbers from 1 to nbPages
  const pagesArr = Array.from({ length: nbPages }, (_, i) => i + 1)

  const pageNumberOptions = pagesArr.map(page => ({
    value: page,
    label: page,
  }))

  const { isMobile } = useDeviceMedia()

  const scrollAnimation = () => {
    $("html, body").animate({ scrollTop: 0 }, 800)
  }

  const handlePageChange = value => {
    scrollAnimation()
    const selectedPage = typeof value === "object" ? value.value : value
    refine(selectedPage)
  }

  const handleNextPage = () => {
    scrollAnimation()
    refine(currentRefinement + 1)
  }

  const handlePrevPage = async () => {
    scrollAnimation()
    refine(currentRefinement - 1)
  }

  if (nbPages < 2) return null

  return (
    <nav className="pagination-wrapper search-pagination-wrapper">
      <Animation className="pagination-container">
        <button
          onClick={handlePrevPage}
          className={`button button-secondary pagination-button button-back ${
            currentRefinement <= 1 ? "button-disabled" : ""
          }`}
          disabled={currentRefinement <= 1}
        >
          <i className="icon left-arrow-icon"></i>
          {!isMobile && <span>back</span>}
        </button>

        {
          <div className="pagination-select-wrap">
            <span className="page-text">Page</span>
            <ReactSelect
              options={pageNumberOptions}
              isSearchable={false}
              className={"pagination-select"}
              classNamePrefix={"pagination-select"}
              components={{
                DropdownIndicator: () => <img src={downArrow} alt="arrow" />,
              }}
              value={{ value: currentRefinement, label: currentRefinement }}
              onChange={handlePageChange}
            />
            <span className="page-text">of {nbPages}</span>
          </div>
        }

        <button
          onClick={handleNextPage}
          className={`button pagination-button button-next ${
            currentRefinement >= nbPages ? "button-disabled" : ""
          }`}
          disabled={currentRefinement >= nbPages}
        >
          {!isMobile && <span>next</span>}
          <i className="icon right-arrow-icon"></i>
        </button>
      </Animation>
    </nav>
  )
}

const SearchPagination = connectPagination(CustomPagination)
// Custom Pagination

export default SearchPagination
