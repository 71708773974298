import { navigate } from "gatsby"
import React from "react"
import {
  commercialPropertiesRentURL,
  commercialPropertiesSaleURL,
  commercialPropertiesURL,
  newHomesSaleURL,
  propertiesRentURL,
  propertiesSaleURL,
  propertiesURL,
  secondaryNewHomeSaleUrl,
} from "../../../common/utils/urls"
import locationIcon from "../../../images/icons/MapPin.svg"
import CustomSelect from "../../elements/CustomSelect/CustomSelect"
import SearchAutoSuggest from "../PredictiveSearch/SearchAutoSuggest"
import useAreaSelect from "../PredictiveSearch/useAreaMutiSelect"
import { usePropertyStore } from "../store/propertyStore"
import {
  addTraillingSlash,
  getAreaPath,
  getBedroomPath,
  getPricePath,
  getProppertyTypePath,
  getSizePath,
  getSortPath,
} from "../utils"
import { locationOptions, propertySaleRentOptions } from "./filterOptions"
import { useEffect } from "react"

export const BuyRentSelect = () => {
  const pType = usePropertyStore(state => state.pType)
  const pDepartment = usePropertyStore(state => state.pDepartment)

  return (
    <div className="filter filter-select">
      <CustomSelect
        options={propertySaleRentOptions}
        value={
          pType === "sales"
            ? propertySaleRentOptions[0]
            : propertySaleRentOptions[1]
        }
        onChange={option => {
          let url = ""

          if (pDepartment === "commercial") {
            url = `${commercialPropertiesURL}${option.value}/`
          } else if (pDepartment === "residential") {
            url = `${propertiesURL}${option.value}/`
          }

          navigate(addTraillingSlash(url+'sortby-price-desc/'))
        }}
        className={"buy-rent-select"}
      />
    </div>
  )
}

export const SearchInput = () => {
  const searchState = usePropertyStore(state => state.searchState)

  const {
    searchText,
    setSearchText,
    areasSuggestionList,
    handleSelectArea,
    setSearchSlug,
  } = useAreaSelect(searchState)

  return (
    <div className="filter search-box">
      <img
        src={locationIcon}
        alt="info icon"
        className="search-location-icon"
      />
      <SearchAutoSuggest
        placeholder={"Street, area or postcode"}
        handleSelectArea={handleSelectArea}
        searchText={searchText}
        setSearchText={setSearchText}
        areasSuggestionList={areasSuggestionList}
        setSearchSlug={setSearchSlug}
      />
    </div>
  )
}

export const LocationSelect = () => {
  const searchSlug = usePropertyStore(state => state.searchSlug)
  const setSearchSlug = usePropertyStore(state => state.setSearchSlug)

  return (
    <div className="filter filter-select">
      <CustomSelect
        options={locationOptions}
        value={
          locationOptions.find(option => option.value === searchSlug) ||
          locationOptions[0]
        }
        onChange={option => {
          setSearchSlug(option.value, true)
        }}
        className={"location-select"}
      />
    </div>
  )
}

export const MinPriceSelect = () => {
  const minPrice = usePropertyStore(state => state.minPrice)
  const setMinPrice = usePropertyStore(state => state.setMinPrice)
  const minPriceOptions = usePropertyStore(state => state.minPriceOptions)

  return (
    <div className="filter filter-select" key={minPrice?.value}>
      <CustomSelect
        options={minPriceOptions}
        value={minPrice}
        onChange={setMinPrice}
        className={"min-price-select"}
        placeholder={"Min price"}
      />
    </div>
  )
}

export const MaxPriceSelect = () => {
  const maxPrice = usePropertyStore(state => state.maxPrice)
  const setMaxPrice = usePropertyStore(state => state.setMaxPrice)
  const maxPriceOptions = usePropertyStore(state => state.maxPriceOptions)

  return (
    <div className="filter filter-select" key={maxPrice?.value}>
      <CustomSelect
        options={maxPriceOptions}
        value={maxPrice}
        onChange={setMaxPrice}
        className={"max-price-select"}
        placeholder={"Max price"}
      />
    </div>
  )
}

export const BedroomSelect = () => {
  const bedroom = usePropertyStore(state => state.bedroom)
  const setBedroom = usePropertyStore(state => state.setBedroom)
  const bedroomOptions = usePropertyStore(state => state.bedroomOptions)

  return (
    <div className="filter filter-select" key={bedroom?.value}>
      <CustomSelect
        options={bedroomOptions}
        value={bedroom}
        onChange={setBedroom}
        className={"beds-select"}
        placeholder={"Bedrooms"}
      />
    </div>
  )
}

export const AreaSizeSelect = () => {
  const areaSize = usePropertyStore(state => state.areaSize)
  const setAreaSize = usePropertyStore(state => state.setAreaSize)
  const areaSizeOptions = usePropertyStore(state => state.areaSizeOptions)

  return (
    <div className="filter filter-select" key={areaSize?.value}>
      <CustomSelect
        options={areaSizeOptions}
        value={areaSize}
        onChange={setAreaSize}
        className={"area-size-select"}
        placeholder={"Size"}
      />
    </div>
  )
}

export const PropertyTypeSelect = () => {
  const propertyType = usePropertyStore(state => state.propertyType)
  const setPropertyType = usePropertyStore(state => state.setPropertyType)
  const propertyTypeOptions = usePropertyStore(
    state => state.propertyTypeOptions
  )

  return (
    <div className="filter filter-select" key={propertyType?.value}>
      <CustomSelect
        options={propertyTypeOptions}
        value={propertyType}
        onChange={setPropertyType}
        className={"propertyType-select"}
        placeholder={"Property type"}
      />
    </div>
  )
}

export const SearchButton = ({ onClick }) => {
  const {
    minPrice,
    maxPrice,
    bedroom,
    areaSize,
    propertyType,
    searchState,
    searchText,
    searchSlug,
    pDepartment,
    pType,
  } = usePropertyStore()

  

  const submitSearch = () => {
    let path=typeof window!=="undefined"?window.location.pathname?.split("/"):"";
    let url = ""

    if (pType === "sales") {
      if (pDepartment === "residential") {
        url = propertiesSaleURL
      } else if (pDepartment === "commercial") {
        url = commercialPropertiesSaleURL
      }
      
       else if (pDepartment === "new_developments") {
        if(path[1]==="new-developments"){
          url=secondaryNewHomeSaleUrl
        }
        else{
        url = newHomesSaleURL
        }
      }
    } else if (pType === "lettings") {
      if (pDepartment === "residential") {
        url = propertiesRentURL
      } else if (pDepartment === "commercial") {
        url = commercialPropertiesRentURL
      }
    }

    url += getAreaPath(searchSlug || searchText)
    url += getProppertyTypePath(propertyType?.value)
    url += getBedroomPath(bedroom?.value)
    url += getSizePath(areaSize?.value)
    url += getPricePath(minPrice?.value, maxPrice?.value)
    url += getSortPath(searchState?.sortBy)
    url = addTraillingSlash(url)

    navigate(url)
    onClick && onClick()
  }

  return (
    <button
      className="button button-green search-button"
      onClick={submitSearch}
    >
      <span>Search</span>
    </button>
  )
}
