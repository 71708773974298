import React from "react"
import { commercialPropertiesRentURL } from "../../common/utils/urls"
import useInitPropertyData from "../../components/SearchResults/hooks/useInitPropertyData"
import SearchResultsTemplate from "../../templates/search-results-template"

const PropertySale = props => {
  const location = props.location

  useInitPropertyData({
    type: "lettings",
    department: "commercial",
    status: ["To Let"],
    locationName: commercialPropertiesRentURL,
  })

  return <SearchResultsTemplate location={location} pSubPath="to-rent" />
}

export default PropertySale
