import clsx from "clsx"
import React from "react"
import { Container } from "react-bootstrap"
import { parseHTML } from "../../common/utils/utils"
import Animation from "../elements/Animation"
import CTALink from "../elements/CTALink"
import LoadExternalScript from "../utils/load-external-script"
import "./instagram.scss"
const Instagram = ({ module }) => {
  const { heading, title, cta } = module
  const socialWallScript = "https://static.elfsight.com/platform/platform.js"
  return (
    <Animation className={clsx("section-p-120")}>
      <Container className="instagram-slider-container">
        <div className="d-head">
          <div>
            <h4 className="heading">{heading}</h4>
            <h2 className="title">{parseHTML(title.data.title)}</h2>
          </div>
          <div className="cta-section">
            <CTALink cta={cta} className={"button button-green-outline"} />
          </div>
        </div>
        <div className="slider-section">
          <LoadExternalScript
            src={socialWallScript}
            // async="true"
            defer="true"
            // loadScript={renderMap}
            // appendScriptTo="head"
          />
          <div class="elfsight-app-ce5c16f1-8f27-4e87-b8fd-b5f44685174d"></div>
        </div>
        <div className="cta-section-mobile">
          <CTALink cta={cta} className={"button button-green-outline"} />
        </div>
      </Container>
    </Animation>
  )
}

export default Instagram
